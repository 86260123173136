import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../../components/ErrorComponent";
import { changePassword } from "../../api/Admin";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import { ADMIN_DISTRIBUTORS_PATH } from "../../constants/adminPaths";

const schema = yup.object().shape({
  email: yup.string().required("Debes ingresar tu correo electrónico."),
});

const SwitchPasswordForm = () => {
  const { t } = useTranslation();
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to submit form
  const onSubmit = (data) => {
    setLoading(true);

    changePassword(data.email, data.password)
      .then((result) => {
        setLoading(false);
        setSuccess(true);
        setError(null);
        alert("Cambio de contraseña de " + data.email + " exitoso");
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(err);
      });
  };

  //if (success) return <Redirect to={ADMIN_DISTRIBUTORS_PATH}></Redirect>;
  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="input-wrapper mt-4">
        <label className="text-bg font-bold text-red-800 leading-4 mb-2 block">
          {t("admin.toolsLbl2")}
        </label>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signInForm.email")}
        </label>
        <input type="email" name="email" ref={register} className="input" />
        <ErrorComponent error={errors.email}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signInForm.pass")}
        </label>
        <input
          type="password"
          name="password"
          ref={register}
          className="input"
        />
        <ErrorComponent error={errors.password}></ErrorComponent>
      </div>

      <button type="submit" className="button-main">
        {t("admin.toolsBtn3")}
      </button>
      <ErrorComponent error={error}></ErrorComponent>
    </form>
  );
};

export default SwitchPasswordForm;
