import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../../ErrorComponent";
import { Redirect } from "react-router-dom";
import { APP_HOME_PATH, SIGNIN_PATH } from "../../../constants/paths";
import { API_PATH } from "../../../constants/api";
import { useLocation, useHistory } from "react-router";
import ForgotPassword from "../../../pages/public/ForgotPassword";
import {
  generateMailCodePassword,
  updatePassword,
  verifiedCodePassword,
} from "../../../api/User";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  email: yup.string().required("Debes ingresar tu correo electrónico."),
});

const schema2 = yup.object().shape({
  code: yup.string().required("Debes ingresar tu codigo."),
});

const schema3 = yup.object().shape({
  password: yup.string().required("Debes ingresar tu nueva contraseña."),
  password2: yup.string().required("Debes reingresar tu nueva contraseña."),
});

const ForgotPasswordForm = () => {
  const { t } = useTranslation();
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [code, setCode] = useState(null);

  const history = useHistory();

  // Instantiate form
  const { register, handleSubmit, errors } = useForm(
    formStep === 1 && {
      resolver: yupResolver(schema),
    },
    formStep === 2 && {
      resolver: yupResolver(schema2),
    },
    formStep === 3 && {
      resolver: yupResolver(schema3),
    }
  );

  const onSubmit = (data) => {
    setLoading(true);
    if (formStep === 1) {
      generateMailCodePassword(data.email)
        .then((result) => {
          if (result.message === "User no exists") {
            alert("No se encontró ningun usuario con ese correo.");
            setLoading(false);
          } else {
            setCode(result.mailCode);
            var templateParams = {
              reply_to: data.email,
              code: result.mailCode,
            };
            emailjs
              .send(
                "service_ss9w9ws",
                "template_x5z4m8p",
                templateParams,
                "user_itfQnWMwlnZrVcVMwjZwQ"
              )
              .then((res) => {
                setTimeout(function () {
                  setFormStep(2);
                }, 1500);
              });
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          setSuccess(false);
        });
    } else if (formStep === 2) {
      verifiedCodePassword(data.code)
        .then((result) => {
          if (result.message === "codigo incorrecto") {
            alert("El codigo es incorrecto");
            setLoading(false);
          } else {
            setTimeout(function () {
              setFormStep(3);
            }, 1500);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          setSuccess(false);
        });
    } else if (formStep === 3) {
      if (data.password !== data.password2) {
        alert("Las contraseñas no coinciden.");
        setLoading(false);
      } else {
        updatePassword(data.password, code).then((result) => {
          if (result.message === "se cambio la contraseña") {
            alert("La contraseña se cambio con exito.");
            history.push(SIGNIN_PATH);
          }
        });
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [formStep]);

  return (
    <>
      {formStep === 1 && (
        <form onSubmit={handleSubmit(onSubmit)} className="relative">
          <div className="input-wrapper mt-4">
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("signInForm.email")}
            </label>
            <input type="email" name="email" ref={register} className="input" />
            <ErrorComponent error={errors.email}></ErrorComponent>
          </div>

          {!loading ? (
            <button type="submit" name="submit" className="button-main">
              {t("signInForm.modalBtn1")}
            </button>
          ) : (
            <div
              className="spinner"
              style={{ margin: "auto", marginTop: "15px" }}
            ></div>
          )}

          <br></br>
          <div className="">
            <ErrorComponent error={error}></ErrorComponent>
          </div>
        </form>
      )}
      {formStep === 2 && (
        <form onSubmit={handleSubmit(onSubmit)} className="relative">
          <div className="input-wrapper mt-4">
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("signInForm.modalTitle2")}
            </label>
            <input type="text" name="code" ref={register} className="input" />
          </div>

          {!loading ? (
            <button type="submit" name="submit" className="button-main">
              {t("signInForm.modalVerify")}
            </button>
          ) : (
            <div
              className="spinner"
              style={{ margin: "auto", marginTop: "15px" }}
            ></div>
          )}

          <br></br>
          <div className="">
            <ErrorComponent error={error}></ErrorComponent>
          </div>
        </form>
      )}
      {formStep === 3 && (
        <form onSubmit={handleSubmit(onSubmit)} className="relative">
          <div className="input-wrapper mt-4">
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("signInForm.modalNewPass1")}
            </label>
            <input
              type="password"
              name="password"
              ref={register}
              className="input"
            />
          </div>
          <div className="input-wrapper mt-4">
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("signInForm.modalNewPass2")}
            </label>
            <input
              type="password"
              name="password2"
              ref={register}
              className="input"
            />
          </div>

          {!loading ? (
            <button type="submit" name="submit" className="button-main">
              {t("signInForm.modalTitle1")}
            </button>
          ) : (
            <div
              className="spinner"
              style={{ margin: "auto", marginTop: "15px" }}
            ></div>
          )}

          <br></br>
          <div className="">
            <ErrorComponent error={error}></ErrorComponent>
          </div>
        </form>
      )}
    </>
  );
};

export default ForgotPasswordForm;
