import React from "react";
import { useTranslation } from "react-i18next";
import WFacturasLayout from "../../components/WFacturasLayout";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import AllInvoicesForm from "../../components/account/AllInvoicesForm";

const MyFacturasPage = ({ page, limit }) => {
  const { t } = useTranslation();

  return (
    <WFacturasLayout title="Mis suscripciones" fullWidth>
      <div className="flex myaccount-main-container">
        <InvoicesSidebar />
        <AllInvoicesForm page={page} limit={limit} />
      </div>
    </WFacturasLayout>
  );
};

export default MyFacturasPage;
