import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LoadingIndicator from "../LoadingIndicator";
import { useTranslation } from "react-i18next";
import PaymentMethodSelect from "./PaymentMethodSelect";
import CfdiSelect from "./CfdiSelect";
import { createFactura } from "../../api/User";
import { APP_MY_FACTURAS_PATH } from "../../constants/paths";
import WInput from "../WInput";
import { useTimbres } from "../../context/TimbresContext";
import { useToast } from "../../context/ToastContext";
import { getTimbresData } from "../../api/Utils";
import LegalDataInputForm from "./LegalDataInputForm";
import WSelect from "../WSelect";

const publicoEnGeneral = {
  legal_name: "Público en general",
  tax_id: "XAXX010101000",
  tax_system: "616",
  use: "S01",
};

const NewGeneralFacturaForm = ({ restId, userData, clients, series }) => {
  const { t } = useTranslation();
  const { updateTimbresCount } = useTimbres();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [publicoGral, setPublicoGral] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filteredLegalName, setFilteredLegalName] = useState([]);
  const minDate = new Date();
  const isWfacturasUser = parseInt(restId) === 1194; //WFACTURAS USER
  minDate.setHours(minDate.getHours() - 72);

  const schema = yup.object().shape({
    legal_name: yup
      .string()
      .required()
      .test(
        "legalname-err",
        "El nombre no debe incluir el régimen societario (ej. 'S.A. de C.V.')",
        (value) => {
          if (!value) return false;

          const upperCaseValue = value.toUpperCase();
          const forbiddenTerms = ["S.A. DE C.V.", "DE C.V.", "DE CV"];

          // for (let term of forbiddenTerms) {
          //   if (upperCaseValue.includes(term)) {
          //     return false;
          //   }
          // }

          return true;
        }
      ),
    support_email: yup.string().optional(),
    street: yup.string().optional(),
    exterior: yup.string().optional(),
    zip: yup.string().required(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    tax_id: yup.string().required().min(12).max(13),
    phone: yup.string().optional(),
    product_key: yup.number().optional(),
    tax_system: yup
      .string()
      .required()
      .test(function (reg) {
        return reg !== "no";
      }),
    folio: yup.string().optional(),
    amount: yup
      .number()
      .required()
      .test(function (amount) {
        const parsedAmount = parseFloat(amount).toFixed(4);
        return parsedAmount > 0;
      }),
    description: yup.string().required(),
    type: yup.string().optional(),
    use: yup
      .string()
      .required()
      .test(function (use) {
        return use !== "no";
      }),
    payment_form: yup
      .string()
      .optional()
      .test(function (pay) {
        return pay !== "no";
      }),
    payment_method: yup.string().optional(),
    period: yup
      .string()
      .optional()
      .test(function (period) {
        if (publicoGral) {
          return period !== "no";
        } else return true;
      }),
    month: yup
      .string()
      .optional()
      .test(function (month) {
        if (publicoGral) {
          return month !== "no";
        } else return true;
      }),
    year: yup
      .string()
      .optional()
      .test(function (year) {
        if (publicoGral) {
          return year !== "no";
        } else return true;
      }),
    pdf_custom_section: yup.string().optional(),
  });
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const evaluateTaxId = (value) => {
    const valueUpper = value.toUpperCase();
    if (valueUpper === "") setSelectedClient(null);
    if (!clients) return;
    if (valueUpper === publicoEnGeneral.tax_id && valueUpper.length >= 12) {
      setPublicoGral(true);
    } else if (
      valueUpper !== publicoEnGeneral.tax_id &&
      valueUpper.length >= 12
    ) {
      setPublicoGral(false);
      const client = clients.find((x) => x.tax_id === valueUpper);
      setSelectedClient(client);
    }
  };

  const handleClientSearch = (value) => {
    const valueLower = value.toLowerCase();
    if (!clients) return;

    if (valueLower === "") {
      setFilteredLegalName([]);
      setSelectedClient(null);
    } else {
      const matches = clients.filter((client) =>
        client.legal_name.toLowerCase().includes(valueLower)
      );
      setFilteredLegalName(matches);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (window.confirm("¿Los datos estan correctos?")) {
        setLoading(true);
        const timbresReponse = await getTimbresData(restId);
        if (timbresReponse.timbres === 0) {
          showToast("Has consumido todos tus timbres", "custom");
          setLoading(false);
          return;
        }
        const updatedProduct = isWfacturasUser
          ? {
              description: values.description,
              product_key: values.product_key,
              price: values.amount,
              tax_included: true,
            }
          : {
              description: values.description,
              price: values.amount,
              tax_included: true,
            };

        const serieFolioStart = series?.find((serie) => {
          return serie.serie_id === values.serie_id;
        });

        if (serieFolioStart) {
          values.serie_initial_folio = serieFolioStart.folio_start;
        }
        const paymentMethod = values.payment_form === "99" ? "PPD" : "PUE";
        const newValues = {
          ...values,
          rest_id: restId,
          user_data: userData,
          payment_method: paymentMethod,
          items: [
            {
              quantity: 1,
              product: updatedProduct,
            },
          ],
        };

        createFactura(newValues)
          .then(() => {
            const prevTimbres = parseInt(localStorage.getItem("timbresCount"));
            if (prevTimbres !== 99999) updateTimbresCount(prevTimbres - 1);
            showToast("¡Factura creada exitosamente!", "success");
            window.location.href = APP_MY_FACTURAS_PATH;
          })
          .catch((error) => {
            showToast(
              `Error: ${typeof error === "object" ? error.message : error}`,
              "error"
            );
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showToast(
        "Error al generar la factura, intente de nuevo mas tarde",
        "error"
      );
    }
  };

  const handleCheckboxChange = () => {
    setPublicoGral((prevState) => !prevState);
  };

  useEffect(() => {
    if (selectedClient && selectedClient !== "new") {
      reset({
        legal_name: selectedClient ? selectedClient.legal_name : "",
        tax_id: selectedClient ? selectedClient.tax_id : "",
        tax_system: selectedClient ? selectedClient.tax_system : "",
        zip: selectedClient ? selectedClient.address.zip : "",
        state: selectedClient ? selectedClient.address.state : "",
        street: selectedClient ? selectedClient.address.street : "",
        exterior: selectedClient ? selectedClient.address.exterior : "",
        city: selectedClient ? selectedClient.address.city : "",
        phone: selectedClient ? selectedClient.phone : "",
        support_email: selectedClient ? selectedClient.email : "",
        use: "G03",
      });
    } else {
      reset({
        legal_name: publicoGral ? publicoEnGeneral.legal_name : "",
        tax_id: publicoGral ? publicoEnGeneral.tax_id : "",
        tax_system: publicoGral ? publicoEnGeneral.tax_system : "",
        use: publicoGral ? publicoEnGeneral.use : "G03",
      });
    }
  }, [publicoGral, selectedClient, reset]);

  useEffect(() => {
    setValue("payment_method", watch("payment_form") === "99" ? "PPD" : "PUE");
  }, [watch]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 ">
        <label className="text-2xl font-semibold wfacturas-table-text mt-5 block">
          Datos fiscales
        </label>
        <label className="text-sm font-bold wfacturas-text leading-4 block cursor-pointer">
          <input
            type="checkbox"
            checked={publicoGral}
            onChange={handleCheckboxChange}
            className="mr-3 cursor-pointer"
          />
          Facturar a público en general{" "}
        </label>
        <div className="w-32">
          <WSelect
            type="digits"
            name="serie_id"
            className="wfacturas-input appearance-none"
            ref={register}
            label="Serie"
          >
            <option value={"no"}>F</option>
            {series?.map((serie) => (
              <option value={serie.serie_id}>{serie.serie_id}</option>
            ))}
          </WSelect>
        </div>
      </div>

      <LegalDataInputForm
        publicoGral={publicoGral}
        register={register}
        filteredLegalName={filteredLegalName}
        selectedClient={selectedClient}
        errors={errors}
        evaluateTaxId={(val) => evaluateTaxId(val)}
        handleClientSearch={(val) => handleClientSearch(val)}
        setSelectedClient={(val) => setSelectedClient(val)}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
            Datos de consumo
          </label>
          <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center">
            <div>
              <CfdiSelect name="use" register={register} />
              {errors.use && <div className="error">Requerido</div>}
            </div>
            <div>
              <WInput
                mandatory
                type="date"
                name="date"
                label="Fecha de emision"
                ref={register}
                maxDate={new Date().toISOString().split("T")[0]}
                minDate={minDate.toISOString().split("T")[0]}
                defaultValue={new Date().toISOString().split("T")[0]}
              />
              {errors.date && (
                <div className="error">
                  Fecha fuera del rango de facturación
                </div>
              )}
            </div>
            <div>
              <WInput
                mandatory
                type="text"
                ref={register}
                label="Descripción"
                name="description"
              />
              {errors.description && (
                <div className="error">Ingrese una descripción</div>
              )}
            </div>
            <div>
              <WInput
                mandatory
                type="currency"
                ref={register}
                label="Monto"
                name="amount"
                defaultValue={0}
              />
              {errors.amount && <div className="error">Monto invalido</div>}
            </div>
            <div>
              <PaymentMethodSelect name="payment_form" register={register} />
              {errors.payment_form && <div className="error">Requerido</div>}
            </div>
            <div>
              <WSelect
                disabled
                removeArrow
                name={"payment_method"}
                label="Método de pago"
                ref={register}
              >
                <option value="PUE">PUE - Pago en Una sola Exhibición</option>
                <option value="PPD">
                  PPD - Pago en Parcialidades o Diferido
                </option>
              </WSelect>
            </div>
            <div>
              <WInput ref={register} label="Notas" name="pdf_custom_section" />
              {errors.pdf_custom_section && (
                <div className="error">Clave inválida</div>
              )}
            </div>
            {isWfacturasUser && (
              <div>
                <WInput
                  mandatory
                  ref={register}
                  label="Clave de producto o servicio"
                  name="product_key"
                />
                {errors.product_key && (
                  <div className="error">Clave inválida</div>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center my-4 justify-end">
            <button
              className="bg-green-700 hover:text-green-100 cursor-pointer text-white font-medium rounded-md p-2 items-center"
              type="submit"
            >
              Generar factura
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default NewGeneralFacturaForm;
