export const MENSUAL = "WMOBIL__MENSUAL";
export const TRIMESTRAL = "WMOBIL_TRIMESTRAL";
export const SEMESTRAL = "WMOBIL_SEMESTRAL";
export const ANUAL = "WMOBIL_ANUAL";
export const MENSUAL_OXXO_SPEI = "WMOBIL_MENSUAL_OXXO_SPEI";
export const TRIMESTRAL_OXXO_SPEI = "WMOBIL_TRIMESTRAL_OXXO_SPEI";
export const SEMESTRAL_OXXO_SPEI = "WMOBIL_SEMESTRAL_OXXO_SPEI";
export const ANUAL_OXXO_SPEI = "WMOBIL_ANUAL_OXXO_SPEI";
export const MENSUAL_PAYPAL = "WMOBIL_MENSUAL_PAYPAL";
export const TRIMESTRAL_PAYPAL = "WMOBIL_TRIMESTRAL_PAYPAL";
export const SEMESTRAL_PAYPAL = "WMOBIL_SEMESTRAL_PAYPAL";
export const ANUAL_PAYPAL = "WMOBIL_ANUAL_PAYPAL";

export const BEARBEAT = "BEARBEAT_QUINCENAL_USD";

export const WMOBIL_DEL_INFINITO = "WMOBIL_DEL_INFINITO";

export const PROMO14 = "WMOBIL14";
export const PROMO13 = "WMOBIL7";
export const PROMO12 = "WMOBIL_MENSUAL_2032";
export const PROMO11 = "WMOBIL_MENSUAL_2031";
export const PROMO10 = "WMOBIL_MENSUAL_2030";
export const PROMO09 = "WMOBIL_MENSUAL_2029";
export const PROMO08 = "WMOBIL_MENSUAL_2028";
export const PROMO07 = "WMOBIL_MENSUAL_2027";
export const PROMO06 = "WMOBIL_MENSUAL_2026";
export const PROMO05 = "WMOBIL_MENSUAL_2025";
export const PROMO04 = "WMOBIL_MENSUAL_2024";
export const PROMO03 = "WMOBIL_MENSUAL_2023";
export const PROMO02 = "WMOBIL_MENSUAL_2022";
export const PROMO01 = "WMOBIL_MENSUAL_2021";

//PLANES PARA PAQUETES DE TIMBRES
export const PLAN_150 = "PLAN_150";
export const PLAN_300 = "PLAN_300";
export const PLAN_UNL = "PLAN_UNL";
