import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../../components/ErrorComponent";
import {
  getUserByEmail,
  getRestaurantsByUserId,
  addDays,
  newRestaurantAdmin,
  reinstallTurnosTotal,
} from "../../api/Admin";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useTranslation } from "react-i18next";
//import { ADMIN_DISTRIBUTORS_PATH } from "../../constants/adminPaths";

const schema = yup.object().shape({
  email: yup.string().required("Debes ingresar tu correo electrónico."),
});

const ResetHistoricsTool = () => {
  const { t } = useTranslation();
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantId, setRestaurantId] = useState(null);
  const [days, setDays] = useState(0);
  const [name, setName] = useState("");

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to submit form
  const onSubmit = (data) => {
    setLoading(true);

    getUserByEmail(data.email)
      .then((res) => {
        if (res === undefined) {
          alert("Usuario no encontrado con el correo proporcionado");
          setLoading(false);
        } else {
          setUser(res);
          getRestaurantsByUserId(res.id)
            .then((res2) => {
              setRestaurants(res2);
              setLoading(false);
            })
            .catch((err) => {
              setLoading(false);
              setError(err);
            });
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const handleSelected = (e) => {
    if (e.target.value === "new") {
      setRestaurantId(e.target.value);
    } else {
      setRestaurantId(
        e.target.value === "null" ? null : parseInt(e.target.value)
      );
    }
  };

  const handleEmpty = () => {
    setUser(null);
    setRestaurantId(null);
    setName("");
    setDays(0);
  };

  const handleAddDays = () => {
    if (restaurantId) {
      if (window.confirm("El servicio ha sido detenido?")) {
        reinstallTurnosTotal(restaurantId)
          .then((res) => {
            if (res.status === 200) {
              alert(
                "Datos borrados con éxito, por favor reinicie el servicio WMobilService"
              );
              handleEmpty();
            } else if (res.status === 205) {
              alert("La tabla no contiene datos que borrar");
              handleEmpty();
            }
          })
          .catch((err) => {
            alert("No se pudo completar la operación");
          });
      }
    } else {
      alert("Debe seleccionar un restaurante");
    }
  };

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="input-wrapper mt-4 flex justify-between">
        <label className="text-bg font-bold text-red-800 leading-4 mb-2 block">
          Herramienta para resintalar historicos (turnosTotal)
        </label>
        {user !== null && (
          <button onClick={handleEmpty}>{t("admin.toolsBtn9")}</button>
        )}
      </div>
      {user === null ? (
        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signInForm.email")}
          </label>
          <input type="email" name="email" ref={register} className="input" />
          <ErrorComponent error={errors.email}></ErrorComponent>
        </div>
      ) : (
        <div className="input-wrappet mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("signInForm.email")}
          </label>
          <p>{user.email}</p>
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block mt-2">
            {t("admin.toolsLbl6")}
          </label>
          <div className="input-select-container-addsub-tool">
            <select
              onChange={(e) => handleSelected(e)}
              name="restaurants"
              id="restaurantsSelector"
            >
              <option value="null">{t("admin.toolsDrop1")}</option>
              {restaurants.map((restaurant) => {
                return (
                  <option value={restaurant.restaurant_id}>
                    {restaurant.restaurant_title}
                  </option>
                );
              })}
            </select>
          </div>
          {restaurantId !== null && (
            <>
              <div className="input-wrapper mt-4">
                <label className="text-md font-bold text-gray-800 leading-4 mb-2 block">
                  Asegurese de realizar lo siguiente antes de continuar:
                </label>
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  - Confirmar que existe el atributo "NameBDAlt" dentro del
                  archivo "C:\WMobil\user.config
                </label>
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  - Detener el servicio WMobilService instalado en el equipo
                </label>
              </div>
            </>
          )}
        </div>
      )}
      {user === null ? (
        <button type="submit" className="button-main">
          {t("admin.toolsBtn6")}
        </button>
      ) : (
        <>
          {restaurantId !== "new" && (
            <button
              type="button"
              onClick={handleAddDays}
              className="button-main"
            >
              Reinstalar
            </button>
          )}
        </>
      )}
      <ErrorComponent error={error}></ErrorComponent>
    </form>
  );
};

export default ResetHistoricsTool;
