import React, { useState } from "react";

import Select from "../../Select";
import SortableTable from "../../SortableTable";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Top = ({ waiters, products, food, drinks }) => {
  const { t } = useTranslation();

  const options = [
    { value: "products", title: t("dashboard.dropdownOps.topTableDropdown1") },
    { value: "food", title: t("dashboard.dropdownOps.topTableDropdown2") },
    { value: "drinks", title: t("dashboard.dropdownOps.topTableDropdown3") },
    { value: "waiters", title: t("dashboard.dropdownOps.topTableDropdown4") },
  ];

  const productColumns = [
    { Header: "Ranking", accessor: "ranking" },
    { Header: t("restPage.accTableTopHeader1"), accessor: "descripcion" },
    { Header: t("restPage.accTableTopHeader2"), accessor: "amount" },
    { Header: t("restPage.accTableTopHeader3"), accessor: "grupo" },
    {
      Header: "Total",
      accessor: (values) => {
        return formatter.format(
          typeof values === "number" ? values.total : parseFloat(values.total)
        );
      },
    },
  ];

  const columns = [
    { Header: "Ranking", accessor: "ranking" },
    { Header: t("restPage.accTableTopHeader1"), accessor: "nombre" },
    { Header: t("restPage.accTableTopHeader4"), accessor: "tables" },
    { Header: t("restPage.accTableTopHeader5"), accessor: "tips" },
    {
      Header: "Total",
      accessor: (values) => {
        return formatter.format(
          typeof values === "number" ? values.total : parseFloat(values.total)
        );
      },
    },
  ];

  const [option, setOption] = useState("products");

  const changeOption = (e) => {
    setOption(e.currentTarget.value);
  };

  const filterData = (data) => {
    return data.filter((row) => row.total !== 0);
  };

  return (
    <div>
      <div className="flex justify-between w-full">
        <h3 className="text-2xl text-gray-700 font-bold">
          {t("restPage.accTableTop")}
        </h3>
        <Select options={options} onChange={changeOption}></Select>
      </div>
      {option === "products" && products ? (
        <SortableTable
          columns={productColumns}
          data={filterData(products)}
          id="top-products"
        ></SortableTable>
      ) : (
        <></>
      )}
      {option === "food" && food ? (
        <SortableTable
          columns={productColumns}
          data={filterData(food)}
          id="top-food"
        ></SortableTable>
      ) : (
        <></>
      )}
      {option === "drinks" && drinks ? (
        <SortableTable
          columns={productColumns}
          data={filterData(drinks)}
          id="top-drinks"
        ></SortableTable>
      ) : (
        <></>
      )}
      {option === "waiters" && waiters ? (
        <SortableTable
          columns={columns}
          data={filterData(waiters)}
          id="top-waiters"
        ></SortableTable>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Top;
