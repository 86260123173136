import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import NotFoundPage from "../public/NotFoundPage";
import RenewSubscriptionForm from "../../components/subscriptions/RenewSubscriptionForm";
import RenewSubscriptionSpeiOxxoForm from "../../components/subscriptions/RenewSubscriptionSpeiOxxoForm";
import RenewSubscriptionPayPalForm from "../../components/subscriptions/RenewSubscriptionPayPal";
import {
  fetchRegularUserFromAdmin,
  getLicensesFromAdmin,
} from "../../api/Admin";
import cardIcons from "../../images/cardicons.svg";
import oxxopayicon from "../../images/oxxopay.png";
import speiIcon from "../../images/spei.png";
import paypalIcon from "../../images/paypal.png";
import RenewSubscriptionFormShareable from "../../components/subscriptions/RenewSubscriptionFormShareable";
import RenewSubscriptionSpeiOxxoFormShareable from "../../components/subscriptions/RenewSubscriptionSpeiOxxoFormShareable";
import { useTranslation } from "react-i18next";

const RenewSubscriptionPageNoAuth = () => {
  const { t } = useTranslation();

  const { user_id, rest_id } = useParams();
  const location = useLocation();

  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [pm, setPm] = useState(1);

  const { conektaid, restaurant, user_email } = location.state?.data || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usr = await fetchRegularUserFromAdmin(user_id);
        setUser(usr);
        const hasSubscription = usr.restaurants?.some(
          (subscription) => subscription.id === parseInt(rest_id)
        );
        if (!hasSubscription) {
          setError({ message: "Restaurante no encontrado" });
        }
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData();
  }, [user_id]);

  if (error) {
    return <NotFoundPage />;
  }
  return (
    <Layout title="Nueva suscripción">
      <div className="wmobil-install-background"></div>
      <div className="introduction-main-container main-admin-page-padding">
        <div className="introduction-card">
          <h2 className="text-2xl text-gray-800 font-bold">
            {t("subsPage.subtitle")}
          </h2>
          <div className="payment-methods-container-new-subscription">
            <div className="payment-methods-container-new-subscription">
              <button
                className={pm == 1 ? "active" : null}
                onClick={() => setPm(1)}
              >
                <img src={cardIcons} />
                {t("dashboard.dropdownOps.card")}
              </button>
              <button
                className={pm == 2 ? "active" : null}
                onClick={() => setPm(2)}
              >
                {/*<img src={speiIcon} />
                SPEI
              </button>
              <button
                className={pm == 3 ? "active" : null}
                onClick={() => setPm(3)}
              >
                <img src={oxxopayicon} />
                OXXO
              </button>
              <button
                className={pm == 4 ? "active" : null}
                onClick={() => setPm(4)}
              >*/}
                <img src={paypalIcon} />
                PayPal
              </button>
            </div>
          </div>
          {pm == 1 && (
            <RenewSubscriptionFormShareable
              id={user_id}
              conektaid={conektaid}
              restaurant={restaurant}
            />
          )}
          {pm !== 1 && pm !== 4 && (
            <RenewSubscriptionSpeiOxxoFormShareable
              id={user_id}
              conektaid={conektaid}
              subscription={restaurant}
              user_email={user_email}
              pm={pm}
            ></RenewSubscriptionSpeiOxxoFormShareable>
          )}

          {pm == 4 && (
            <RenewSubscriptionPayPalForm
              id={user_id}
              conektaid={conektaid}
              subscription={{ restaurant_title: restaurant }}
              pm={pm}
            ></RenewSubscriptionPayPalForm>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default RenewSubscriptionPageNoAuth;
