import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import UserIsAdminRoute from "./components/auth/UserIsAdminRoute";
import UserIsAuthRoute from "./components/auth/UserIsAuthRoute";
import UserIsDistributorRoute from "./components/auth/UserIsDistributorRoute";
import UserIsNotAuthRoute from "./components/auth/UserIsNotAuthRoute";
import {
  ADMIN_CONNECTIONS_PATH,
  ADMIN_DISTRIBUTORS_PATH,
  ADMIN_HOME_PATH,
  ADMIN_MY_ACCOUNT_PATH,
  ADMIN_NEW_DISTRIBUTOR_PATH,
  ADMIN_PAYMENTS_PATH,
  ADMIN_TOOLS_PATH,
  ADMIN_USERS_PATH,
} from "./constants/adminPaths";
import {
  DISTRIBUTOR_DASHBOARD_PATH,
  DISTRIBUTOR_HOME_PATH,
  DISTRIBUTOR_MY_ACCOUNT_PATH,
  DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH,
  DISTRIBUTOR_REFERRALS_PATH,
} from "./constants/distributorPaths";
import {
  APP_HOME_PATH,
  APP_MY_ACCOUNT_PATH,
  APP_RESTAURANTS_PATH,
  COLLABORATOR_INSTALL_PATH,
  FORGOT_PASSWORD_PATH,
  GROUP_PATH,
  HOME_PATH,
  HOME_PATH_DISTRIBUTION,
  HOME_PATH_LANDING,
  INSTALL_PATH,
  INTRODUCTION_PATH,
  MY_PAYMENT_METHODS_PATH,
  MY_SUBSCRIPTIONS_PATH,
  NEW_GROUP_PATH,
  NEW_PAYMENT_METHOD_PATH,
  NEW_SUBSCRIPTION_PATH,
  PAYMENT_REFERENCE,
  PERSONAL_INSTALL_PATH,
  RENEW_SUBSCRIPTION_PATH,
  SIGNIN_PATH,
  SIGNUP_PATH,
  VERIFICATION_PATH,
  WMOBIL_INSTALL_PATH,
  POLITICAS_DE_PRIVACIDAD,
  TERMINOS_Y_CONDICIONES,
  PAYMENT_LINK_PATH,
  RENEW_SUBSCRIPTION_PATH_NO_AUTH,
  APP_MY_FACTURAS_PATH,
  APP_CREATE_FACTURA,
  APP_SEARCH_FOLIO_FACTURA,
  HOME_PATH_FACTURACION,
  APP_CREATE_GENERAL_FACTURA,
  APP_WFACTURAS_CLIENTS,
  APP_WFACTURAS_PLANS,
  APP_WFACTURAS_SETTINGS,
  APP_CREATE_PARTIAL_PAY,
  APP_PARTIAL_PAY_LEGAL_DATA,
} from "./constants/paths";
import AdminAccountPage from "./pages/admin/AdminAccountPage";
import AdminDistributorPage from "./pages/admin/AdminDistributorPage";
import AdminPaymentsPage from "./pages/admin/AdminPaymentsPage";
import AdminToolsPage from "./pages/admin/AdminToolsPage";
import { NotificationContextProvider } from "./context/NotificationContext";

import AdminDistributorsPage from "./pages/admin/AdminDistributorsPage";
import AdminHomePage from "./pages/admin/AdminHomePage";
import AdminRestaurantPage from "./pages/admin/AdminRestaurantPage";
import AdminUserPage from "./pages/admin/AdminUserPage";
import NewDistributorPage from "./pages/admin/NewDistributorPage";
import ColaboradorInstall from "./pages/app/ColaboradorInstall";
import DashboardPage from "./pages/app/DashboardPage";
import EditGroupPage from "./pages/app/groups/EditGroupPage";
import NewGroupPage from "./pages/app/groups/NewGroupPage";
import Install from "./pages/app/Install";
import Intro from "./pages/app/Introduction";
import MyAccountPage from "./pages/app/MyAccountPage";
import MyPaymentMethodsPage from "./pages/app/MyPaymentMethodsPage";
import MySubscriptionPage from "./pages/app/MySubscriptionPage";
import MySubscriptionsPage from "./pages/app/MySubscriptionsPage";
import NewPaymentMethodPage from "./pages/app/NewPaymentMethodPage";
import NewSubscriptionPage from "./pages/app/NewSubscriptionPage";
import PaymentReference from "./pages/app/PaymentReference";
import PersonalInstall from "./pages/app/PersonalInstall";
import RenewSubscriptionPage from "./pages/app/RenewSubscriptionPage";
import RestaurantPage from "./pages/app/RestaurantPage";
import TablePage from "./pages/app/TablePage";
import Verification from "./pages/app/Verification";
import WmobilInstall from "./pages/app/WmobilInstall";
import DistributorAccountPage from "./pages/distributor/DistributorAccountPage";
import DistributorHomePage from "./pages/distributor/DistributorHomePage";
import DistributorReferralPage from "./pages/distributor/DistributorReferralPage";
import DistributorRestaurantPage from "./pages/distributor/DistributorRestaurantPage";
import ForgotPassword from "./pages/public/ForgotPassword";
import HomePage from "./pages/public/HomePage";
import NotFoundPage from "./pages/public/NotFoundPage";
import SigninPage from "./pages/public/SigninPage/SigninPage";
import SignupPage from "./pages/public/SignupPage/SignupPage";
import PoliticasdePrivacidad from "./pages/public/PoliticasdePrivacidad/PoliticasdePrivacidad";
import TerminosyCondiciones from "./pages/public/TerminosyCondiciones/TerminosyCondiciones";
import AdminConnectionsPage from "./pages/admin/AdminConnectionsPage";
import MyShareableLink from "./pages/app/MyShareableLink";
import { LandingPage } from "./pages/public/LandingPage/LandingPage";
import {
  DistributorDashboard,
  DistributorDashboardPage,
} from "./pages/distributor/DistributorDashboard/DistributorDashboardPage";
import "./styles/index.scss";
import { PaymentDailyDetailsPage } from "./pages/distributor/PaymentDailyDetails/PaymentDailyDetailsPage";
import RenewSubscriptionPageNoAuth from "./pages/app/RenewSubscriptionPageNoAuth";
import MyFacturasPage from "./pages/app/MyFacturasPage";
import SearchFacturaPage from "./pages/app/SearchFacturaPage";
import FacturacionPage from "./pages/public/FacturacionPage";
import FacturacionFormPage from "./pages/public/FacturacionFormPage";
import NewFacturaPage from "./pages/app/NewFacturaPage";
import { TimbresProvider } from "./context/TimbresContext";
import { ToastProvider } from "./context/ToastContext";
import ClientsPage from "./pages/app/ClientsPage";
import PlansPage from "./pages/app/PlansPage";
import PlansPay from "./pages/app/PlansPay";
import SettingsPage from "./pages/app/SettingsPage";
import { ProfilePicProvider } from "./context/ProfilePicContext";
import DashboardPageMock from "./pages/app/DashboardPageMock";
import NewPartialPayPage from "./pages/app/NewPartialPayPage";
import LegalDataPage from "./pages/app/LegalDataPage";

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <TimbresProvider>
          <ProfilePicProvider>
            <Switch>
              {/* Routes that don't need auth */}
              <Route exact path={HOME_PATH_LANDING}>
                <UserIsNotAuthRoute>
                  <LandingPage location={"landing"} type="restaurantes" />
                </UserIsNotAuthRoute>
              </Route>
              <Route exact path={HOME_PATH_DISTRIBUTION}>
                <UserIsNotAuthRoute>
                  <LandingPage location={"landing"} type="distribuidores" />
                </UserIsNotAuthRoute>
              </Route>
              <Route exact path={POLITICAS_DE_PRIVACIDAD}>
                <UserIsNotAuthRoute>
                  <PoliticasdePrivacidad />
                </UserIsNotAuthRoute>
              </Route>
              <Route exact path={TERMINOS_Y_CONDICIONES}>
                <UserIsNotAuthRoute>
                  <TerminosyCondiciones />
                </UserIsNotAuthRoute>
              </Route>
              <Route exact path={VERIFICATION_PATH}>
                <UserIsAuthRoute>
                  <Verification />
                </UserIsAuthRoute>
              </Route>
              <Route exact path={INTRODUCTION_PATH}>
                <UserIsAuthRoute>
                  <Intro />
                </UserIsAuthRoute>
              </Route>
              <Route exact path={INSTALL_PATH}>
                <UserIsAuthRoute>
                  <Install />
                </UserIsAuthRoute>
              </Route>
              <Route exact path={PERSONAL_INSTALL_PATH}>
                <PersonalInstall />
              </Route>
              <Route exact path={COLLABORATOR_INSTALL_PATH}>
                <UserIsAuthRoute>
                  <ColaboradorInstall />
                </UserIsAuthRoute>
              </Route>
              <Route exact path={WMOBIL_INSTALL_PATH}>
                <UserIsAuthRoute>
                  <WmobilInstall />
                </UserIsAuthRoute>
              </Route>
              <Route
                exact
                path={SIGNUP_PATH}
                render={(props) => {
                  return (
                    <UserIsNotAuthRoute>
                      <SignupPage {...props} />
                    </UserIsNotAuthRoute>
                  );
                }}
              />
              <Route exact path={SIGNIN_PATH}>
                <UserIsNotAuthRoute>
                  <SigninPage />
                </UserIsNotAuthRoute>
              </Route>
              <Route exact path={FORGOT_PASSWORD_PATH}>
                <UserIsNotAuthRoute>
                  <ForgotPassword />
                </UserIsNotAuthRoute>
              </Route>
              <Route exact path={HOME_PATH_FACTURACION}>
                <UserIsNotAuthRoute>
                  <FacturacionPage />
                </UserIsNotAuthRoute>
              </Route>
              <Route
                exact
                path={`${HOME_PATH_FACTURACION}/:id`}
                render={(props) => {
                  return (
                    <UserIsNotAuthRoute>
                      <FacturacionPage {...props} />
                    </UserIsNotAuthRoute>
                  );
                }}
              />
              <Route
                exact
                path={`${HOME_PATH_FACTURACION}/:rest_id/:folio_id/:date`}
                render={(props) => {
                  return (
                    <UserIsNotAuthRoute>
                      <FacturacionFormPage {...props} />
                    </UserIsNotAuthRoute>
                  );
                }}
              />
              {/* Routes for app */}
              <Route exact path={APP_HOME_PATH}>
                <UserIsAuthRoute>
                  <DashboardPageMock />
                </UserIsAuthRoute>
              </Route>
              <Route
                exact
                path={`${APP_RESTAURANTS_PATH}/:id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <RestaurantPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route
                exact
                path={`${APP_RESTAURANTS_PATH}/:restaurant_id/cuentas/:table_id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <TablePage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route exact path={APP_MY_ACCOUNT_PATH}>
                <UserIsAuthRoute>
                  <MyAccountPage />
                </UserIsAuthRoute>
              </Route>
              <Route
                exact
                path={NEW_SUBSCRIPTION_PATH}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <NewSubscriptionPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route
                exact
                path={RENEW_SUBSCRIPTION_PATH}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <RenewSubscriptionPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route
                exact
                path={`${PAYMENT_LINK_PATH}=:user_id/rid=:rest_id`}
                render={(props) => {
                  return <RenewSubscriptionPageNoAuth {...props} />;
                }}
              />
              <Route
                exact
                path={PAYMENT_REFERENCE}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <PaymentReference {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route
                exact
                path={NEW_PAYMENT_METHOD_PATH}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <NewPaymentMethodPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route
                exact
                path={NEW_PAYMENT_METHOD_PATH + "/:userId"}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <NewPaymentMethodPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route exact path={MY_SUBSCRIPTIONS_PATH}>
                <UserIsAuthRoute data={MY_SUBSCRIPTIONS_PATH}>
                  <MySubscriptionsPage />
                </UserIsAuthRoute>
              </Route>
              <Route
                exact
                path={`${MY_SUBSCRIPTIONS_PATH}/:id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <MySubscriptionPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />
              <Route exact path={MY_PAYMENT_METHODS_PATH}>
                <UserIsAuthRoute>
                  <MyPaymentMethodsPage />
                </UserIsAuthRoute>
              </Route>
              <Route exact path={NEW_GROUP_PATH}>
                <UserIsAuthRoute>
                  <NewGroupPage />
                </UserIsAuthRoute>
              </Route>
              <Route
                exact
                path={`${GROUP_PATH}/:id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <EditGroupPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />

              <Route
                exact
                path={`${APP_MY_FACTURAS_PATH}`}
                render={(props) => {
                  const queryParams = new URLSearchParams(
                    props.location.search
                  );
                  const page = queryParams.get("page") || 1;
                  const limit = queryParams.get("limit") || 100;

                  return (
                    <UserIsAuthRoute>
                      <MyFacturasPage
                        page={parseInt(page)}
                        limit={parseInt(limit)}
                      />
                    </UserIsAuthRoute>
                  );
                }}
              />

              <Route exact path={APP_SEARCH_FOLIO_FACTURA}>
                <UserIsAuthRoute>
                  <SearchFacturaPage />
                </UserIsAuthRoute>
              </Route>

              <Route
                exact
                path={`${APP_CREATE_FACTURA}/:rest_id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <NewFacturaPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />

              <Route
                exact
                path={`${APP_CREATE_GENERAL_FACTURA}/:rest_id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <NewFacturaPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />

              <Route
                exact
                path={`${APP_PARTIAL_PAY_LEGAL_DATA}/:rest_id`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <LegalDataPage {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />

              <Route exact path={APP_CREATE_PARTIAL_PAY}>
                <UserIsAuthRoute>
                  <NewPartialPayPage />
                </UserIsAuthRoute>
              </Route>

              <Route exact path={APP_WFACTURAS_CLIENTS}>
                <UserIsAuthRoute>
                  <ClientsPage />
                </UserIsAuthRoute>
              </Route>

              <Route exact path={APP_WFACTURAS_PLANS}>
                <UserIsAuthRoute>
                  <PlansPage />
                </UserIsAuthRoute>
              </Route>

              <Route
                exact
                path={`${APP_WFACTURAS_PLANS}/:restId/:plan`}
                render={(props) => {
                  return (
                    <UserIsAuthRoute>
                      <PlansPay {...props} />
                    </UserIsAuthRoute>
                  );
                }}
              />

              <Route exact path={APP_WFACTURAS_SETTINGS}>
                <UserIsAuthRoute>
                  <SettingsPage />
                </UserIsAuthRoute>
              </Route>

              {/* ADMIN ROUTES */}

              <Route exact path={ADMIN_HOME_PATH}>
                <UserIsAdminRoute>
                  <AdminHomePage />
                </UserIsAdminRoute>
              </Route>
              <Route exact path={ADMIN_CONNECTIONS_PATH}>
                <UserIsAdminRoute>
                  <NotificationContextProvider>
                    <AdminConnectionsPage />
                  </NotificationContextProvider>
                </UserIsAdminRoute>
              </Route>
              <Route
                exact
                path={`${ADMIN_USERS_PATH}/:id`}
                render={(props) => {
                  return (
                    <UserIsAdminRoute>
                      <AdminUserPage {...props} />
                    </UserIsAdminRoute>
                  );
                }}
              />
              <Route
                exact
                path={`${PAYMENT_LINK_PATH}=:user_id`}
                render={(props) => {
                  return <MyShareableLink {...props} />;
                }}
              />
              <Route
                exact
                path={`${ADMIN_USERS_PATH}/:user_id/restaurantes/:id`}
                render={(props) => {
                  return (
                    <UserIsAdminRoute>
                      <AdminRestaurantPage {...props} />
                    </UserIsAdminRoute>
                  );
                }}
              />
              <Route exact path={ADMIN_MY_ACCOUNT_PATH}>
                <UserIsAdminRoute>
                  <AdminAccountPage />
                </UserIsAdminRoute>
              </Route>
              <Route exact path={ADMIN_NEW_DISTRIBUTOR_PATH}>
                <UserIsAdminRoute>
                  <NewDistributorPage />
                </UserIsAdminRoute>
              </Route>
              <Route exact path={ADMIN_DISTRIBUTORS_PATH}>
                <UserIsAdminRoute>
                  <AdminDistributorsPage />
                </UserIsAdminRoute>
              </Route>
              <Route exact path={ADMIN_PAYMENTS_PATH}>
                <UserIsAdminRoute>
                  <AdminPaymentsPage />
                </UserIsAdminRoute>
              </Route>

              <Route exact path={ADMIN_TOOLS_PATH}>
                <UserIsAdminRoute>
                  <AdminToolsPage />
                </UserIsAdminRoute>
              </Route>
              <Route
                exact
                path={`${ADMIN_DISTRIBUTORS_PATH}/:id`}
                render={(props) => {
                  return (
                    <UserIsAdminRoute>
                      <AdminDistributorPage {...props} />
                    </UserIsAdminRoute>
                  );
                }}
              />

              <Route exact path={DISTRIBUTOR_HOME_PATH}>
                <UserIsDistributorRoute>
                  <DistributorHomePage />
                </UserIsDistributorRoute>
              </Route>
              <Route exact path={DISTRIBUTOR_DASHBOARD_PATH}>
                <UserIsDistributorRoute>
                  <DistributorDashboardPage />
                </UserIsDistributorRoute>
              </Route>
              <Route exact path={DISTRIBUTOR_MY_ACCOUNT_PATH}>
                <UserIsDistributorRoute>
                  <DistributorAccountPage />
                </UserIsDistributorRoute>
              </Route>
              <Route exact path={DISTRIBUTOR_PAYMENT_DAILY_DETAILS_PATH}>
                <UserIsDistributorRoute>
                  <PaymentDailyDetailsPage />
                </UserIsDistributorRoute>
              </Route>
              <Route
                exact
                path={`${DISTRIBUTOR_REFERRALS_PATH}/:id`}
                render={(props) => {
                  return (
                    <UserIsDistributorRoute>
                      <DistributorReferralPage {...props} />
                    </UserIsDistributorRoute>
                  );
                }}
              />
              <Route
                exact
                path={`${DISTRIBUTOR_REFERRALS_PATH}/:user_id/restaurantes/:restaurant_id`}
                render={(props) => {
                  return (
                    <UserIsDistributorRoute>
                      <DistributorRestaurantPage {...props} />
                    </UserIsDistributorRoute>
                  );
                }}
              />
              {/* Not found page */}
              <Route>
                <NotFoundPage />
              </Route>
            </Switch>
          </ProfilePicProvider>
        </TimbresProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
