import axios from "axios";
import { API_PATH, HISTORIC_PATH } from "../constants/api";
import i18next from "i18next";
import translationEn from "../translations/en/translation.json";
import translationEs from "../translations/es/translation.json";

import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

var currentLanguage = localStorage.getItem("i18nextLng");
i18next.init({
  debug: false,
  fallbackLng: currentLanguage || "es-MX",
  //keySeparator: false,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      translation: translationEn,
    },
    es: {
      translation: translationEs,
    },
  },
});
const t = i18next.t;

const paymentFormText = {
  "01": "Efectivo",
  "02": "Cheque nominativo",
  "03": "Transferencia electrónica de fondos",
  "04": "Tarjeta de crédito",
  "05": "Monedero electrónico",
  "06": "Dinero electrónico",
  "08": "Vales de despensa",
  12: "Dación en pago",
  13: "Pago por subrogación",
  14: "Pago por consignación",
  15: "Condonación",
  17: "Compensación",
  23: "Novación",
  24: "Confusión",
  25: "Remisión de deuda",
  26: "Prescripción o caducidad",
  27: "A satisfacción del acreedor",
  28: "Tarjeta de débito",
  29: "Tarjeta de servicios",
  30: "Aplicación de anticipos",
  31: "Intermediario pagos",
  99: "Por definir",
};

export const getRestaurants = (selectedGroup) => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/restaurants?group=${selectedGroup}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.restaurants;
    })
    .catch((error) => {
      if (error.response && error.response.data)
        throw error.response.data.error;

      throw error;
    });
};

export const getRestaurant = (id) => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/restaurants/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.restaurant;
    })
    .catch((error) => {
      if (error.response && error.response.data)
        throw error.response.data.error;

      throw error;
    });
};

export const getReporteVentas = (restaurantId, type, startDate, endDate) => {
  const token = localStorage.getItem("token");
  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/${type}?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (type === "grupo") {
        generateReporteVentasGrupo(
          restaurantId,
          response.data.report.grupos,
          response.data.report.ventaTotalSinDescuento,
          response.data.report.ventaTotalConDescuento,
          response.data.report.ventaTotalConIva,
          response.data.report.cantidadTotal,
          response.data.report.descuento,
          startDate,
          endDate
        );
      }
      if (type === "corte")
        generateReporteVentasCorte(
          restaurantId,
          response.data.cortes,
          response.data.totalizadorDeTotal,
          response.data.totalizadorDeSubtotal,
          response.data.totalizadorDeEfectivo,
          response.data.totalizadorDeTarjeta,
          response.data.totalizadorDeVales,
          response.data.totalizadorDeOtros,
          response.data.totalTurnos,
          startDate,
          endDate
        );

      if (type === "movimientos")
        generateReporteVentasMovimientos(
          restaurantId,
          response.data.totalizadorDepositos,
          response.data.totalDepositos,
          response.data.totalizadorRetiros,
          response.data.totalRetiros,
          response.data.totalMovimientos,
          response.data.reporte,
          startDate,
          endDate
        );
    })
    .catch((error) => {
      console.error(error);
      alert("Necesita actualizar el poleador");
      throw error;
    });
};

export const getVentasPorGrupo = (restaurantDB, actualDate) => {
  const token = localStorage.getItem("token");
  return axios
    .get(`${API_PATH}/restaurants/groups/${restaurantDB}?today=${actualDate}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getfechasAtrasadas = (restaurantId, startDate, endDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/fechasPasadas?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const generateReporteVentasGrupo = (
  restaurantId,
  grupos,
  ventaTotalSinDescuento,
  ventaTotalConDescuento,
  ventaTotalConIva,
  cantidadTotal,
  descuento,
  startDate,
  endDate
) => {
  const start_date = startDate.slice(0, 10);
  const end_date = endDate.slice(0, 10);
  const doc = new jsPDF("l", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  try {
    doc.addSvgAsImage(img, 480, 40, 60, 60);
  } catch (error) {}

  doc.line(40, 40, 560, 40); // horizontal line

  //doc.text(totalizadorDeTotal.toString(), 40, 60);

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep1"), 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.rep1Par1") +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");

  doc.autoTable({
    headStyles: { fillColor: "#21840e", fontSize: "7" },
    bodyStyles: { fontSize: "9" },
    startY: 115,
    columnStyles: {
      0: { cellWidth: 200 },
      1: { cellWidth: 60 },
      // etc
    },
    head: [
      [
        t("restaurantJs.gSalesHead1"),
        t("restaurantJs.gSalesHead2"),
        t("restaurantJs.gSalesHead4"),
        t("restaurantJs.gSalesHead5"),
        "Importe total con IVA",
      ],
    ],
    body: grupos.map((grupo) => [
      `${grupo.grupo}`,
      `${parseFloat(grupo.cantidad).toFixed(2)}`,
      `${formatter.format(
        isNaN(grupo.totalSinDescuento)
          ? parseFloat(grupo.totalSinDescuento)
          : grupo.totalSinDescuento
      )}`,
      `${formatter.format(
        isNaN(0.0)
          ? parseFloat(grupo.totalSinDescuento - grupo.totalConDescuento)
          : grupo.totalSinDescuento - grupo.totalConDescuento
      )}`,
      `${formatter.format(
        isNaN(grupo.totalConIva)
          ? parseFloat(grupo.totalConIva)
          : grupo.totalConIva
      )}`,
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7", fontSize: "9" },
    headStyles: { fillColor: "#21840e", fontSize: "7" },
    startY: finalY_1 + 25,
    columnStyles: {
      0: { cellWidth: 200 },
      1: { cellWidth: 60 },
      // etc
    },
    head: [
      [
        "Total:",
        t("restaurantJs.gSalesHead6"),
        t("restaurantJs.gSalesHead4"),
        t("restaurantJs.gSalesHead5"),
        "Venta total con IVA",
      ],
    ],
    body: [
      [
        "",
        parseFloat(cantidadTotal).toFixed(2),
        formatter.format(
          isNaN(ventaTotalSinDescuento)
            ? parseFloat(ventaTotalSinDescuento)
            : ventaTotalSinDescuento
        ),
        formatter.format(isNaN(descuento) ? parseFloat(descuento) : descuento),
        formatter.format(
          isNaN(ventaTotalConIva)
            ? parseFloat(ventaTotalConIva)
            : ventaTotalConIva
        ),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName1")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Ventas - grupos).pdf`);
};

const generateReporteVentasCorte = (
  restaurantId,
  cortes,
  totalizadorDeTotal,
  totalizadorDeSubtotal,
  totalizadorDeEfectivo,
  totalizadorDeTarjeta,
  totalizadorDeVales,
  totalizadorDeOtros,
  totalTurnos,
  startDate,
  endDate
) => {
  const satrt_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("l", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  try {
    doc.addSvgAsImage(img, 480, 40, 60, 60);
  } catch (error) {}

  doc.line(40, 40, 560, 40); // horizontal line

  //doc.text(totalizadorDeTotal.toString(), 40, 60);
  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep1"), 40, 70);
  //report paragraph
  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.salesSub1") +
      satrt_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );
  //report table
  doc.setFont("Arial", "normal");

  //pegarle los datos al final de la tabla para hacer conclusivo
  /*   var finalHeaderRow = {
      cierre: "Cierre",
      apertura: "Apertura",
      total: "Importe Final",
      subTotal: "Importe sin IVA Final",
      efectivo: "Total de Efectivo",
      tarjeta: "Total de Tarjeta",
      vales: "Total de Vales",
      otros: "Total de Otros",
  
    } */

  //cortes.push(finalHeaderRow)
  // cortes.push(finalRow)
  doc.autoTable({
    headStyles: { fillColor: "#21840e", fontSize: "7" },
    bodyStyles: { fontSize: "9" },
    startY: 115,
    columnStyles: {
      0: { cellWidth: 60 },
      1: { cellWidth: 60 },
      // etc
    },
    head: [
      [
        t("restaurantJs.salesHead1"),
        t("restaurantJs.salesHead2"),
        t("restaurantJs.salesHead3"),
        t("restaurantJs.salesHead4"),
        t("restaurantJs.salesHead5"),
        t("restaurantJs.salesHead6"),
        t("restaurantJs.salesHead7"),
        t("restaurantJs.salesHead8"),
      ],
    ],
    body: cortes.map((corte) => [
      `${new Date(corte.apertura).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(corte.apertura).toLocaleTimeString()}`,
      `${new Date(corte.cierre).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(corte.cierre).toLocaleTimeString()}`,
      corte.total === "Importe Final"
        ? corte.total
        : formatter.format(
            isNaN(corte.total) ? parseFloat(corte.total) : corte.total
          ),
      corte.utilidad === "Importe sin IVA Final"
        ? corte.total
        : formatter.format(
            isNaN(corte.total)
              ? parseFloat(corte.total) / 1.16
              : corte.total / 1.16
          ),
      corte.efectivo === "Total de Efectivo"
        ? corte.efectivo
        : formatter.format(
            isNaN(corte.efectivo) ? parseFloat(corte.efectivo) : corte.efectivo
          ),
      corte.tarjeta === "Total de Tarjeta"
        ? corte.tarjeta
        : formatter.format(
            isNaN(corte.tarjeta) ? parseFloat(corte.tarjeta) : corte.tarjeta
          ),
      corte.otros === "Total de Otros"
        ? corte.otros
        : formatter.format(
            isNaN(corte.otros) ? parseFloat(corte.otros) : corte.otros
          ),
      corte.vales === "Total de Vales"
        ? corte.vales
        : formatter.format(
            isNaN(corte.vales) ? parseFloat(corte.vales) : corte.vales
          ),
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7", fontSize: "8" },
    headStyles: { fillColor: "#21840e", fontSize: "8" },
    startY: finalY_1 + 25,
    head: [
      [
        t("restaurantJs.salesTotal1"),
        t("restaurantJs.salesTotal2"),
        t("restaurantJs.salesTotal3"),
        t("restaurantJs.salesTotal4"),
        t("restaurantJs.salesTotal5"),
        t("restaurantJs.salesTotal6"),
      ],
    ],
    body: [
      [
        formatter.format(
          isNaN(totalizadorDeTotal)
            ? parseFloat(totalizadorDeTotal)
            : totalizadorDeTotal
        ),
        formatter.format(
          isNaN(totalizadorDeSubtotal)
            ? parseFloat(totalizadorDeSubtotal)
            : totalizadorDeSubtotal
        ),
        formatter.format(
          isNaN(totalizadorDeEfectivo)
            ? parseFloat(totalizadorDeEfectivo)
            : totalizadorDeEfectivo
        ),
        formatter.format(
          isNaN(totalizadorDeTarjeta)
            ? parseFloat(totalizadorDeTarjeta)
            : totalizadorDeTarjeta
        ),
        formatter.format(
          isNaN(totalizadorDeOtros)
            ? parseFloat(totalizadorDeOtros)
            : totalizadorDeOtros
        ),
        formatter.format(
          isNaN(totalizadorDeVales)
            ? parseFloat(totalizadorDeVales)
            : totalizadorDeVales
        ),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName2")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Ventas - cortes).pdf`);
};

const generateReporteVentasMovimientos = (
  restaurantId,
  totalizadorDepositos,
  totalDepositos,
  totalizadorRetiros,
  totalRetiros,
  totalMovimientos,
  movimientos,
  startDate,
  endDate
) => {
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep2"), 40, 70);
  doc.setFont("Arial", "normal");

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.rep2Par1") +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [
      [
        t("restaurantJs.movsHead1"),
        t("restaurantJs.movsHead2"),
        t("restaurantJs.movsHead3"),
        t("restaurantJs.movsHead4"),
        t("restaurantJs.movsHead5"),
        t("restaurantJs.movsHead6"),
      ],
    ],
    body: movimientos.map((movimiento) => [
      movimiento.idturno,
      `${new Date(movimiento.fecha).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(movimiento.fecha).toLocaleTimeString()}`,
      movimiento.tipo === "1"
        ? t("restPage.paymentsChart2Op1")
        : t("restPage.paymentsChart2Op2"),
      movimiento.concepto,
      movimiento.referencia,
      formatter.format(
        isNaN(movimiento.importe)
          ? parseFloat(movimiento.importe)
          : movimiento.importe
      ),
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [
      [
        t("restaurantJs.movsTotal1"),
        t("restaurantJs.movsTotal2"),
        t("restaurantJs.movsTotal3"),
        t("restaurantJs.movsTotal4"),
        t("restaurantJs.movsTotal5"),
        t("restaurantJs.movsTotal6"),
      ],
    ],
    body: [
      [
        parseFloat(totalMovimientos),
        formatter.format(
          isNaN(totalizadorDepositos)
            ? parseFloat(totalizadorDepositos)
            : totalizadorDepositos
        ),
        parseFloat(totalDepositos),
        formatter.format(
          isNaN(totalizadorRetiros)
            ? parseFloat(totalizadorRetiros)
            : totalizadorRetiros
        ),
        parseFloat(totalRetiros),
        parseFloat(totalMovimientos),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName3")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Ventas - movimientos).pdf`);
};

export const getReporteCancelaciones = (
  restaurantId,
  type,
  startDate,
  endDate
) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/${type}?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (type === "cuentas_canceladas")
        generateReporteCuentasCanceladas(
          restaurantId,
          response.data.totalizadorImporteCancelado,
          response.data.totalCancelacionesEnCuentas,
          response.data.reporte.cuentas,
          startDate,
          endDate
        );

      if (type === "productos_cancelados")
        generateReporteProductosCancelados(
          restaurantId,
          response.data.totalizadorImporteCancelado,
          response.data.totalCancelacionesEnProductos,
          response.data.reporte.productos,
          startDate,
          endDate
        );
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const generateReporteCuentasCanceladas = (
  restaurantId,
  totalizadorImporteCancelado,
  totalCancelacionesEnCuentas,
  cuentas,
  startDate,
  endDate
) => {
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep3"), 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.rep3Par1") +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");
  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [
      [
        t("restaurantJs.cancHead1"),
        t("restaurantJs.cancHead7"),
        t("restaurantJs.cancHead3"),
        t("restaurantJs.cancHead4"),
        t("restaurantJs.cancHead5"),
      ],
    ],
    body: cuentas.map((cuenta) => [
      cuenta.folio,
      `${new Date(cuenta.fecha).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(cuenta.fecha).toLocaleTimeString()}`,
      formatter.format(
        isNaN(cuenta.importe) ? parseFloat(cuenta.importe) : cuenta.importe
      ),
      cuenta.usuario_cancelo,
      cuenta.razon_cancelado,
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [[t("restaurantJs.cancTotal1"), t("restaurantJs.cancTotal2")]],
    body: [
      [
        parseFloat(totalCancelacionesEnCuentas),
        formatter.format(
          isNaN(totalizadorImporteCancelado)
            ? parseFloat(totalizadorImporteCancelado)
            : totalizadorImporteCancelado
        ),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName4")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Cancelaciones - cuentas).pdf`);
};

const generateReporteProductosCancelados = (
  restaurantId,
  totalizadorImporteCancelado,
  totalCancelacionesEnProductos,
  productos,
  startDate,
  endDate
) => {
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);

  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep4"), 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.rep4Par1") +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");
  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [
      [
        t("restaurantJs.cancHead1"),
        t("restaurantJs.cancHead7"),
        t("restaurantJs.gSalesHead2"),
        t("restPage.accTableHeader2"),
        t("restaurantJs.cancHead6"),
        t("restPage.discountHeader7"),
        t("restaurantJs.cancHead4"),
        "Razon",
      ],
    ],
    body: productos.map((producto) => [
      producto.folio,
      `${new Date(producto.fecha).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(producto.fecha).toLocaleTimeString()}`,
      producto.cantidad,
      producto.mesero.nombre,
      producto.producto,
      formatter.format(
        isNaN(producto.precio) ? parseFloat(producto.precio) : producto.precio
      ),
      producto.usuario_cancelo,
      producto.razon,
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [[t("restaurantJs.cancTotal1"), t("restaurantJs.cancTotal2")]],
    body: [
      [
        parseFloat(totalCancelacionesEnProductos),

        formatter.format(
          isNaN(totalizadorImporteCancelado)
            ? parseFloat(totalizadorImporteCancelado)
            : totalizadorImporteCancelado
        ),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName5")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Cancelaciones - productos).pdf`);
};

export const getReporteDescuentos = (
  restaurantId,
  type,
  startDate,
  endDate
) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/${type}?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      if (type === "descuento_a_cuentas")
        generateReporteDescuentoACuentas(
          restaurantId,
          response.data.totalizadorDeImporte,
          response.data.totalizadorDeDescuentos,
          response.data.totalCuentas,
          response.data.reporte.cuentas,
          startDate,
          endDate
        );

      if (type === "descuento_a_productos")
        generateReporteDescuentoAProductos(
          restaurantId,
          response.data.totalizadorImporteConDescuento,
          response.data.totalizadorImporteSinDescuento,
          response.data.totalProductosConDescuentos,
          response.data.reporte.productos,
          startDate,
          endDate
        );
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const generateReporteDescuentoACuentas = (
  restaurantId,
  totalizadorDeImporte,
  totalizadorDeDescuentos,
  totalCuentas,
  cuentas,
  startDate,
  endDate
) => {
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep5"), 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.rep5Par1") +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");
  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [
      [
        t("restaurantJs.cancHead1"),
        t("restaurantJs.cancHead2"),
        t("restaurantJs.cancHead3"),
        t("restaurantJs.gSalesHead5"),
        t("restaurantJs.descHead1"),
        "Comentario",
      ],
    ],
    body: cuentas.map((cuenta) => [
      cuenta.folio,
      `${new Date(cuenta.fecha).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(cuenta.fecha).toLocaleTimeString()}`,
      formatter.format(
        isNaN(cuenta.importe) ? parseFloat(cuenta.importe) : cuenta.importe
      ),
      `${(cuenta.porcentaje_descuento * 100).toFixed(2)} %`,
      formatter.format(
        isNaN(cuenta.importe_descuento)
          ? parseFloat(cuenta.importe_descuento)
          : cuenta.importe_descuento
      ),
      cuenta.comentario,
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [
      [
        t("restaurantJs.descTotal1"),
        t("restaurantJs.descTotal2"),
        t("restaurantJs.descTotal3"),
      ],
    ],
    body: [
      [
        parseFloat(totalCuentas),
        formatter.format(
          isNaN(totalizadorDeImporte)
            ? parseFloat(totalizadorDeImporte)
            : totalizadorDeImporte
        ),
        formatter.format(
          isNaN(totalizadorDeDescuentos)
            ? parseFloat(totalizadorDeDescuentos)
            : totalizadorDeDescuentos
        ),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName6")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Descuentos - cuentas).pdf`);
};

export const getReporteAsistencias = (restaurantId, startDate, endDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/asistencias/?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      generateReporteAsistencias(
        restaurantId,
        response.data.reporte.asistencias,
        startDate,
        endDate
      );
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const generateReporteAsistencias = (
  restaurantId,
  asistencias,
  startDate,
  endDate
) => {
  // Default export is a4 paper, portrait, using millimeters for units
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep6"), 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    `${t("restaurantJs.rep6Par1")} ${start_date} ${t(
      "restaurantJs.salesSub2"
    )} ${end_date}`,
    40,
    90
  );
  doc.setFont("Arial", "normal");

  Object.keys(asistencias).forEach((empleadoKey, index) => {
    const asistenciasGrupo = asistencias[empleadoKey].attendance;
    const totalHours = asistencias[empleadoKey].total_hours;

    //group title
    doc.setFont("Arial", "normal");
    doc.setFontSize(12);
    const startY = index === 0 ? 120 : doc.lastAutoTable.finalY + 20;
    doc.text(`${t("restaurantJs.attHead1")}: ${empleadoKey}`, 40, startY);

    doc.autoTable({
      headStyles: { fillColor: "#21840e" },
      startY: startY + 10,
      head: [
        [
          t("restaurantJs.attHead2"),
          t("restaurantJs.attHead3"),
          t("restaurantJs.attHead4"),
        ],
      ],
      body: asistenciasGrupo.map((asistencia) => [
        `${new Date(asistencia.entrada).toLocaleString(t("restaurantJs.lan"))}`,
        asistencia.salida
          ? `${new Date(asistencia.salida).toLocaleString(
              t("restaurantJs.lan")
            )}`
          : t("restaurantJs.attHead5"),
        `${asistencia.hours} ${t("restaurantJs.attHead4")}`,
      ]),
    });

    let finalY_1 = doc.previousAutoTable.finalY;
    // row for total hours
    doc.autoTable({
      startY: finalY_1,
      bodyStyles: { halign: "right", fontSize: 14 },
      body: [[`Total: ${totalHours} ${t("restaurantJs.attHead4")}`, ""]],
    });
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName8")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Asistencias).pdf`);
};

export const getReporteProductosEnProduccion = (restaurantId, startDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/productos_en_produccion/?start_date=${startDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      generateReporteProductosEnProduccion(
        restaurantId,
        response.data.reporte.productos
      );
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const generateReporteProductosEnProduccion = (restaurantId, productos) => {
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text("Resumen de productos en producción", 40, 70);
  doc.setFont("Arial", "normal");

  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 90,
    head: [
      [
        "Folio",
        "Producto",
        "Hora",
        "Hora de producción",
        "Minutos de preparación",
      ],
    ],
    body: productos.map((producto) => [
      producto.folio,
      producto.productodes,
      producto.hora,
      producto.horaproduccion,
      producto.minutospreparacion,
    ]),
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName9")}.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Productos en producción).pdf`);
};

const generateReporteDescuentoAProductos = (
  restaurantId,
  totalizadorImporteConDescuento,
  totalizadorImporteSinDescuento,
  totalProductosConDescuentos,
  productos,
  startDate,
  endDate
) => {
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(t("restaurantJs.salesRep7"), 40, 70);

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    t("restaurantJs.rep7Par1") +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");
  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [
      [
        t("restaurantJs.cancHead1"),
        t("restaurantJs.cancHead2"),
        t("restaurantJs.gSalesHead2"),
        t("restaurantJs.cancHead6"),
        "Comentario",
        "Usuario",
        t("restPage.discountHeader7"),
        t("restaurantJs.gSalesHead5"),
        t("restaurantJs.descHead1"),
      ],
    ],
    body: productos.map((producto) => [
      producto.folio,
      `${new Date(producto.fecha).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(producto.fecha).toLocaleTimeString()}`,
      producto.cantidad,
      producto.producto.nombre,
      producto.comentario,
      producto.usuario,
      formatter.format(
        isNaN(producto.precio) ? parseFloat(producto.precio) : producto.precio
      ),
      `${(producto.porcentaje_descuento * 100).toFixed(2)} %`,
      formatter.format(
        isNaN(producto.importe_descuento)
          ? parseFloat(producto.importe_descuento)
          : producto.importe_descuento
      ),
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [
      [
        t("restaurantJs.descTotal4"),
        t("restaurantJs.descTotal2"),
        t("restaurantJs.descTotal6"),
      ],
    ],
    body: [
      [
        parseFloat(totalProductosConDescuentos),
        formatter.format(
          isNaN(totalizadorImporteSinDescuento)
            ? parseFloat(totalizadorImporteSinDescuento)
            : totalizadorImporteSinDescuento
        ),
        formatter.format(
          isNaN(totalizadorImporteConDescuento)
            ? parseFloat(totalizadorImporteConDescuento)
            : totalizadorImporteConDescuento
        ),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} ${t("restaurantJs.repName7")}.pdf`,
  });

  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (Descuentos - productos).pdf`);
};

const generateInvoicesReport = (restaurantId, data, startDate, endDate) => {
  // Default export is a4 paper, portrait, using millimeters for units
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(
    `Resumen de facturas emitidas ${
      data.invoices.length > 0
        ? "por " + data.invoices[0].issuer_info.legal_name
        : ""
    }`,
    40,
    70
  );

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    "Resumen de facturas emitidas del periodo:\n" +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");
  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [
      ["Folio", "Estatus", "Fecha", "RFC", "Metodo de pago", "Monto con IVA"],
    ],
    body: data.invoices.map((invoice) => [
      `F${invoice.folio_number}`,
      invoice.status == "valid"
        ? "Facturada"
        : invoice.status == "canceled"
          ? "Cancelada"
          : invoice.status,
      `${new Date(invoice.created_at).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(invoice.created_at).toLocaleTimeString()}`,
      invoice.issuer_info.tax_id,
      paymentFormText[invoice.payment_form],
      formatter.format(
        isNaN(invoice.total) ? parseFloat(invoice.total) : invoice.total
      ),
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [
      [
        "Total de facturas",
        "Total de facturas canceladas con IVA",
        "Total de facturas válidas con IVA",
        "Monto total facturado con IVA",
      ],
    ],
    body: [
      [
        data.facturasCount,
        formatter.format(data.totalCancelado),
        formatter.format(data.totalFacturado),
        formatter.format(data.total),
      ],
    ],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} - invoices.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (invoices).pdf`);
};

export const getInvoicesReport = (restaurantId, startDate, endDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/invoices/?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      generateInvoicesReport(restaurantId, response.data, startDate, endDate);
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

const generateCanceledInvoicesReport = (
  restaurantId,
  data,
  startDate,
  endDate
) => {
  // Default export is a4 paper, portrait, using millimeters for units
  const start_date = startDate.slice(0, 10);
  const dateObject = new Date(endDate);
  dateObject.setDate(dateObject.getDate() - 1);
  const end_date = dateObject.toISOString().slice(0, 10);
  // Default export is a4 paper, portrait, using millimeters for units
  const doc = new jsPDF("p", "pt", "a4");

  doc.setFont("Arial", "normal");
  doc.text("Wmobil", 40, 30);
  var img = new Image();
  img.src = "../images/wmobil.svg";

  doc.addSvgAsImage(img, 480, 40, 60, 60);

  doc.line(40, 40, 560, 40); // horizontal line

  doc.setFont("Arial", "bold");
  doc.text(
    `Resumen de facturas canceladas ${
      data.invoices.length > 0
        ? "por " + data.invoices[0].issuer_info.legal_name
        : ""
    }`,
    40,
    70
  );

  doc.setFont("Arial", "normal");
  doc.setFontSize(12);
  doc.text(
    "Resumen de facturas canceladas del periodo:\n" +
      start_date +
      t("restaurantJs.salesSub2") +
      end_date,
    40,
    90
  );

  doc.setFont("Arial", "normal");
  doc.autoTable({
    headStyles: { fillColor: "#21840e" },
    startY: 115,
    head: [["Folio", "Fecha", "RFC", "Metodo de pago", "Monto con IVA"]],
    body: data.invoices.map((invoice) => [
      `F${invoice.folio_number}`,
      `${new Date(invoice.created_at).toLocaleDateString(
        t("restaurantJs.lan")
      )}\n${new Date(invoice.created_at).toLocaleTimeString()}`,
      invoice.issuer_info.tax_id,
      paymentFormText[invoice.payment_form],
      formatter.format(
        isNaN(invoice.total) ? parseFloat(invoice.total) : invoice.total
      ),
    ]),
  });

  let finalY_1 = doc.previousAutoTable.finalY;
  //insertar una tabla despues de otra tabla
  doc.autoTable({
    bodyStyles: { fillColor: "#7a7a7" },
    headStyles: { fillColor: "#21840e" },
    startY: finalY_1 + 25,
    head: [["Total de facturas", "Monto total cancelado con IVA"]],
    body: [[data.facturasCount, formatter.format(data.total)]],
  });

  doc.setProperties({
    title: `Wmobil ${restaurantId} - invoices.pdf`,
  });
  var string = doc.output("datauristring");
  var embed = "<embed width='100%' height='100%' src='" + string + "'/>";
  var x = window.open();
  x.document.open();
  x.document.write(`<style>body { margin: 0; } </style>${embed}`);
  x.document.close();

  //doc.save(`Wmobil ${restaurantId} (invoices).pdf`);
};

export const getCanceledInvoicesReport = (restaurantId, startDate, endDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/historic/restaurants/${restaurantId}/canceled_invoices/?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      generateCanceledInvoicesReport(
        restaurantId,
        response.data,
        startDate,
        endDate
      );
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const changePassword = (restaurantId, password) => {
  const token = localStorage.getItem("token");

  return axios
    .patch(
      `${API_PATH}/restaurants/${restaurantId}/password`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const validatePassword = (restaurantId, password) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/restaurants/${restaurantId}/password`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data.restaurant;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const setGoals = (restaurantId, goals) => {
  const token = localStorage.getItem("token");

  return axios
    .patch(
      `${API_PATH}/restaurants/${restaurantId}/goals`,
      {
        daily_goal: goals.daily,
        weekly_goal: goals.weekly,
        monthly_goal: goals.monthly,
        yearly_goal: goals.yearly,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data.message;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateRestaurantLogo = async (logo, myFile, restId) => {
  if (logo) {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("upload", myFile);
    try {
      const response = await axios.post(
        `${API_PATH}/upload/${restId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export const updateFolioSettings = async (
  restId,
  new_folio_start,
  new_duedate,
  enable_pmethod_select,
  invoice_general_description,
  selfinv_general_description,
  selfinv_active
) => {
  const token = localStorage.getItem("token");
  const folioData = {
    new_folio_start,
    new_duedate,
    enable_pmethod_select,
    invoice_general_description,
    selfinv_general_description,
    selfinv_active,
  };
  try {
    const response = await axios.post(
      `${API_PATH}/restaurants/update_folio_settings/${restId}`,
      folioData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateIndividualFolioExpoiration = async (
  restId,
  folio,
  new_hours
) => {
  const token = localStorage.getItem("token");
  const folioData = { folio, new_hours };
  try {
    const response = await axios.post(
      `${API_PATH}/restaurants/update_single_folio/${restId}`,
      folioData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSeriesTable = async (
  restId,
  serie_index,
  serie_id,
  folio_start,
  serie_type
) => {
  const token = localStorage.getItem("token");
  const serieData = { serie_index, serie_id, folio_start, serie_type };
  try {
    const response = await axios.post(
      `${API_PATH}/restaurants/update_series/${restId}`,
      serieData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const callSyncCheques = async (restId, month, year) => {
  const token = localStorage.getItem("token");
  const period = { month, year };
  try {
    const response = await axios.post(
      `${API_PATH}/restaurants/sync_cheques/${restId}`,
      period,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
