import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import {
//   CardElement,
//   useStripe,
//   useElements,
//   CardNumberElement,
//   CardExpiryElement,
//   CardCvcElement,
// } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

import { Redirect, useParams } from "react-router-dom";
import ErrorComponent from "../ErrorComponent";
import { registerMethod } from "../../api/User";
import LoadingIndicator from "../LoadingIndicator";
import axios from "axios";
import { API_PATH } from "../../constants/api";

const schema = yup.object().shape({
  name: yup.string().required("Campo requerido"),
  number: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(16, "Must be exactly 5 digits")
    .max(16, "Must be exactly 5 digits"),
  exp_year: yup.string().required("Campo incompleto o erróneo"),
  exp_month: yup.string().required("Campo incompleto o erróneo"),
  cvc: yup.string().required("Campo incompleto o erróneo"),
});

const NewPaymentMethodForm = ({ redirect }) => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setCustomError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Instantiate form
  const { register, handleSubmit, errors, setError, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
    });

  let { userId } = useParams();

  useEffect(() => {
    register("number");
    register("exp_year");
    register("exp_month");
    register("cvc");
    window.Conekta.setPublicKey("key_Sud9prnqdFEs7x7YtYKMb7Q");
  }, [register]);

  const handleConektaSubmit = async (data) => {
    setLoading(true);
    const tokenParams = {
      card: {
        number: data.number,
        name: data.name,
        exp_year: data.exp_year,
        exp_month: data.exp_month,
        cvc: data.cvc,
      },
    };
    window.Conekta.Token.create(tokenParams, successCallback, errorCallback);
  };

  const successCallback = (data) => {
    const token = localStorage.getItem("token");
    const customerId = `cus_${userId}`;
    return axios
      .patch(
        `${API_PATH}/methods/card`,
        { ...data, customerId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        alert("Tarjeta añadida con exito");
        setSuccess(true);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        alert(
          "Hubo un problema añadiendo su tarjeta, porfavor verifique los datos y vuelva a intentarlo"
        );

        throw error;
      });
  };

  const errorCallback = (data) => {
    alert(data.message_to_purchaser);
  };

  if (success && redirect && redirect !== "")
    return <Redirect to={redirect}></Redirect>;

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form className="my-8" onSubmit={handleSubmit(handleConektaSubmit)}>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-md font-bold mb-3"
          htmlFor="title"
        >
          Nombre del titular de la cuenta
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="text"
          name="name"
          ref={register}
          placeholder="Titular de la cuenta"
        />
      </div>

      <div className="mb-4">
        <label
          className="block text-gray-700 text-md font-bold mb-3"
          htmlFor="number"
        >
          Número de la tarjeta
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="text"
          name="number"
          ref={register}
          placeholder="16 Dígitos de la tarjeta"
        />
      </div>

      <div className="flex mb-8">
        <div className="mr-4 flex-1">
          <label
            className="block text-gray-700 text-md font-bold mb-3"
            htmlFor="exp_year"
          >
            Més de Vencimiento
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="exp_month"
            ref={register}
          >
            <option value="01">Enero</option>
            <option value="02">Febrero</option>
            <option value="03">Marzo</option>
            <option value="04">Abril</option>
            <option value="05">Mayo</option>
            <option value="06">Junio</option>
            <option value="07">Julio</option>
            <option value="08">Agosto</option>
            <option value="09">Septiembre</option>
            <option value="10">Octubre</option>
            <option value="11">Noviembre</option>
            <option value="12">Diciembre</option>
          </select>
        </div>

        <div className="mr-4 flex-1">
          <label
            className="block text-gray-700 text-md font-bold mb-3"
            htmlFor="exp_month"
          >
            Año de vencimiento
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="exp_year"
            ref={register}
            placeholder="2028"
          />
        </div>

        <div className="mr-4 flex-1">
          <label
            className="block text-gray-700 text-md font-bold mb-3"
            htmlFor="cvc"
          >
            Código de Seguirdad
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="cvc"
            ref={register}
            placeholder="CVC"
          />
        </div>
      </div>

      {loading ? (
        <LoadingIndicator></LoadingIndicator>
      ) : (
        <button
          className="w-full bg-brand-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Actualizar el método de pago
        </button>
      )}
    </form>
  );
};

export default NewPaymentMethodForm;

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#35AF2E",
      fontWeight: 400,
      fontSize: "1.25rem",
      color: "rgba(74, 85, 104, 1)",
      fontFamily:
        "Raleway, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#cbd5e0",
      },
    },
    invalid: {
      iconColor: "#c53030",
      color: "#c53030",
    },
  },
};
