import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ADMIN_HOME_PATH } from "../../constants/adminPaths";
import { DISTRIBUTOR_HOME_PATH } from "../../constants/distributorPaths";
import { APP_HOME_PATH, HOME_PATH } from "../../constants/paths";
import { NotificationContextProvider } from "../../context/NotificationContext";

import "./Navbar.scss";
import SignedInLinks from "./SignedInLinks";
import SignedInLinksNoAdmin from "./SignedInLinksNoAdmin";
import SignedOutLinks from "./SignedOutLinks";

const Navbar = ({ admin, distributor }) => {
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("token") ? true : false
  );

  useEffect(() => {
    // Set an event listener for local storage.
    window.addEventListener("storage", reactToTokenChange);

    // Remove event listener.
    return () => {
      window.removeEventListener("storage", reactToTokenChange);
    };
  }, []);
  // Function to react to token change in local storage.
  const reactToTokenChange = () => {
    setIsAuth(window.localStorage.getItem("token") ? true : false);
  };
  return (
    <header
      className="flex items-center justify-between py-2 border border-gray-300 shadow-sm md:px-16 px-4 fixed-navbar"
      style={{ backgroundColor: "white" }}
    >
      <Link
        to={
          isAuth
            ? admin
              ? ADMIN_HOME_PATH
              : distributor
              ? DISTRIBUTOR_HOME_PATH
              : APP_HOME_PATH
            : HOME_PATH
        }
        className="h-12 flex items-center home-image-z-index"
      >
        <img
          src={require("../../images/wmobil.svg")}
          className="max-h-full"
        ></img>{" "}
        <span className="font-regular ml-2 md:block hidden">Wmobil</span>
      </Link>
      <div>
        {isAuth ? (
          admin ? (
            <NotificationContextProvider>
              <SignedInLinks admin={admin} distributor={distributor} />
            </NotificationContextProvider>
          ) : (
            <SignedInLinksNoAdmin admin={admin} distributor={distributor} />
          )
        ) : (
          <SignedOutLinks />
        )}
      </div>
    </header>
  );
};

export default Navbar;
