import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SortFoliosTable from "../../SortFoliosTable";
import { Checkbox } from "@mui/material";
import WInput from "../../WInput";
import gearIcon from "../../../images/gearIcon.svg";
import WFacturasActionModal from "../../modals/WFacturasActionModal";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const PendingFoliosTable = ({
  cheques,
  selectAll,
  setSelectAll,
  onSelectionChange,
  onNumchequeSelectionChange,
  onDeselectionChange,
  selectedFolios,
  handleSaveHours,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [currPage, setCurrPage] = useState(0);
  const [filterMethod, setFilterMethod] = useState("all");
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const handleCheckboxChange = (folio) => {
    onSelectionChange((prevSelected) => {
      if (prevSelected.includes(folio)) {
        return prevSelected.filter((item) => item !== folio);
      } else {
        return [...prevSelected, folio];
      }
    });

    if (selectAll) {
      onDeselectionChange((prevSelected) => {
        if (prevSelected.includes(folio)) {
          return prevSelected.filter((item) => item !== folio);
        } else {
          return [...prevSelected, folio];
        }
      });
    }
  };

  const handleCheckboxChangeNumcheque = (folio) => {
    onNumchequeSelectionChange((prevSelected) => {
      if (prevSelected.includes(folio)) {
        return prevSelected.filter((item) => item !== folio);
      } else {
        return [...prevSelected, folio];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectAll((prevSelectAll) => !prevSelectAll);

    onSelectionChange(() => {
      if (!selectAll) {
        const filteredCheques = cheques
          .filter(
            (cheque) =>
              filterMethod === "all" ||
              cheque.forma_de_pago === filterMethod ||
              filterMethod === "no_facturado"
          )
          .filter(
            (cheque) =>
              parseInt(cheque.facturado) === 0 || cheque.facturado === "False"
          );

        return filteredCheques.map((folio) => folio.folio);
      } else {
        return [];
      }
    });

    onNumchequeSelectionChange(() => {
      if (!selectAll) {
        const filteredCheques = cheques
          .filter(
            (cheque) =>
              filterMethod === "all" ||
              cheque.forma_de_pago === filterMethod ||
              filterMethod === "no_facturado"
          )
          .filter(
            (cheque) =>
              parseInt(cheque.facturado) === 0 || cheque.facturado === "False"
          );

        return filteredCheques.map((folio) => folio.wf_numcheque);
      } else {
        return [];
      }
    });

    onDeselectionChange(() => {
      return [];
    });
  };

  const handleOpenModal = (type, folio, initialDate, expDate) => {
    setModalData({
      type: type,
      folio: folio,
      fecha: initialDate,
      vencimiento: expDate,
    });

    setOpenModal(true);
  };

  const columns = [
    {
      id: "facturar",
      Header: (
        <div className="flex items-center justify-center">
          <span onClick={handleSelectAll}>
            <Checkbox checked={selectAll} />
          </span>
          <div className="arrow-down" onClick={handleSelectAll}>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      ),
      accessor: (values) => {
        const invoiced =
          parseInt(values.facturado) === 1 || values.facturado === "True";
        return (
          <Checkbox
            disabled={invoiced}
            onChange={() => {
              handleCheckboxChange(values.folio);
              handleCheckboxChangeNumcheque(values.wf_numcheque);
            }}
            checked={selectedFolios.includes(values.folio)}
          />
        );
      },
    },
    {
      Header: "Folio",
      accessor: (values) => {
        const folio = parseInt(values.wf_numcheque, 10);
        if (!folio || isNaN(folio)) {
          return (
            <span className="text-gray-700">{t("restPage.accTableNoFol")}</span>
          );
        } else {
          return <span className="text-gray-700">{folio}</span>;
        }
      },
    },
    {
      Header: "Fecha",
      accessor: (values) => {
        const formattedFecha = values.fecha.replace("T", " ");
        return formattedFecha;
      },
    },
    {
      Header: "Vencimiento",
      accessor: (values) => {
        if (!values.vencimiento || values.vencimiento.trim() === "") {
          const fechaDate = new Date(values.fecha);
          fechaDate.setDate(fechaDate.getDate() + 3);
          return fechaDate.toISOString().replace("T", " ").split(".")[0];
        } else {
          return values.vencimiento.replace("T", " ");
        }
      },
    },
    {
      Header: "facturado",
      accessor: (values) => {
        const invoiced =
          parseInt(values.facturado) === 1 || values.facturado === "True";
        return (
          <div
            className={`font-semibold ${
              invoiced ? "text-green-700" : "text-yellow-600"
            } `}
          >
            {invoiced ? "Facturado" : "No facturado"}
          </div>
        );
      },
    },
    {
      Header: "forma de pago",
      accessor: "forma_de_pago",
    },
    { Header: t("restPage.accTableHeader3"), accessor: "wf_totalarticulos" },
    {
      Header: "monto",
      accessor: (values) => {
        return formatter.format(
          typeof values === "number"
            ? values.wf_totalsinpropina
            : parseFloat(values.wf_totalsinpropina)
        );
      },
    },
    {
      Header: "propina",
      accessor: (values) => {
        var format = parseFloat(values.wf_propina).toFixed(2);
        if (format != 0) {
          format = format + "$";
          return format;
        } else {
          return "Sin propina";
        }
      },
    },
    {
      Header: "Total",
      accessor: (values) => {
        return formatter.format(
          typeof values === "number"
            ? values.wf_totalconpropina
            : parseFloat(values.wf_totalconpropina)
        );
      },
    },
    {
      id: "setting",
      accessor: (values) => {
        const invoiced =
          parseInt(values.facturado) === 1 || values.facturado === "True";

        const handleAccessorClick = () => {
          if (invoiced) return;
          handleOpenModal(
            "setting",
            values.folio,
            values.fecha,
            values.vencimiento
          );
        };
        return (
          <img
            onClick={handleAccessorClick}
            className="cursor-pointer"
            src={gearIcon}
          />
        );
      },
    },
  ];

  const handleSelectedFilterType = (type) => {
    setFilterMethod(type);
  };

  const filteredCheques = cheques.filter((cheque) => {
    const includesSearch = [
      "wf_numcheque",
      "fecha",
      "wf_totalconpropina",
      "wf_propina",
    ].some((key) => {
      const value = String(cheque[key]).toLowerCase();
      return value.includes(searchValue.toLowerCase());
    });

    const facturadoValue =
      parseInt(cheque.facturado) === 1 || cheque.facturado === "True";

    const matchesPaymentMethod =
      filterMethod === "all" ||
      cheque.forma_de_pago.toLowerCase() === filterMethod ||
      (filterMethod === "facturado" && facturadoValue) ||
      (filterMethod === "no_facturado" && !facturadoValue);
    return includesSearch && matchesPaymentMethod;
  });

  return (
    <div>
      <WFacturasActionModal
        modalIsOpen={openModal}
        closeModal={() => setOpenModal(false)}
        modalData={modalData}
        saveHours={(data) => handleSaveHours(data)}
      />
      <div className="flex my-4 justify-between items-center">
        <div>
          <label className="wfacturas-table-text">
            {selectedFolios.length}{" "}
            {selectedFolios.length > 1 || selectedFolios.length == 0
              ? "folios seleccionados"
              : "folio seleccionado"}
          </label>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex flex-col mr-8 justify-center">
            <div className="inline-block relative">
              <select
                className="outline-none"
                value={filterMethod}
                onChange={(e) => handleSelectedFilterType(e.target.value)}
              >
                <option value="all">Todos</option>
                <option value="facturado">Facturados</option>
                <option value="no_facturado">No facturados</option>
                <option value="efectivo">Efectivo</option>
                <option value="tarjeta">Tarjeta</option>
                <option value="vales">Vales</option>
                <option value="otros">Otros</option>
              </select>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <WInput
              type="search"
              label="Búsqueda de folio"
              value={searchValue}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <SortFoliosTable
        actualPage={currPage}
        setCurrPage={setCurrPage}
        columns={columns}
        data={filteredCheques}
      />
    </div>
  );
};

export default PendingFoliosTable;
