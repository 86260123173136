import React from "react";
import plan150 from "../images/150_plan.svg";
import plan300 from "../images/300_plan.svg";
import plan1000 from "../images/1000_plan.svg";
import planUnlimited from "../images/unl_plan.svg";
import dollarSign from "../images/dollar_sign.svg";
import greenArrow from "../images/green-arrow.svg";
import cancelledCross from "../images/red-cancelled.svg";

const planLogo = {
  150: plan150,
  300: plan300,
  1000: plan1000,
  // UNL: planUnlimited,
};

const PlanCard = ({
  plan,
  handlePlanPreview,
  activePlan,
  monthly,
  cancel,
  planInterval,
}) => {
  const isMonthInterval = planInterval === "month" && monthly;
  const isAnnualInterval = planInterval === "year" && !monthly;
  const displayActive =
    plan === activePlan && (isMonthInterval || isAnnualInterval);

  const planPrices = {
    150: monthly ? 499 : 5099,
    300: monthly ? 759 : 7749,
    1000: monthly ? 999 : 9590,
    // UNL: monthly ? 999 : 9590,
  };

  const planContent = {
    150: `${monthly ? 150 : 1800} timbres incluidos`,
    300: `${monthly ? 300 : 3600} timbres incluidos`,
    1000: `${monthly ? 1000 : 12000} timbres incluidos`,
    // UNL: `Timbres ilimitados mientras tenga vigencia el plan`,
  };

  const planDiscount = {
    150: `Ahorra un 15% con facturación anual`,
    300: `Ahorra un 15% con facturación anual`,
    1000: `Ahorra un 15% con facturación anual`,
    // UNL: `Ahorra un 20% con facturación anual`,
  };

  const price = planPrices[plan];
  const content = planContent[plan];
  const logo = planLogo[plan];
  const discount = planDiscount[plan];

  return (
    <>
      <div
        className={`flex flex-col h-full ${displayActive ? "wborder-green-logo border-4" : ""} items-center folios-table-bg rounded-3xl`}
      >
        <div className="w-full mt-4 flex items-center justify-center">
          <img src={logo} />
        </div>
        <div className="flex items-center justify-center">
          <img className="pt-8 mr-4 w-8" src={dollarSign} />
          <label className="plans-banner-text">{price}</label>
        </div>
        <div className="plans-banner-subtitle">
          MXN / {monthly ? "mes" : "año"}
        </div>
        <div
          className={`${
            displayActive
              ? "wbg-green-logo text-white "
              : "wbg-brown-light wfacturas-table-text"
          }  w-full py-2 items-center text-center`}
        >
          <label className="font-bold">
            {displayActive ? "Plan actual" : discount}
          </label>
        </div>
        <div className="mt-4 flex w-full p-2 pl-10 items-center justify-start">
          <img src={greenArrow} />
          <label className="ml-4 font-medium wfacturas-table-text text-xl">
            {content}
          </label>
        </div>
        {(plan === 300 || plan === 1000 || plan === "UNL") && (
          <div className="flex w-full p-2 pl-10 items-center justify-start">
            <img src={greenArrow} />
            <label className="ml-4 font-medium wfacturas-table-text text-xl">
              Logo personalizado
            </label>
          </div>
        )}
        <div className="flex w-full p-2 pl-10 items-center justify-start">
          <img src={greenArrow} />
          <label className="ml-4 font-medium wfacturas-table-text text-xl">
            Soporte de software
          </label>
        </div>
        <div
          className={`flex w-full items-end h-full px-8 ${displayActive ? "pt-8" : "py-8 "}`}
        >
          <button
            onClick={() => handlePlanPreview(plan)}
            className={`wfacturas-button${displayActive ? "-off" : ""} justify-center w-full h-12`}
          >
            {displayActive ? "Volver a comprar" : "Actualizar plan"}
          </button>
        </div>
        {displayActive && (
          <div className="flex mt-2 w-full pl-8">
            <span
              onClick={cancel}
              className="text-red-600 py-1 text-sm cursor-pointer"
            >
              Cancelar suscripción
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default PlanCard;
