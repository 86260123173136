import React from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorComponent from "../../ErrorComponent";
import { getReporteProductosEnProduccion } from "../../../api/Restaurant";

const schema = yup.object().shape({
  startDate: yup
    .date()
    .max(new Date(), "La fecha de inicio debe ser antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
});
const ProductsInProductionForm = ({ restaurantId }) => {
  // Instantiate form
  const { register, handleSubmit, errors, setError, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
    });

  // Function to generate report
  const generateReport = ({ startDate }) => {
    getReporteProductosEnProduccion(
      restaurantId,
      startDate.toISOString().slice(0, 19).replace("T", " ")
    );
  };

  return (
    <form className="my-4" onSubmit={handleSubmit(generateReport)}>
      <div class="mb-4">
        <label class="block text-gray-700 text-md font-bold mb-3" for="title">
          Fecha de inicio
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="startDate"
          ref={register}
          max={new Date().toISOString().split("T")[0]}
          defaultValue={new Date().toISOString().split("T")[0]}
        />
        <ErrorComponent error={errors.startDate}></ErrorComponent>
      </div>
      <button
        class="w-full bg-brand-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
        type="submit"
      >
        Generar reporte
      </button>
    </form>
  );
};

export default ProductsInProductionForm;
