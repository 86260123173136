import React, { useEffect, useState } from "react";
import { cancelSubscription, getSubscription } from "../../api/User";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";
import SubscriptionPasswordForm from "../../components/subscriptions/SubscriptionPasswordForm";
import SubscriptionPasswordFormSwitch from "../../components/subscriptions/SubscriptionPasswordFormSwitch";
import "./MySubscriptionPage.css";
import { useHistory } from "react-router-dom";

// Icons
import MdCalendar from "react-ionicons/lib/MdCalendar";
import MdCopy from "react-ionicons/lib/MdCopy";
import MdLink from "react-ionicons/lib/MdLink";

// Component for copying to clipboard
import ClipboardCopy from "../../components/ClipboardCopy";

// Library for formatting dates
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/es"; // load on demand
import {
  DOMAIN_PATH,
  NEW_SUBSCRIPTION_PATH,
  NEW_PAYMENT_METHOD_PATH,
  MY_PAYMENT_METHODS_PATH,
  MY_SUBSCRIPTIONS_PATH,
  RENEW_SUBSCRIPTION_PATH,
} from "../../constants/paths";

//PLANS-ID's
import {
  MENSUAL,
  TRIMESTRAL,
  SEMESTRAL,
  ANUAL,
  WMOBIL_DEL_INFINITO,
  MENSUAL_OXXO_SPEI,
  TRIMESTRAL_OXXO_SPEI,
  SEMESTRAL_OXXO_SPEI,
  ANUAL_OXXO_SPEI,
} from "../../constants/plans";
import { Link } from "react-router-dom";
import { date } from "yup";
import { useTranslation } from "react-i18next";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";
dayjs.extend(relativeTime);
dayjs.locale("es"); // use Spanish locale globally

// Formatter for currencies
const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const MySubscriptionPage = ({ match }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // Variable for getting subscription
  const [subscription, setSubscription] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Variable for getting subscription update link
  const [portalUrl, setPortalUrl] = useState(null);

  // Variables and functions for password modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSwitchPasswordIsOpen, setModalSwitchPasswordIsOpen] =
    useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const openModalSwitchPassword = () => {
    setModalSwitchPasswordIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const closeModalSwitchPassword = () => {
    setModalSwitchPasswordIsOpen(false);
  };

  // Get licenses
  useEffect(() => {
    setLoading(true);
    getSubscription(match.params.id)
      .then((sub) => {
        if (sub === "Acceso restringido") {
          history.push(MY_SUBSCRIPTIONS_PATH);
        }
        sub.license_active = sub.activated;
        if (sub.current_period_start !== null) {
          if (sub.current_period_start.toString().length === 10) {
            sub.current_period_start = sub.current_period_start * 1000;
            sub.current_period_end = sub.current_period_end * 1000;
          }
        }
        sub.client = sub.conekta_id.substring(4);
        setSubscription(sub);
        setLoading(false);
      })
      .catch((error) => {
        history.push(MY_SUBSCRIPTIONS_PATH);
        setError(error);
        setLoading(false);
        console.log(error);
      });
  }, []);

  const submitCancelSubscription = () => {
    setLoading(true);
    cancelSubscription(`cus_${subscription.client}`)
      .then((res) => {
        alert("Subscripción cancelada con exito");
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        alert(
          "Hubo un problema cancelando la subscripción, porfavor intenta más tarde"
        );
        console.error(error);
        setLoading(false);
      });
  };

  const getPlanPrice = (plan_name) => {
    let plan_price = "(Error)";
    switch (plan_name) {
      case MENSUAL:
        plan_price = "($349.00)";
        break;
      case TRIMESTRAL:
        plan_price = "($979.00)";
        break;
      case SEMESTRAL:
        plan_price = "($1,919.00)";
        break;
      case ANUAL:
        plan_price = "($3,699.00)";
        break;
      case WMOBIL_DEL_INFINITO:
        plan_price = "(Cortesía)";
        break;
      case MENSUAL_OXXO_SPEI:
        plan_price = "($349.00)";
        break;
      case TRIMESTRAL_OXXO_SPEI:
        plan_price = "($979.00)";
        break;
      case SEMESTRAL_OXXO_SPEI:
        plan_price = "($1,919.00)";
        break;
      case ANUAL_OXXO_SPEI:
        plan_price = "($3,699.00)";
        break;
      default:
        plan_price = "($349.00)";
        break;
    }
    return plan_price;
  };

  useEffect(() => {
    if (portalUrl) window.open(portalUrl, "_self");
  }, [portalUrl]);

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  let dateRenovation = new Date(subscription.current_period_end);
  dateRenovation.setDate(dateRenovation.getDate() - 3);
  let tryRenovation = new Date(Date.now());

  return (
    <Layout title={subscription.restaurant_title || "Mi suscripción"} fullWidth>
      <SubscriptionPasswordForm
        openModal={openModal}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        id={subscription.restaurant_id}
        title={subscription.restaurant_title}
      ></SubscriptionPasswordForm>
      <SubscriptionPasswordFormSwitch
        openModal={openModalSwitchPassword}
        modalIsOpen={modalSwitchPasswordIsOpen}
        closeModal={closeModalSwitchPassword}
        id={subscription.restaurant_id}
        title={subscription.restaurant_title}
      ></SubscriptionPasswordFormSwitch>
      <div className="flex myaccount-main-container">
        <ProfileNavbar></ProfileNavbar>
        <div className="p-8 w-full padding-resp">
          <div className="mb-6">
            <h2 className="text-4xl text-gray-800 font-bold mb-0 leading-tight">
              {subscription.restaurant_title || "Mi suscripción"}
            </h2>
            <div className="flex items-center mt-0">
              {subscription.license_active ? (
                <div className="w-2 h-2 bg-green-300 rounded-full m-0 p-0 mr-2"></div>
              ) : (
                <div className="w-2 h-2 bg-red-300 rounded-full m-0 p-0 mr-2"></div>
              )}
              <p className="text-sm text-gray-700 font-light flex items-baseline">
                {subscription.plan}
                <span className="ml-2 text-base text-gray-900 font-medium">
                  {getPlanPrice(subscription.plan)}
                </span>
              </p>
            </div>
            {subscription.password_protected ? (
              <ClipboardCopy
                value={`Registra el restaurante ${subscription.restaurant_title} a través de este enlace: ${DOMAIN_PATH}${NEW_SUBSCRIPTION_PATH}?id=${subscription.restaurant_id}`}
              >
                <p
                  className="flex items-center text-gray-800 text-sm mt-2"
                  style={{ width: "fit-content" }}
                >
                  <MdLink className="mr-1 fill-current"></MdLink>
                  {t("subsPage.link")}
                </p>
              </ClipboardCopy>
            ) : (
              <p
                onClick={openModal}
                className="flex items-center text-gray-800 text-sm mt-2 hover-pointer"
                style={{ width: "fit-content" }}
              >
                <MdLink className="mr-1 fill-current"></MdLink>
                {t("subsPage.link")}
              </p>
            )}
            {subscription.license_key ? (
              <ClipboardCopy value={`${subscription.license_key}`}>
                <p
                  className="flex items-center text-gray-800 text-sm mt-2"
                  style={{ width: "fit-content" }}
                >
                  <MdCopy className="mr-1 fill-current"></MdCopy>
                  {t("subsPage.lic")}
                </p>
              </ClipboardCopy>
            ) : (
              <></>
            )}
            <p
              onClick={openModalSwitchPassword}
              className="flex items-center text-gray-800 text-sm mt-2 hover-pointer"
              style={{ width: "fit-content" }}
            >
              {t("subsPage.changePass")}
            </p>
            {(!subscription.activated ||
              (dateRenovation < tryRenovation &&
                subscription.plan.indexOf("OXXO") !== -1)) && (
              <div className="mysub-btn-pay-container">
                <span>{t("subsPage.exp")}</span>
                <button
                  onClick={() =>
                    history.push({
                      pathname: RENEW_SUBSCRIPTION_PATH,
                      state: {
                        data: {
                          conektaid: subscription.conekta_id,
                          subscription: subscription,
                        },
                      },
                    })
                  }
                >
                  {t("subsPage.renew")}
                </button>
              </div>
            )}
          </div>
          <hr className="mt-2 mb-4"></hr>
          <div>
            <div className="flex">
              <div className="border-r border-gray-300 pr-4">
                <strong className="text-xs uppercase text-gray-800 mb-2 block">
                  {t("subsPage.lastPay")}
                </strong>
                <p className="flex items-center mb-2 text-gray-700 text-sm font-light">
                  <MdCalendar className="fill-current mr-1"></MdCalendar>
                  {dayjs(subscription.current_period_start).format(
                    "DD/MM/YYYY"
                  )}{" "}
                  ({dayjs(subscription.current_period_start).fromNow()})
                </p>
              </div>
              <div className="pl-4">
                <strong className="text-xs uppercase text-gray-800 mb-2 block">
                  {t("subsPage.nextPay")}
                </strong>
                <p className="flex items-center mb-2 text-gray-700 text-sm font-light">
                  {subscription.current_period_end ? (
                    <>
                      <MdCalendar className="fill-current mr-1"></MdCalendar>
                      {dayjs(subscription.current_period_end).format(
                        "DD/MM/YYYY"
                      )}{" "}
                      ({dayjs(subscription.current_period_end).fromNow()})
                    </>
                  ) : (
                    <p>{t("subsPage.cancelledSub")}</p>
                  )}
                </p>
              </div>
            </div>
            <div className="mt-2">
              <strong className="text-xs uppercase text-gray-800 block mb-2">
                {t("subsPage.payMethod")}
              </strong>
              <PaymentMethod {...subscription.default_payment_method} />
            </div>
          </div>
          {subscription.activated &&
          subscription.plan.indexOf("OXXO") === -1 &&
          subscription.plan.indexOf("PAYPAL") === -1 ? (
            <>
              {" "}
              <div>
                <Link
                  to={`${NEW_PAYMENT_METHOD_PATH}/${subscription.client}?redirect=${MY_SUBSCRIPTIONS_PATH}`}
                  className="text-sm text-blue-700"
                >
                  {t("subsPage.changeMethod")}
                </Link>
              </div>
              <div>
                <button
                  className="text-sm text-red-600 absolute font-regular"
                  onClick={submitCancelSubscription}
                >
                  {t("subsPage.cancelSub")}
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Layout>
  );
};

const PaymentMethod = ({ brand, last4, name }) => {
  return (
    <div
      className={`flex mb-4 items-center border-t border-b py-2 w-full border-gray-200`}
    >
      {brand === "mastercard" && <FaCcMastercard size={30} className="mr-4" />}
      {brand === "visa" && <FaCcVisa size={30} className="mr-4" />}
      <div>
        <p className="text-xs font-light text-gray-700">{name}</p>
        <p className="text-sm text-gray-700">•••• {last4}</p>
      </div>
    </div>
  );
};

export default MySubscriptionPage;
