import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPaymentMethods } from "../../api/User";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";
import PaymentMethodOption from "../../components/subscriptions/PaymentMethodOption";
import {
  MY_PAYMENT_METHODS_PATH,
  NEW_PAYMENT_METHOD_PATH,
} from "../../constants/paths";

const MyPaymentMethodsPage = () => {
  // Variable for getting payment methods
  const [methods, setMethods] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Get payment methods
  useEffect(() => {
    setLoading(true);
    getPaymentMethods()
      .then((methods) => {
        setMethods(methods);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <Layout title="Mis métodos de pago" fullWidth>
      <div className="flex">
        <ProfileNavbar></ProfileNavbar>
        <div className="p-8 w-full">
          <h2 className="text-2xl text-gray-800 font-bold">
            Mis métodos de pago
          </h2>
          <div className="mt-4">
            {loading ? <LoadingIndicator></LoadingIndicator> : <></>}
            {methods.length > 0 &&
              methods.map((indMethod) => (
                <PaymentMethodOption
                  {...indMethod}
                  noClick
                ></PaymentMethodOption>
              ))}
          </div>
          <Link
            to={`${NEW_PAYMENT_METHOD_PATH}?redirect=${MY_PAYMENT_METHODS_PATH}`}
            className="text-sm text-blue-700"
          >
            Nuevo método
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default MyPaymentMethodsPage;
