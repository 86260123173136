import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getLicenses } from "../../api/User";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import { fetchUserProfile } from "../../api/Auth";
import { isUserVerified } from "./../../api/User";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";
import SubscriptionItem from "../../components/subscriptions/SubscriptionItem";
import {
  MY_SUBSCRIPTIONS_PATH,
  NEW_SUBSCRIPTION_PATH,
} from "../../constants/paths";
import { useTranslation } from "react-i18next";

const MySubscriptionsPage = () => {
  const { t } = useTranslation();
  // Variable for getting licenses
  const [licenses, setLicenses] = useState([]);
  const [verified, setVerified] = useState(false);
  const [userEmail, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Get licenses
  useEffect(() => {
    setLoading(true);
    getLicenses()
      .then((licenses) => {
        if (licenses.licenses !== undefined) {
          setLicenses(licenses.licenses);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      fetchUserProfile()
        .then((usr_data) => {
          setEmail(usr_data.email);

          isUserVerified(usr_data.email).then((res) => {
            if (res.emailVerified) {
              setVerified(true);
            } else {
              setVerified(false);
            }
          });
          //emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID)
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {}
  }, []);

  return (
    <Layout title="Mis suscripciones" fullWidth>
      <div className="flex myaccount-main-container main-admin-page-padding">
        <ProfileNavbar></ProfileNavbar>
        <div className="p-8 w-full">
          <h2 className="text-2xl text-gray-800 font-bold">
            {t("subsPage.title")}
          </h2>
          {verified ? (
            <Link
              to={`${NEW_SUBSCRIPTION_PATH}?redirect=${MY_SUBSCRIPTIONS_PATH}`}
              className="text-sm text-blue-700"
            >
              {t("subsPage.subtitle")}
            </Link>
          ) : (
            <>{t("subsPage.noEmail")}</>
          )}
          {verified && (
            <div className="mt-4">
              {loading ? <LoadingIndicator></LoadingIndicator> : <></>}
              {licenses.length > 0 &&
                licenses.map((license) => (
                  <SubscriptionItem {...license}></SubscriptionItem>
                ))}
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MySubscriptionsPage;
