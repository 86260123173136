import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./ColaboradorInstall.css";
import { useHistory } from "react-router-dom";
import { INSTALL_PATH } from "../../constants/paths";
import { useTranslation } from "react-i18next";

const ColaboradorInstall = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Layout title="Introducción">
      <div className="wmobil-install-background"></div>
      <div class="collaborator-install-main-container">
        <div class="collaborator-install-card">
          <div className="collaborator-install-back-text-container">
            <a
              onClick={() => history.push(INSTALL_PATH)}
              style={{ cursor: "pointer" }}
            >
              ← {t("installPage.backBtn")}
            </a>
          </div>
          <div class="collaborator-install-card-title-container">
            <h2>{t("installPage.personalInstCol")}</h2>
          </div>
          <div class="collaborator-install-text-container">
            <p>{t("installPage.personalSubCol")}</p>
          </div>
          <div class="collaborator-install-card-input-container">
            <span>{t("installPage.nameField")}</span>
            <div class="collaborator-install-card-input">
              <input
                placeholder={t("restPage.accTableTopHeader1")}
                type="text"
              />
            </div>
            <span>{t("installPage.emailField")}</span>
            <div class="collaborator-install-card-input">
              <input placeholder="correo@dominio.com" type="text" />
            </div>
            <span>{t("installPage.phoneField")}</span>
            <div class="collaborator-install-card-input">
              <input placeholder="00 0000 0000" type="text" />
            </div>
          </div>
          <div class="collaborator-install-card-empecemos-btn-container">
            <button>{t("installPage.beginCol")}</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ColaboradorInstall;
