import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "../../Select";
import GoalsForm from "../GoalsForm";
import ProgressBar from "../ProgressBar";

import MdCreate from "react-ionicons/lib/MdCreate";
import { useTranslation } from "react-i18next";

const Goals = ({ goals, values, restaurantId }) => {
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <GoalsForm
        id={restaurantId}
        goals={goals}
        modalIsOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
      ></GoalsForm>
      <div className="grid grid-cols-4 gap-4">
        {goals.daily ? (
          <ProgressBar
            id="daily"
            max={goals.daily}
            current={values.daily}
            title={t("restPage.goalTitle1")}
            color="#20639B"
          ></ProgressBar>
        ) : (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200">
            <div className="text-lg text-gray-800 font-bold">
              {t("restPage.goalTitle1")}
            </div>
            <div className="text-sm text-gray-700 font-light">
              {t("restPage.goalSubtitle1")}{" "}
              <button
                className="text-blue-500"
                onClick={() => setModalIsOpen(true)}
              >
                {t("restPage.goalHere")}
              </button>
            </div>
          </div>
        )}
        {goals.weekly ? (
          <ProgressBar
            id="weekly"
            max={goals.weekly}
            current={values.weekly}
            title={t("restPage.goalTitle2")}
            color="#3caea3"
          ></ProgressBar>
        ) : (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200">
            <div className="text-lg text-gray-800 font-bold">
              {t("restPage.goalTitle2")}
            </div>
            <div className="text-sm text-gray-700 font-light">
              {t("restPage.goalSubtitle2")}{" "}
              <button
                className="text-blue-500"
                onClick={() => setModalIsOpen(true)}
              >
                {t("restPage.goalHere")}
              </button>
            </div>
          </div>
        )}
        {goals.monthly ? (
          <ProgressBar
            id="monthly"
            max={goals.monthly}
            current={values.monthly}
            title={t("restPage.goalTitle3")}
            color="#f6d55c"
          ></ProgressBar>
        ) : (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200">
            <div className="text-lg text-gray-800 font-bold">
              {t("restPage.goalTitle3")}
            </div>
            <div className="text-sm text-gray-700 font-light">
              {t("restPage.goalSubtitle3")}{" "}
              <button
                className="text-blue-500"
                onClick={() => setModalIsOpen(true)}
              >
                {t("restPage.goalHere")}
              </button>
            </div>
          </div>
        )}
        {goals.yearly ? (
          <ProgressBar
            id="yearly"
            max={goals.yearly}
            current={values.yearly}
            title={t("restPage.goalTitle4")}
            color="#ed553b"
          ></ProgressBar>
        ) : (
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200">
            <div className="text-lg text-gray-800 font-bold">
              {t("restPage.goalTitle4")}
            </div>
            <div className="text-sm text-gray-700 font-light">
              {t("restPage.goalSubtitle4")}{" "}
              <button
                className="text-blue-500"
                onClick={() => setModalIsOpen(true)}
              >
                {t("restPage.goalHere")}
              </button>
            </div>
          </div>
        )}
      </div>
      <button
        className="w-full text-xs text-gray-500 mt-4 inline-flex items-center"
        onClick={() => setModalIsOpen(true)}
      >
        <MdCreate className="mr-1 fill-current w-4"></MdCreate>
        {t("restPage.goalEdit")}
      </button>
    </>
  );
};

export default Goals;
