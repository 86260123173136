import React, { useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";

import "./Table.scss";

// import ReactHTMLTableToExcel from "react-html-table-to-excel";

import MdDownload from "react-ionicons/lib/MdDownload";
import { useTranslation } from "react-i18next";

const SortableTable = ({ columns, data, id }) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    const filtered = data.filter((row) =>
      Object.values(row).some((cellValue) =>
        String(cellValue).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data: filteredData.sort(compare),
      initialState: { pageIndex: 0, pageSize: data.length },
    },
    useSortBy,
    usePagination
  );

  function compare(a, b) {
    if (a.folio < b.folio) {
      return -1;
    }
    if (a.folio > b.folio) {
      return 1;
    }
    return 0;
  }

  return (
    <>
    {/*

      <div className="flex justify-between mt-4">
        <input
          type="text"
          className="px-2 py-1 border border-gray-400 rounded-md"
          placeholder={t("restPage.accTableSearch")}
          value={searchValue}
          onChange={handleInputChange}
        />
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table={id}
          filename="Wmobil"
          sheet="Hoja de cálculo"
          buttonText={
            <span className="bg-gray-200 flex items-center justify-center py-1 px-2 text-sm text-gray-800 border rounded-sm">
              <span className="mr-1">{t("restPage.accTableDownload")}</span>
              <MdDownload className="w-4 text-gray-800 fill-current" />
            </span>
          }
        />
      </div>
    */}

      <div className="main-admin-page-users-table-wrapper">
        <table
          id={id}
          {...getTableProps()}
          className="table-auto w-full bg-white mx-auto shadow-md mt-1"
        >
          <thead className="w-full">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border px-4 py-3 text-gray-800 bg-gray-200 text-left uppercase text-sm44444"
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`border px-4 py-3 text-gray-700 ${
                          i % 2 !== 0 ? "bg-gray-100" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="bg-white py-3 flex items-center justify-between border-t border-gray-200">
        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="text-sm leading-5 text-gray-700">
              {t("restPage.accTablePage1")}
              <span className="font-medium mx-1 inline-block">
                {pageIndex + 1}
              </span>
              {t("restPage.accTablePage2")}
              <span className="font-medium mx-1 inline-block">
                {pageOptions.length}
              </span>
            </p>
          </div>
          <div className="buttons">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              aria-label="Previous"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
              aria-label="Next"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="inline-block relative">
            <select
              className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50, "todas"].map((pageSize) => {
                const size = pageSize === "todas" ? data.length : pageSize;
                return (
                  <option key={size} value={size}>
                    {t("restPage.accTableShow")} {size}
                  </option>
                );
              })}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SortableTable;
