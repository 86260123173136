import React from "react";
import MyAccountForm from "../../components/account/MyAccountForm";
import Layout from "../../components/Layout";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";
import "./MyAccountPage.css";
import { useTranslation } from "react-i18next";

const MyAccountPage = () => {
  const { t } = useTranslation();
  return (
    <Layout title="Mi cuenta" fullWidth>
      <div className="flex myaccount-main-container">
        <ProfileNavbar></ProfileNavbar>
        <div className="p-8 w-full">
          <h2 className="text-2xl text-gray-800 font-bold">
            {t("navBar.myAcc")}
          </h2>
          <MyAccountForm></MyAccountForm>
        </div>
      </div>
    </Layout>
  );
};

export default MyAccountPage;
