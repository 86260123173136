import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import downArrow from "../images/downArrow.svg";

const WSelect = forwardRef(
  (
    {
      mandatory,
      label,
      name,
      value,
      defaultValue,
      disabled,
      onChange,
      children,
      type,
      removeArrow,
    },
    ref
  ) => {
    const { t } = useTranslation();
    let className = "wfacturas-input appearance-none h-10";

    if (type && type === "landing") {
      className = "landing-input appearance-none";
    }

    return (
      <div className="relative w-full">
        <select
          disabled={disabled}
          className={className}
          name={name}
          {...(ref && { ref: ref })}
          value={value}
          defaultValue={defaultValue}
          {...(onChange && {
            onChange: (e) => onChange(e.target.value),
          })}
        >
          {children}
        </select>
        <label className="wfacturas-input-label">
          {type === "landing" ? "" : label}
          {mandatory && <span className="mandatory">*</span>}
        </label>
        {!removeArrow && (
          <div className="absolute top-0 right-0 mt-4 mr-4 pointer-events-none">
            <img src={downArrow} />
          </div>
        )}
      </div>
    );
  }
);

export default WSelect;
