import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchRegularUsers } from "../../api/Admin";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";

import SortableTable from "../../components/SortableTable";

import { ADMIN_USERS_PATH } from "../../constants/adminPaths";
import "./AdminHomePage.scss";
import { useTranslation } from "react-i18next";

const AdminHomePage = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      Header: t("restPage.accTableTopHeader1"),
      accessor: (values) => {
        return { id: values.id, name: `${values.name} ${values.lastName}` };
      },
      Cell: NameCell,
    },
    {
      Header: t("landingPage.email"),
      accessor: "email",
    },
  ];

  useEffect(() => {
    // Get restaurants once
    fetchRegularUsers()
      .then((usrs) => {
        setUsers(usrs);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <LoadingIndicator fullScreen />;

  if (error) {
    return (
      <Layout title="Dashboard">
        <div>{error.message}</div>
      </Layout>
    );
  }

  return (
    <Layout title="Dashboard" admin>
      <div className="py-8 wrapper">
        <div className="main-admin-page-padding">
          <h2 className="text-2xl text-gray-800 font-bold">
            {t("navBar.users")}
          </h2>
          <hr className="mt-2 mb-4" />
          <SortableTable
            id="admin-users-table"
            columns={columns}
            data={users}
          />
        </div>
      </div>
    </Layout>
  );
};

const NameCell = ({ value }) => {
  const { id, name } = value;
  return (
    <Link to={`${ADMIN_USERS_PATH}/${id}`} className="font-medium">
      {name}
    </Link>
  );
};

export default AdminHomePage;
