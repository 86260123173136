import React from "react";
import DistributorForm from "../../components/distributor/forms/DistributorForm";
import Layout from "../../components/Layout";
import LayoutV2 from "../../components/LayoutV2/LayoutV2";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";

const DistributorAccountPage = () => {
  return (
    <LayoutV2 title="Mi cuenta" fullWidth distributor>
      <div className="flex">
        <ProfileNavbar distributor></ProfileNavbar>
        <div className="p-8 w-full">
          <h2 className="text-2xl text-gray-800 font-bold">Mi cuenta</h2>
          <DistributorForm></DistributorForm>
        </div>
      </div>
    </LayoutV2>
  );
};

export default DistributorAccountPage;
