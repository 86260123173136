import React from "react";
import { Link } from "react-router-dom";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import { BsArrowLeft, BsBorder } from "react-icons/bs";
import "./PaymentDailyDetailsPage.scss";
import { DISTRIBUTOR_DASHBOARD_PATH } from "../../../constants/distributorPaths";

export const PaymentDailyDetailsPage = (props) => {
  return (
    <LayoutV2
      distributor={true}
      title="Desglocec de Diarios"
      className="mx:px-0 px-4"
    >
      <div className="Payment-main-component">
        <div className="Payment-main-container">
          <div className="Payment-form-card">
            <div className="Payment-content">
              <Link to={DISTRIBUTOR_DASHBOARD_PATH}>
                <p className="Payment-go-back">
                  <div className="Payment-icon">
                    <BsArrowLeft></BsArrowLeft>Atras
                  </div>
                </p>
              </Link>
              <h1 className="Payment-title">Desgloce del Diarios</h1>
              <p className="Payment-fecha">26 de Diciembre de 2021</p>
            </div>
            <div className="Payment-table">
              <table className="Payment-table-content">
                <tr className="Payment-table-header">
                  <td>Usuario</td>
                  <td>Total de Suscripciones</td>
                  <td>Ganancias Totales</td>
                </tr>
                <tr className="Payment-table-body">
                  <td>06/12/12</td>
                  <td>3 Suscripciones</td>
                  <td>$1,500.00 MXN</td>
                </tr>
                <tr className="Payment-table-body">
                  <td>07/12/12</td>
                  <td>2 Suscripciones</td>
                  <td>$900.00 MXN</td>
                </tr>
                <tr className="Payment-table-body">
                  <td>08/12/12</td>
                  <td>2 Suscripciones</td>
                  <td>$900.00 MXN</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
};
