export const DOMAIN_PATH = process.env.REACT_APP_SITE_URL;

export const HOME_PATH_LANDING = "/";
export const HOME_PATH = "/";
export const HOME_PATH_DISTRIBUTION = "/distribuidores";
export const SIGNIN_PATH = "/iniciar-sesion";
export const SIGNUP_PATH = "/registro";
export const POLITICAS_DE_PRIVACIDAD = "/politicas-de-privacidad";
export const TERMINOS_Y_CONDICIONES = "/terminos-y-condiciones";
export const FORGOT_PASSWORD_PATH = "/olvidar-contraseña";
export const VERIFICATION_PATH = "/app/verificacion";
export const INTRODUCTION_PATH = "/app/introduction";
export const INSTALL_PATH = "/app/instalacion";
export const PERSONAL_INSTALL_PATH = "/app/instalacion/personal";
export const COLLABORATOR_INSTALL_PATH = "/app/instalacion/colaborador";
export const WMOBIL_INSTALL_PATH = "/app/instalacion/wmobil";
export const PAYMENT_REFERENCE = "/app/referencia-pago";

export const APP_HOME_PATH = "/app";
export const APP_RESTAURANTS_PATH = "/app/restaurantes";

export const APP_MY_ACCOUNT_PATH = "/app/mi-cuenta";
export const MY_SUBSCRIPTIONS_PATH = "/app/mi-cuenta/suscripciones";
export const MY_PAYMENT_METHODS_PATH = "/app/mi-cuenta/metodos";

export const NEW_SUBSCRIPTION_PATH = "/app/mi-cuenta/suscripciones/nueva";
export const NEW_PAYMENT_METHOD_PATH = "/app/mi-cuenta/metodos/nuevo";
export const RENEW_SUBSCRIPTION_PATH = "/app/mi-cuenta/suscripciones/renovar";
export const RENEW_SUBSCRIPTION_PATH_NO_AUTH = "/app/suscripciones/renovar";

export const NEW_GROUP_PATH = "/app/mi-cuenta/grupos/nuevo";
export const GROUP_PATH = "/app/mi-cuenta/grupos";

export const PAYMENT_LINK_PATH = "/pagar-licencias/uid";
export const APP_MY_FACTURAS_PATH = "/app/wfacturas/mis-facturas";
export const APP_CREATE_FACTURA = "/app/wfacturas/crear-factura";
export const APP_CREATE_GENERAL_FACTURA =
  "/app/wfacturas/crear-factura-general";
export const APP_PARTIAL_PAY_LEGAL_DATA = "/app/wfacturas/datos-fiscales";
export const APP_CREATE_PARTIAL_PAY = "/app/wfacturas/datos-de-complemento";
export const APP_SEARCH_FOLIO_FACTURA = "/app/wfacturas/search-factura";
export const APP_WFACTURAS_CLIENTS = "/app/wfacturas/clientes";
export const APP_WFACTURAS_PLANS = "/app/wfacturas/planes";
export const APP_WFACTURAS_SETTINGS = "/app/wfacturas/ajustes";
export const HOME_PATH_FACTURACION = "/facturacion";
