import React, { useContext } from "react";
import { useTimbres } from "../context/TimbresContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_WFACTURAS_PLANS } from "../constants/paths";

const TimbresComponent = () => {
  const { timbresCount } = useTimbres();
  const history = useHistory();
  const isPending = localStorage.getItem("pendingFacturapiSetup");

  const timbresCountString =
    timbresCount < 10
      ? "000" + timbresCount
      : timbresCount > 9 && timbresCount < 100
        ? "00" + timbresCount
        : timbresCount > 99 && timbresCount < 1000
          ? "0" + timbresCount
          : timbresCount;

  const goToSubs = () => {
    history.push(APP_WFACTURAS_PLANS);
  };

  return (
    <div>
      <div className="wfacturas-timbres">
        {timbresCount !== 0 ? (
          <>
            <span className="pl-4 timbres-count">
              {timbresCount == 99999 ? (
                <img src={require("../images/infinite.svg")} />
              ) : (
                timbresCount
              )}
            </span>
            <span>Timbres disponibles</span>
          </>
        ) : (
          <span
            className="w-full px-2 text-center text-xl cursor-pointer"
            onClick={goToSubs}
          >
            Contratar un plan
          </span>
        )}
      </div>
      {isPending && (
        <div className="flex pl-4 text-red-300">
          <span>
            Complete sus datos fiscales para comenzar a usar sus timbres
          </span>
        </div>
      )}
    </div>
  );
};

export default TimbresComponent;
