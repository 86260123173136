import React, { useEffect, useState } from "react";
import { fetchDistributor } from "../../api/Admin";
import BackArrow from "../../components/BackArrow";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import {
  ADMIN_DISTRIBUTORS_PATH,
  ADMIN_USERS_PATH,
} from "../../constants/adminPaths";

import MdMail from "react-ionicons/lib/MdMail";

import dayjs from "dayjs";
import "dayjs/locale/es"; // load on demand
import { Link } from "react-router-dom";
import SortableTable from "../../components/SortableTable";

import { useForm } from "react-hook-form";
import { updateDataAdmin } from "../../api/Distributor";

const AdminDistributorPage = ({ match }) => {
  // Variables for handling distributor data from API.
  const [distributor, setDistributor] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get restaurants once
    fetchDistributor(match.params.id)
      .then((usr) => {
        setDistributor(usr);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  //Actualizar CLABE y %

  //metodos del form, regsiter se usa para agarrar los valores del form con base en name y los manda a values
  const { register, handleSubmit, errors, reset } = useForm({});

  const onSubmit = (values) => {
    values["id"] = distributor.id;
    values["isAdmin"] = "true";
    updateDataAdmin(values);
  };

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  return (
    <Layout title={`${distributor.name} ${distributor.lastName}`} admin>
      <div className="py-8 wrapper">
        <BackArrow to={ADMIN_DISTRIBUTORS_PATH} title="Atrás"></BackArrow>
        <div className="flex justify-between items-end text-gray-700">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex">
              <div className="flex-auto">
                <h2 className="text-2xl text-gray-800 font-bold">
                  {`${distributor.name} ${distributor.lastName}`}
                </h2>
                <p className="text-sm mb-4">
                  Se unió el{" "}
                  {dayjs(distributor.created_at)
                    .locale("es")
                    .format("dddd D [de] MMMM [del] YYYY")}
                </p>
                <a
                  href={`mailto:${distributor.email}`}
                  className="text-blue-700 text-sm"
                >
                  <MdMail className="fill-current mr-1 inline-block w-4"></MdMail>
                  {distributor.email}
                </a>
              </div>
              <div className="flex-auto pr-32 display-block"></div>
              <div className="flex-auto">
                <div className=" text-gray-800 text-sm">
                  <table>
                    <tr
                      style={{
                        borderBottomWidth: 12 + "px",
                        borderColor: "white",
                      }}
                    >
                      <th>Cambiar Porcentaje de Ingresos</th>
                      <th>
                        <input
                          style={{ width: 75 + "%", borderWidth: 3 + "px" }}
                          class="text-center"
                          type="number"
                          name="porcentajeIngresos"
                          ref={register}
                          min="0"
                          max="100"
                          placeholder="0"
                          defaultValue={distributor.porcentajeIngresos}
                        />{" "}
                        {" %"}
                      </th>
                    </tr>
                    <tr>
                      <th>Cambiar CLABE</th>
                      <th className="p-0">
                        <input
                          style={{
                            fontSize: 14,
                            padding: 0,
                            width: 95 + "%",
                            textAlign: "center",
                          }}
                          type="text"
                          name="clabe"
                          ref={register}
                          className="input"
                          minLength="18"
                          maxLength="18"
                          pattern="[0-9]+"
                          defaultValue={distributor.clabe}
                        />
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="flex-auto pr-24 display-block"></div>
              <div className="flex-auto">
                <button className="button-main px-2" type="submit">
                  Guardar datos
                </button>
              </div>
            </div>
          </form>
        </div>
        <hr className="mt-2 mb-4"></hr>
        <div>
          <h2 className="text-xl text-gray-800 font-bold">Referidos</h2>
          <SortableTable
            id={`distributor-${distributor.id}-restaurants`}
            data={distributor.referrals || []}
            columns={columns}
          ></SortableTable>
        </div>
      </div>
    </Layout>
  );
};

const NameCell = ({ value }) => {
  const { id, name } = value;
  return (
    <Link to={`${ADMIN_USERS_PATH}/${id}`} className="font-medium">
      {name}
    </Link>
  );
};

const columns = [
  {
    Header: "Nombre",
    accessor: (values) => {
      return { id: values.id, name: `${values.name} ${values.lastName}` };
    },
    Cell: NameCell,
  },
];

export default AdminDistributorPage;
