import React, { useEffect, useRef, useState } from "react";
import { getRestaurant, getVentasPorGrupo } from "../../api/Restaurant";
import BackArrow from "../../components/BackArrow";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import Tabs from "../../components/restaurant/Tabs";
import Online from "../../components/restaurant/views/Online";
import PaymentsAndMovements from "../../components/restaurant/views/PaymentsAndMovements";
import Tables from "../../components/restaurant/views/Tables";
import Top from "../../components/restaurant/views/Top";
import { APP_HOME_PATH } from "../../constants/paths";

// Icons
import MdMore from "react-ionicons/lib/MdMore";
import MdCash from "react-ionicons/lib/MdCash";
import MdRepeat from "react-ionicons/lib/MdRepeat";
import MdClose from "react-ionicons/lib/MdClose";
import MdCut from "react-ionicons/lib/MdCut";
import MdPerson from "react-ionicons/lib/MdPerson";
import MdPizza from "react-ionicons/lib/MdPizza";

// Import Modal.
import ReportGenerationModal from "../../components/restaurant/ReportGenerationModal";
import DiscountsAndCancellations from "../../components/restaurant/views/DiscountsAndCancellations";
import Goals from "../../components/restaurant/views/Goals";
import SwitchRestaurantName from "./SwitchRestaurantName.js";
import { useTranslation } from "react-i18next";

// Function to get view from location.
const getViewFromLocation = (location) => {
  if (location.search && location.search !== "") {
    // If there are parameters provided.
    if (location.search.split("view=").length > 0) {
      switch (location.search.split("view=")[1]) {
        case "general":
          return "general";
        case "tables":
          return "tables";
        case "top":
          return "top";
        case "payments-and-movements":
          return "payments-and-movements";
        case "discounts-and-cancellations":
          return "discounts-and-cancellations";
        case "goals":
          return "goals";
        default:
          return "general";
      }
    }

    // If no params provided, return default view.
    return "general";
  }

  // If no params provided, return default view.
  return "general";
};
const RestaurantPage = ({ match, location }) => {
  const { t } = useTranslation();
  // Variables for handling restaurant data from API.
  const [restaurant, setRestaurant] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [groupSales, setGroupSales] = useState([]);

  // Variables for managing report dropdown menu.
  const [reportsOpen, setReportsOpen] = useState(false);
  const reportsRef = useRef(null);

  // Variables for managing report generation
  const [reportType, setReportType] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Functions to handle report modal events
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Function to set report type
  const handleReportType = (event) => {
    setReportType(event.currentTarget.dataset["type"]);
    setReportsOpen(false);
    setModalIsOpen(true);
  };

  // Variable for managing active view mode.
  const [activeTab, setActiveTab] = useState(getViewFromLocation(location));

  const refreshRestaurant = () => {
    // Get restaurants once
    setLoading(true);
    getRestaurant(match.params.id)
      .then((rest) => {
        setRestaurant(rest[0]);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };
  const getVentasGrupos = (restaurantDB) => {
    getVentasPorGrupo(restaurantDB, new Date())
      .then((res) => {
        setGroupSales(res.grupos);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // Get restaurants once
    getRestaurant(match.params.id)
      .then((rest) => {
        setRestaurant(rest[0]);

        getVentasGrupos(rest[0].database_string);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    // Get restaurants every 5 minutes
    const interval = setInterval(() => {
      getRestaurant(match.params.id)
        .then((rest) => {
          setRestaurant(rest[0]);
          getVentasGrupos(rest[0].database_string);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }, 1000 * 2 * 60);

    return () => clearInterval(interval);
  }, []);

  // Function for opening dropdown menu
  const showMenu = (event) => {
    event.preventDefault();
    setReportsOpen(true);
    document.addEventListener("click", closeMenu);
  };

  // Function for closing dropdown menu
  const closeMenu = (event) => {
    if (!reportsRef.current?.contains(event.target)) {
      setReportsOpen(false);
      document.removeEventListener("click", closeMenu);
    }
  };

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (error)
    return (
      <Layout title="Error">
        <div className="py-8 wrapper relative">
          <div className="flex justify-between items-center">
            <BackArrow to={APP_HOME_PATH} title="Atrás"></BackArrow>
          </div>
          <h2 className="text-2xl text-gray-800 font-bold mb-4">
            {error.message}
          </h2>
        </div>
      </Layout>
    );

  return (
    <>
      <ReportGenerationModal
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        reportType={reportType}
        restaurantId={restaurant.id}
      ></ReportGenerationModal>
      <Layout title={restaurant.text || "Restaurante"}>
        <div className="py-8 wrapper relative main-admin-page-padding">
          <div className="flex justify-between items-center">
            <BackArrow
              to={APP_HOME_PATH}
              title={t("installPage.backBtn")}
            ></BackArrow>
            <div className="relative">
              <button onClick={showMenu} className="report-button">
                {t("restPage.repBtn")}
              </button>
              <ul
                style={{ minWidth: "22rem" }}
                ref={reportsRef}
                className={`${
                  !reportsOpen ? "hidden" : "block"
                } z-10 bg-white py-2 absolute top-0 mt-8 rounded right-0 border shadow-md text-left text-gray-700 text-sm font-normal`}
              >
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="sales"
                >
                  <MdCash className="fill-current w-6 mr-2"></MdCash>{" "}
                  {t("restPage.salesRep")}
                </li>
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="sales-group"
                >
                  <MdCash className="fill-current w-6 mr-2"></MdCash>{" "}
                  {t("restPage.salesRepGroup")}
                </li>
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="movements"
                >
                  <MdRepeat className="fill-current w-6 mr-2"></MdRepeat>{" "}
                  {t("restPage.movements")}
                </li>
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="discounts"
                >
                  <MdCut className="fill-current w-6 mr-2"></MdCut>{" "}
                  {t("restPage.discountRep")}
                </li>
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="cancellations"
                >
                  <MdClose className="fill-current w-6 mr-2"></MdClose>{" "}
                  {t("restPage.cancellationRep")}
                </li>
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="assistance"
                >
                  <MdPerson className="fill-current w-6 mr-2"></MdPerson>{" "}
                  {t("restPage.attendanceRep")}
                  <span className="ml-auto text-xs text-green-500 font-semibold">
                    {t("admin.toolsDrop2")}
                  </span>
                </li>
                {/*
                <li
                  className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
                  onClick={handleReportType}
                  data-type="products-in-production"
                >
                  <MdPizza className="fill-current w-6 mr-2"></MdPizza> Reporte
                  de productos en producción
                </li>
                */}
              </ul>
            </div>
          </div>
          {/*      <h2 className="text-2xl text-gray-800 font-bold mb-4">
          {restaurant.text || "Restaurante"} 
            
          </h2> */}
          <SwitchRestaurantName
            restaurant={restaurant}
            refreshRestaurant={refreshRestaurant}
          ></SwitchRestaurantName>
          <Tabs setActiveTab={setActiveTab} activeTab={activeTab}></Tabs>
          <hr className="mb-6"></hr>
          {activeTab === "general" && (
            <Online
              online={restaurant.online}
              year={restaurant.year}
              month={restaurant.month}
              week={restaurant.week}
              groupSales={groupSales}
            ></Online>
          )}
          {activeTab === "tables" && (
            <Tables
              openTables={restaurant.openTables}
              cancelledTables={restaurant.cancelledTables}
              closedTables={restaurant.closedTables}
              delayedTables={restaurant.delayedTables}
              restaurantId={match.params.id}
            ></Tables>
          )}
          {activeTab === "top" && (
            <Top
              waiters={restaurant.waiters}
              products={restaurant.products}
              food={restaurant.food}
              drinks={restaurant.drinks}
            ></Top>
          )}
          {activeTab === "payments-and-movements" && (
            <PaymentsAndMovements
              payments={restaurant.payments}
              movements={restaurant.movements}
            ></PaymentsAndMovements>
          )}
          {activeTab === "discounts-and-cancellations" && (
            <DiscountsAndCancellations
              cancelledProducts={restaurant.cancelledProducts}
              restaurantId={restaurant.id}
            ></DiscountsAndCancellations>
          )}
          {activeTab === "goals" && (
            <Goals
              goals={{
                daily: restaurant.daily_goal,
                weekly: restaurant.weekly_goal,
                monthly: restaurant.monthly_goal,
                yearly: restaurant.yearly_goal,
              }}
              values={{
                daily: parseFloat(restaurant.online.total),
                weekly:
                  parseFloat(restaurant.online.total) +
                  parseFloat(restaurant.week.total),
                monthly:
                  parseFloat(restaurant.online.total) +
                  parseFloat(restaurant.month.total),
                yearly:
                  parseFloat(restaurant.online.total) +
                  parseFloat(restaurant.year.total),
              }}
              restaurantId={restaurant.id}
            ></Goals>
          )}
        </div>
      </Layout>
    </>
  );
};

export default RestaurantPage;
