import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import LegalDataInputForm from "./LegalDataInputForm";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_CREATE_PARTIAL_PAY } from "../../constants/paths";

const schema = yup.object().shape({
  legal_name: yup.string().required(),
  support_email: yup.string().optional(),
  street: yup.string().optional(),
  exterior: yup.string().optional(),
  zip: yup.string().required(),
  city: yup.string().optional(),
  state: yup.string().optional(),
  tax_id: yup.string().required().min(12).max(13),
  phone: yup.string().optional(),
  tax_system: yup
    .string()
    .required()
    .test(function (reg) {
      return reg !== "no";
    }),
});

const publicoEnGeneral = {
  legal_name: "Público en general",
  tax_id: "XAXX010101000",
  tax_system: "616",
  use: "S01",
};

const LegalDataForm = ({ restId, clients, series, invoices, prevClient }) => {
  const history = useHistory();
  const [selectedClient, setSelectedClient] = useState(null);
  const [filteredLegalName, setFilteredLegalName] = useState([]);
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClientSearch = (value) => {
    const valueLower = value.toLowerCase();
    if (!clients) return;

    if (valueLower === "") {
      setFilteredLegalName([]);
      setSelectedClient(null);
      reset();
    } else {
      const matches = clients.filter((client) =>
        client.legal_name.toLowerCase().includes(valueLower)
      );
      setFilteredLegalName(matches);
    }
  };

  const evaluateTaxId = (value) => {
    const valueUpper = value.toUpperCase();
    if (valueUpper === "") {
      setSelectedClient(null);
      reset();
    }
    if (!clients) return;

    if (valueUpper !== publicoEnGeneral.tax_id && valueUpper.length >= 12) {
      const client = clients.find((x) => x.tax_id === valueUpper);
      setSelectedClient(client);
    }
  };

  const onSubmit = async (values) => {
    history.push({
      pathname: APP_CREATE_PARTIAL_PAY,
      state: {
        rest_id: restId,
        clientInput: values,
        customers: clients,
        series: series,
        invoices: invoices,
        selectedClient: selectedClient,
      },
    });
  };

  useEffect(() => {
    if (selectedClient) {
      setValue("legal_name", selectedClient.legal_name);
      setValue("tax_id", selectedClient.tax_id);
      setValue("tax_system", selectedClient.tax_system);
      setValue("zip", selectedClient.address.zip);
      setValue("state", selectedClient.address.state);
      setValue("street", selectedClient.address.street);
      setValue("exterior", selectedClient.address.exterior);
      setValue("city", selectedClient.address.city);
      setValue("phone", selectedClient.phone);
      setValue("support_email", selectedClient.email);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (prevClient && !selectedClient) {
      setSelectedClient(prevClient);
      setValue("legal_name", prevClient.legal_name);
      setValue("tax_id", prevClient.tax_id);
      setValue("tax_system", prevClient.tax_system);
      setValue("zip", prevClient.address.zip);
      setValue("state", prevClient.address.state);
      setValue("street", prevClient.address.street);
      setValue("exterior", prevClient.address.exterior);
      setValue("city", prevClient.address.city);
      setValue("phone", prevClient.phone);
      setValue("support_email", prevClient.email);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
          Datos fiscales
        </label>

        <LegalDataInputForm
          register={register}
          filteredLegalName={filteredLegalName}
          selectedClient={selectedClient}
          errors={errors}
          evaluateTaxId={(val) => evaluateTaxId(val)}
          handleClientSearch={(val) => handleClientSearch(val)}
          setSelectedClient={(val) => setSelectedClient(val)}
        />
      </div>
      <div className="flex w-full pt-4 md:pt-0 justify-end">
        <button className="wfacturas-button" type="submit">
          Continuar
        </button>
      </div>
    </form>
  );
};

export default LegalDataForm;
