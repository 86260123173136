import React from "react";
import "./FooterStyles.scss";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  HOME_PATH,
  HOME_PATH_DISTRIBUTION,
  HOME_PATH_FACTURACION,
  HOME_PATH_LANDING,
  POLITICAS_DE_PRIVACIDAD,
  SIGNIN_PATH,
  SIGNUP_PATH,
  TERMINOS_Y_CONDICIONES,
} from "../../constants/paths";
import PoliticasdePrivacidad from "../../pages/public/PoliticasdePrivacidad/PoliticasdePrivacidad";

export function Footer(props) {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="header">
        <div className="image-container one">
          <img src={require("../../images/full-logo.svg")} alt="wmobil logo" />
        </div>
        <ul>
          <Link to={HOME_PATH_LANDING}>
            <li>{t("navBar.rest")}</li>
          </Link>
          <Link to={HOME_PATH_DISTRIBUTION} exact>
            <li>{t("navBar.dist")}</li>
          </Link>
          <Link to={HOME_PATH_FACTURACION} exact>
            <li>Facturación</li>
          </Link>
          <Link to={SIGNIN_PATH} exact>
            <li>{t("navBar.signIn")}</li>
          </Link>
          <Link to={SIGNUP_PATH} exact>
            <li>{t("navBar.register")}</li>
          </Link>
          <li>App</li>
          <Link to={POLITICAS_DE_PRIVACIDAD} exact>
            <li>{t("footer.privacy")}</li>
          </Link>
          <Link to={TERMINOS_Y_CONDICIONES} exact>
            <li>{t("footer.terms")}</li>
          </Link>
        </ul>
        <div className="image-container two">
          <img
            src={require("../../images/inowu-logo.svg")}
            alt="logo de inowu"
          />
        </div>
      </div>
      <div className="bottom-container">
        <h3>WMobil Inowu 2022</h3>
      </div>
    </footer>
  );
}
