import React, { useState } from "react";
import { Link } from "react-router-dom";
import { APP_RESTAURANTS_PATH } from "../../../constants/paths";
import Select from "../../Select";
import SortableTable from "../../SortableTable";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const Tables = ({
  openTables,
  closedTables,
  cancelledTables,
  delayedTables,
  restaurantId,
}) => {
  const { t } = useTranslation();

  const options = [
    {
      value: "openTables",
      title: t("dashboard.dropdownOps.accTableDropdown1"),
    },
    {
      value: "closedTables",
      title: t("dashboard.dropdownOps.accTableDropdown2"),
    },
    {
      value: "cancelledTables",
      title: t("dashboard.dropdownOps.accTableDropdown3"),
    },
    {
      value: "delayedTables",
      title: t("dashboard.dropdownOps.accTableDropdown4"),
    },
  ];

  const [option, setOption] = useState("openTables");

  const changeOption = (e) => {
    setOption(e.currentTarget.value);
  };

  const columns = [
    {
      Header: "Folio",
      accessor: (values) => {
        var numcheque = values.numcheque;
        if (
          numcheque === 0 ||
          numcheque <= 0 ||
          numcheque === "0" ||
          numcheque === null ||
          numcheque === undefined
        ) {
          return (
            <Link
              className="text-blue-700"
              to={`${APP_RESTAURANTS_PATH}/${restaurantId}/cuentas/${values.folio}`}
            >
              {t("restPage.accTableNoFol")}
              {t("restPage.accTableMore")}
            </Link>
          );
        } else {
          return (
            <Link
              className="text-blue-700"
              to={`${APP_RESTAURANTS_PATH}/${restaurantId}/cuentas/${values.folio}`}
            >
              {values.numcheque + " "}
              {t("restPage.accTableMore")}
            </Link>
          );
          //}
        }
      },
    },
    {
      Header: t("restPage.accTableHeader1"),
      accessor: "mesa",
    },
    {
      Header: t("restPage.accTableHeader2"),
      accessor: (values) => {
        var nombre = values.nombre;
        if (
          nombre == "" ||
          nombre == null ||
          nombre == undefined ||
          nombre == " " ||
          nombre == 0 ||
          nombre === 0 ||
          nombre == "0" ||
          nombre === "0"
        ) {
          return t("restPage.accTableNoWait");
        } else {
          return nombre;
        }
      },
    },
    { Header: t("restPage.accTableHeader3"), accessor: "totalarticulos" },
    {
      Header: t("restPage.accTableHeader4"),
      accessor: (values) => {
        var format = values.descuento;
        if (format != 0) {
          format = format + "%";
          return format;
        } else {
          return t("restPage.accTableNoDisc");
        }
      },
    },
    {
      Header: "Total",
      accessor: (values) => {
        return formatter.format(
          typeof values === "number"
            ? values.totalconpropina
            : parseFloat(values.totalconpropina)
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex justify-between w-full">
        <h3 className="text-2xl text-gray-700 font-bold">
          {t("restPage.acc")}
        </h3>
        <Select options={options} onChange={changeOption}></Select>
      </div>
      {option === "openTables" && openTables ? (
        <SortableTable
          columns={columns}
          data={openTables}
          id="open-tables"
        ></SortableTable>
      ) : (
        <></>
      )}
      {option === "closedTables" && closedTables ? (
        <SortableTable
          columns={columns}
          data={closedTables}
          id="closed-tables"
        ></SortableTable>
      ) : (
        <></>
      )}
      {option === "cancelledTables" && cancelledTables ? (
        <SortableTable
          columns={columns}
          data={cancelledTables}
          id="cancelled-tables"
        ></SortableTable>
      ) : (
        <></>
      )}
      {option === "delayedTables" && delayedTables ? (
        <SortableTable
          columns={columns}
          data={delayedTables}
          id="open-tables"
        ></SortableTable>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Tables;
