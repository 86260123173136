import React, { forwardRef, useEffect } from "react";
import WInput from "../WInput";
import WSelect from "../WSelect";

const RelatedDocsSectionForm = forwardRef(
  (
    {
      clientInput,
      invoices,
      selectedComplement,
      series,
      errors,
      invoiceChange,
    },
    ref
  ) => {
    return (
      <>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 gap-x-40 mb-6 items-center">
          {invoices.length > 0 && (
            <WSelect
              name="invoice_select"
              label="Seleccionar factura"
              onChange={invoiceChange}
              value={selectedComplement ? selectedComplement.uuid : -1}
            >
              <option value={-1}>Seleccione una factura</option>
              {invoices.map((invoice, index) => (
                <option key={index} value={invoice.uuid}>
                  {`Folio: ${invoice.series}${invoice.folio_number} Total: $${invoice.total} RFC: ${invoice.customer.tax_id}`}
                </option>
              ))}
            </WSelect>
          )}
          <div>
            <WSelect ref={ref} name="relatedDocs.series" label="Serie">
              <option value={""}></option>
              {series?.map((serie, index) => (
                <option key={index} value={serie.serie_id}>
                  {serie.serie_id}
                </option>
              ))}
            </WSelect>
          </div>
          {invoices.length === 0 && (
            <div className=" wfacturas-table-text font-semibold">
              <p>
                El cliente con RFC: {clientInput.tax_id}, no tiene facturas a
                las que se puedan ligar un complemento de pago
              </p>
            </div>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center">
          <div>
            <WInput
              mandatory
              name="relatedDocs.folio_number"
              ref={ref}
              label="Folio"
            />
            <div className="error">
              {errors.relatedDocs?.folio_number?.message}
            </div>
          </div>
          <div>
            <WInput mandatory name="relatedDocs.uuid" ref={ref} label="UUID" />
            <div className="error">{errors.relatedDocs?.uuid?.message}</div>
          </div>
          <div>
            <WSelect
              name="relatedDocs.payment_method"
              ref={ref}
              label="Método de pago"
            >
              <option value={"PUE"}>Pago en una sola exhibición</option>
              <option value={"PPD"}>Pago en parcialidades o diferido</option>
            </WSelect>
            <div className="error">
              {errors.relatedDocs?.payment_method?.message}
            </div>
          </div>
          <div>
            <WInput
              mandatory
              type="currency"
              name="relatedDocs.prev_balance"
              ref={ref}
              label="Importe anterior"
            />
            <div className="error">
              {errors.relatedDocs?.prev_balance?.message}
            </div>
          </div>
          <div>
            <WInput
              type="currency"
              name="relatedDocs.amount"
              ref={ref}
              label="Importe pagado"
            />
            <div className="error">{errors.relatedDocs?.amount?.message}</div>
          </div>
          <div>
            <WInput
              type="currency"
              name="relatedDocs.balance_due"
              ref={ref}
              label="Importe saldo insoluto"
            />
          </div>
          <div>
            <WSelect
              name="relatedDocs.currency2"
              ref={ref}
              label="Tipo de moneda"
            >
              <option value="MXN">MXN</option>
              <option value="USD">USD</option>
            </WSelect>
          </div>
          <div>
            <WInput
              type="currency"
              name="relatedDocs.exchange2"
              ref={ref}
              label="Tipo de cambio"
            />
          </div>
          <div>
            <WInput
              mandatory
              type="digits"
              name="relatedDocs.partiality"
              ref={ref}
              label="Número de parcialidad"
            />
            <div className="error">
              {errors.relatedDocs?.partiality?.message}
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default RelatedDocsSectionForm;
