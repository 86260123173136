import React, { useEffect, useState } from "react";
import { getTable } from "../../api/Table";
import Layout from "../../components/Layout";
import PieChart from "../../components/restaurant/PieChart";
import { APP_RESTAURANTS_PATH } from "../../constants/paths";

import BackArrow from "../../components/BackArrow";
import LoadingIndicator from "../../components/LoadingIndicator";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const TablePage = ({ match }) => {
  const [table, setTable] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get table once
    getTable(match.params.restaurant_id, match.params.table_id)
      .then((t) => {
        setTable(t);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  return (
    <Layout title={table.mesa || "Cuenta"}>
      <div className="py-8 wrapper">
        <BackArrow
          to={`${APP_RESTAURANTS_PATH}/${match.params.restaurant_id}?view=tables`}
          title="Atrás"
        ></BackArrow>
        <div className="flex justify-between items-center">
          <div>
            <h4 className="text-sm text-gray-800 font-medium">Total</h4>
            <span className="flex items-baseline">
              <span className="text-brand-700 font-bold text-4xl">
                {formatter.format(
                  typeof table.totalconpropina === "number"
                    ? table.totalconpropina
                    : parseFloat(table.totalconpropina)
                )}
              </span>
              <span className="text-xs ml-1 uppercase">MXN</span>
            </span>
            <span className="text-gray-700 font-light text-sm">
              Propina registrada:{" "}
              <span className="font-medium">
                {table.propina === "0"
                  ? "No disponible"
                  : formatter.format(parseFloat(table.propina)) + " MXN"}
              </span>
            </span>
          </div>

          <div>
            <h2 className="text-2xl text-gray-800 font-bold text-right">
              {table.folio ? `${table.folio} - ${table.mesa}` : "Cuenta"}
            </h2>
            <p className="text-gray-700 text-sm text-right">
              Mesero a cargo:{" "}
              <span className="text-gray-900">{table.nombre}</span>
            </p>
          </div>
        </div>

        <hr className="mt-2 mb-6"></hr>
        <div className="flex flex-wrap md:flex-no-wrap">
          <div className="w-full block p-4 rounded border mt-2 shadow-sm border-gray-200">
            <div className="text-sm text-gray-800 font-medium mb-2">Cuenta</div>
            <div className="px-4 py-2 border rounded mb-2">
              <div className="flex justify-between">
                <div className="text-gray-700 text-xs">
                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Tipo:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.cierre === null || table.pagado === "0"
                        ? "Cuenta abierta"
                        : "Cuenta cerrada"}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Tipo de servicio:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.tipodeservicio === null
                        ? "No disponible"
                        : table.tipodeservicio}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Total de artículos:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.tipodeservicio === null
                        ? "No disponible"
                        : table.totalarticulos}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Total en descuentos:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.totaldescuentos === "0"
                        ? "No aplica"
                        : formatter.format(table.totaldescuentos) + " MXN"}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Total en cortesías:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.totalcortesias === "0"
                        ? "No aplica"
                        : formatter.format(table.totalcortesias) + " MXN"}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Pago con efectivo:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.efectivo === "0"
                        ? "No usado"
                        : formatter.format(table.efectivo) + " MXN"}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Pago con tarjeta:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.tarjeta === "0"
                        ? "No usado"
                        : formatter.format(table.tarjeta) + " MXN"}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Pago con vales:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.vales === "0"
                        ? "No usado"
                        : formatter.format(table.vales) + " MXN"}
                    </span>
                  </p>

                  <p
                    className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                  >
                    Pago con otros:{" "}
                    <span className="text-sm font-medium text-gray-700">
                      {table.otros === "0"
                        ? "No usado"
                        : formatter.format(table.otros) + " MXN"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="text-sm text-gray-800 font-medium mb-2">
              Productos
            </div>
            {table.products.length === 0 ||
            table.products[0].idproducto === null ? (
              <div className="px-4 py-2 border rounded mb-2">
                <div className="flex justify-between">
                  <div className="text-gray-700 text-xs">
                    <p
                      className="transition duration-500 ease-in-out 
                       hover:text-brand-700 transform hover:-translate-y-1"
                    >
                      <span
                        className="text-sm font-medium text-gray-700 transition duration-500 ease-in-out 
                       hover:text-red-700 transform hover:-translate-y-1"
                      >
                        {"No hay productos registrados en la base de datos"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              table.products &&
              table.products.map((product) => (
                <div className="px-4 py-2 border rounded mb-2">
                  <div className="flex justify-between">
                    <div className="text-gray-700 text-xs">
                      <h4 className="text-sm text-gray-900">
                        {product.descripcion}
                      </h4>
                      <p>{product.grupo}</p>
                    </div>
                    <div className="text-gray-700 text-sm">
                      <span className="text-blue-700 text-sm">
                        Descuento:
                        <span className="text-blue-700 font-bold text-base">
                          {
                            product.descuento != 0 //Condicion
                              ? " " + product.descuento + " %" //si no se cumple
                              : " No aplica" //si se cumple
                          }
                        </span>
                      </span>
                      <span>{"   |   "}</span>
                      {product.precio === 0 || product.precio === "0"
                        ? ""
                        : " " + product.cantidad + " x "}
                      <span className="text-gray-900 font-bold text-base">
                        {product.precio === 0 || product.precio === "0"
                          ? " Sin costo"
                          : product.precio + " MXN"}
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <PieChart
            className="ml-4"
            title="Distribución de cuenta"
            customProperties={{
              animate: true,
              margin: { top: 30, right: 30, bottom: 30, left: 30 },
            }}
            data={[
              {
                id: "totalalimentos",
                label: "Alimentos",
                value: parseFloat(table.totalalimentos),
              },
              {
                id: "totalbebidas",
                label: "Bebidas",
                value: parseFloat(table.totalbebidas),
              },
            ]}
          ></PieChart>
        </div>
      </div>
    </Layout>
  );
};

export default TablePage;
