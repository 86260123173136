import React from "react";
import "./styles/DetailedCardMoreInfo.scss";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DetailedCardMoreInfo = ({
  cancelaciones_cuentas,
  cancelaciones_productos,
  descuentos_cuentas,
  descuentos_productos,
}) => {
  const { t } = useTranslation();
  return (
    <div className="detail-card-more-info-container">
      <div className="card">
        <p className="">{t("restPage.detailedCard1")}</p>
        <p>
          <span className="text-lg font-medium ">
            {"$ " + cancelaciones_cuentas + " "}
          </span>
          MXN
        </p>
      </div>
      <div className="card">
        <p className="">{t("restPage.detailedCard2")}</p>
        <p>
          <span className="text-lg font-medium">
            {"$ " + cancelaciones_productos + " "}
          </span>
          MXN
        </p>
      </div>
      <div className="card">
        <p className="">{t("restPage.detailedCard3")}</p>
        <p>
          <span className="text-lg font-medium">
            {"$ " + descuentos_cuentas + " "}
          </span>
          MXN
        </p>
      </div>
      <div className="card">
        <p className=" ">{t("restPage.detailedCard4")}</p>
        <p>
          <span className="text-lg font-medium">
            {"$ " + descuentos_productos + " "}
          </span>
          MXN
        </p>
      </div>
    </div>
  );
};

export default DetailedCardMoreInfo;
