import React, { useEffect } from "react";

const WBanner = ({ type, setShowBanner, text, duration }) => {
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setShowBanner(false);
  //   }, duration);

  //   return () => clearTimeout(timeoutId);
  // }, [setShowBanner, duration]);

  // const alertType =
  //   type === "alert"
  //     ? "bg-yellow-200"
  //     : type === "success"
  //     ? "bg-green-200"
  //     : "bg-red-200";

  return (
    <div className="flex items-center">
      <div
        className={`fixed animate-fade top-0 shadow-lg left-0 w-full bg-yellow-200 p-2 items-center justify-center font-medium text-green-900 text-center`}
      >
        {text}
      </div>
    </div>
    // <div className="flex items-center">
    //   <div
    //     className={`fixed top-0 left-0 w-full z-50 ${alertType} p-2 items-center justify-center font-medium text-green-900 text-center`}
    //   >
    //     {text}
    //   </div>
    //   <div
    //     className="fixed cursor-pointer z-50 font-bold text-sm text-green-900 right-0 top-0 px-4 py-1"
    //     onClick={() => setShowBanner(false)}
    //   >
    //     x
    //   </div>
    // </div>
  );
};

export default WBanner;
