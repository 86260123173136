import axios from "axios";
import { API_PATH } from "../constants/api";

import jwt_decode from "jwt-decode";

export const getDistributorData = () => {
  const token = localStorage.getItem("token");

  return jwt_decode(token);
};

export const fetchReferrals = () => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/distributor/referrals`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.referrals;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const fetchReferral = (id) => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/distributor/referrals/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.referral;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const updateAvatar = (file) => {
  const token = localStorage.getItem("token");

  const bodyFormData = new FormData();
  bodyFormData.append("avatar", file);

  return axios
    .post(`${API_PATH}/distributor/avatar`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      window.dispatchEvent(new Event("storage"));

      return response.data.token;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const updateDataAdmin = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(`${API_PATH}/distributor/updateDataAdmin`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      window.dispatchEvent(new Event("storage"));

      return response.data.token;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const updateData = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(`${API_PATH}/distributor/updateData`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      window.dispatchEvent(new Event("storage"));

      return response.data.token;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const fetchRestaurant = (user_id, restaurant_id) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/distributor/referrals/${user_id}/restaurants/${restaurant_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data.restaurant;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
