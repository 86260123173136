import React from "react";
import { Link } from "react-router-dom";
import { SIGNIN_PATH, SIGNUP_PATH } from "../../constants/paths";
import "./SignedOutLinks.scss";
import { useTranslation } from "react-i18next";

const SignedOutLinks = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ul className="hideOnMobile auth-links md:flex hidden pl-4 text-base items-center font-regular">
        <li>
          <Link to={SIGNIN_PATH}>{t("navBar.signIn")}</Link>
        </li>
        <li className="md:ml-4">
          <Link to={SIGNUP_PATH}>{t("navBar.register")}</Link>
        </li>
      </ul>

      <ul className="showOnMobile auth-links sm:flex hidden pl-4 text-base items-center font-regular">
        <li>
          <Link className="rightPadded" to={SIGNIN_PATH}>
            {t("navBar.signIn")}
          </Link>{" "}
          <Link className="leftBordered rightPadded" to={SIGNUP_PATH}>
            {t("navBar.register")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SignedOutLinks;
