import React, { useMemo } from "react";
import { APP_MY_FACTURAS_PATH } from "../../constants/paths";

const FacturasPagination = ({
  page,
  itemsInPage,
  totalResults,
  previousPage,
  canPreviousPage,
  tblnextPage,
  canNextPage,
  gotoPage,
  itemsInTable,
  setCurrPage,
}) => {
  const MAX_PAGES_DISPLAY = 15;

  const renderPageNumbers = useMemo(() => {
    const pageNumbers = [];
    page = page + 1;
    const pages = Math.min(
      Math.floor(totalResults / itemsInPage) +
        (totalResults % itemsInPage !== 0 ? 1 : 0)
    );
    const maxPagesDisplay = Math.min(MAX_PAGES_DISPLAY, pages);

    let startPage = Math.max(1, page - Math.floor(maxPagesDisplay / 2));
    let endPage = Math.min(startPage + maxPagesDisplay - 1, pages);
    if (page > pages - Math.floor(maxPagesDisplay / 2)) {
      startPage = Math.max(1, pages - maxPagesDisplay + 1);
      endPage = pages;
    }

    const handleGotoPage = (i) => {
      gotoPage(i - 1);
      setCurrPage(i - 1);
    };

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <span
            onClick={() => handleGotoPage(i)}
            className={`flex items-center cursor-pointer rounded justify-center w-10 h-10 leading-tight ${
              i === page
                ? `bg-green-700 text-white hover:bg-green-600`
                : "text-green-700 hover:text-green-600"
            } dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
          >
            {i}
          </span>
        </li>
      );
    }
    return pageNumbers;
  }, [itemsInPage, page, totalResults, gotoPage]);

  const handlePrevPage = () => {
    setCurrPage(page - 1);
    previousPage();
  };

  const handleNextPage = () => {
    setCurrPage(page + 1);
    tblnextPage();
  };

  return (
    <>
      <nav aria-label="Page navigation">
        <ul className="main-admin-page-users-table-wrapper inline-flex text-sm text-green-800 font-normal mt-4 mb-4 rounded">
          <div className="flex items-center justify-around">
            <>
              <div className="flex items-center rounded justify-center h-8 leading-tight text-green-800 bg-white">
                <li>
                  <span
                    disabled={canPreviousPage}
                    onClick={handlePrevPage}
                    className="flex items-center rounded justify-between w-32 px-3 h-8 ms-0 leading-tight text-green-800 bg-white hover:text-green-600"
                  >
                    {canPreviousPage && (
                      <div className="flex items-center cursor-pointer rounded justify-between w-32 px-3 h-8 leading-tight text-green-800 bg-white hover:text-green-600">
                        <svg
                          className="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="ml-2">Anterior</div>
                      </div>
                    )}
                  </span>
                </li>
              </div>
            </>
            {renderPageNumbers}
            <li>
              <span
                disabled={canNextPage}
                className="flex items-center rounded justify-between w-32 px-3 h-8 ms-0 leading-tight text-green-800 bg-white hover:text-green-600"
                onClick={handleNextPage}
              >
                {canNextPage && (
                  <div className="flex items-center cursor-pointer rounded justify-between w-32 px-3 h-8 leading-tight text-green-800 bg-white hover:text-green-600">
                    <div className="mr-2">Siguiente</div>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                )}
              </span>
            </li>
          </div>
        </ul>
      </nav>
      <text className="text-green-800">
        Mostrando {itemsInTable} de {totalResults}
      </text>
    </>
  );
};

export default FacturasPagination;
