import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { APP_HOME_PATH } from "../../constants/paths";

const UserIsNotAuthRoute = ({ children }) => {
  // State variable for tracking auth status.
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("token") ? true : false
  );

  useEffect(() => {
    // Set an event listener for local storage.
    window.addEventListener("storage", reactToTokenChange);

    // Remove event listener.
    return () => {
      window.removeEventListener("storage", reactToTokenChange);
    };
  }, []);

  // Function to react to token change in local storage.
  const reactToTokenChange = () => {
    setIsAuth(window.localStorage.getItem("token") ? true : false);
  };

  // If user is authenticated return the proper route.
  if (!isAuth) return <>{children}</>;

  // Else, redirect to app home page.
  return <Redirect to={APP_HOME_PATH}></Redirect>;
};

export default UserIsNotAuthRoute;
