import React, { useState } from "react";
import "./MyAccountPage.css";
import NewFacturaForm from "../../components/account/NewFacturaForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { useEffect } from "react";
import { getFacturaUser, searchFolio } from "../../api/User";
import sectionArrow from "../../images/sectionArrow.svg";
import LoadingIndicator from "../../components/LoadingIndicator";
import NewGeneralFacturaForm from "../../components/account/NewGeneralFacturaForm";
import InvoicesSidebar from "../../components/navigation/InvoicesSidebar";
import WFacturasLayout from "../../components/WFacturasLayout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_SEARCH_FOLIO_FACTURA } from "../../constants/paths";
import { getAllClientsFromRest, getFolioSettings } from "../../api/Utils";

const NewFacturaPage = ({ match }) => {
  const location = useLocation();
  let history = useHistory();
  const { rest_id } = match.params;
  const {
    selectedFolios,
    selectedWfNumcheques,
    deselectedFolios,
    generalDescription,
    includeTips,
    folioStartDate,
    folioEndDate,
    selectAll,
  } = location.state || {};
  const [userData, setUserData] = useState([]);
  const [series, setSeries] = useState([]);
  const [cheques, setCheques] = useState(null);
  const [clients, setClients] = useState(null);
  const [loading, setLoading] = useState(false);
  const dateRange = { folioStartDate, folioEndDate } || null;

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const userFromFacturapi = await getFacturaUser(rest_id);
        setUserData(userFromFacturapi);

        if (selectedFolios) {
          const folioData = await searchFolio(
            rest_id,
            selectedFolios,
            includeTips,
            dateRange
          );
          setCheques(folioData);
          setLoading(false);
        }
      } catch (error) {
        return null;
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [rest_id, selectedFolios]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const allClients = await getAllClientsFromRest(rest_id);
        const folioSettings = await getFolioSettings(rest_id);
        setClients(allClients);
        setSeries(folioSettings.series);
      } catch (error) {
        return null;
      }
    };

    getClients();
  }, []);

  return (
    <WFacturasLayout title="Mi cuenta" fullWidth>
      <div className="flex myaccount-main-container">
        <InvoicesSidebar />
        <div className="p-8 w-full mt-12 md:mt-0">
          <div className="flex items-center font-medium text-xl collaborator-install-back-text-container text-green-800 mt-4">
            <span
              onClick={() => history.push(APP_SEARCH_FOLIO_FACTURA)}
              className="cursor-pointer"
            >
              Folios pendientes
            </span>
            <span>
              <img className="mx-4" src={sectionArrow} />
            </span>
            <span className="font-bold">Nueva factura</span>
          </div>
          {loading ? (
            <LoadingIndicator />
          ) : cheques && selectedFolios ? (
            <NewFacturaForm
              cheques={cheques}
              restId={rest_id}
              selectedFolios={selectedFolios}
              selectedWfNumcheques={selectedWfNumcheques}
              deselectedFolios={deselectedFolios}
              userData={userData}
              generalDescription={generalDescription}
              clients={clients}
              includeTips={includeTips}
              series={series}
              dateRange={dateRange}
              selectAll={selectAll}
            />
          ) : (
            <NewGeneralFacturaForm
              restId={rest_id}
              userData={userData}
              clients={clients}
              series={series}
            />
          )}
        </div>
      </div>
    </WFacturasLayout>
  );
};

export default NewFacturaPage;
