import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchDistributorsPayments, fetchRegularUsers } from "../../api/Admin";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import SwitchPasswordForm from "./SwitchPasswordForm";
import SwitchRestaurantColorStatus from "./SwitchRestaurantColorStatus";
import TerminateSessionForm from "./TerminateSessionForm";
import adminToolsOage from "./AdminToolsPage.scss";
import VerifyUserTool from "./VerifyUserTool";
import AddSubTool from "./AddSubTool";
import { AdminClientDownloadTool } from "./AdminClientDownloadTool";
import { useTranslation } from "react-i18next";
import ResetHistoricsTool from "./ResetHistoricsTool";
import UpdateInvoicedCheque from "./UpdateInvoicedCheque.js";

const AdminToolsPage = () => {
  const { t } = useTranslation();
  const [distributors, setDistributors] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (error) {
    return (
      <Layout title="Herramientas">
        <div>{error.message}</div>
      </Layout>
    );
  }

  return (
    <Layout title="Herramientas" admin>
      <div className="py-8 wrapper form main-admin-page-padding margin-remover">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl text-gray-800 font-bold">
            {t("navBar.tools")}
          </h2>
        </div>
        <div>
          <div className="form-layer">
            <AdminClientDownloadTool />
          </div>
          <div className="form-layer">
            <SwitchPasswordForm></SwitchPasswordForm>
          </div>
          <div className="form-layer">
            <TerminateSessionForm></TerminateSessionForm>
          </div>
          <div className="form-layer">
            <VerifyUserTool></VerifyUserTool>
          </div>
          <div className="form-layer">
            <ResetHistoricsTool />
          </div>
          <div className="form-layer">
            <UpdateInvoicedCheque />
          </div>
          <div className="form-layer">
            <AddSubTool></AddSubTool>
          </div>
          {/*  <div className="form-layer">
                        <SwitchRestaurantColorStatus></SwitchRestaurantColorStatus>
                    </div> */}
        </div>

        <hr className="mt-2 mb-4"></hr>
      </div>
    </Layout>
  );
};
export default AdminToolsPage;
