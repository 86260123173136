import React, { useEffect, useRef, useState } from "react";
import { getFolioSettings } from "../../api/Utils";
import { useRestsDropdown } from "../../hooks/useRestsDropdown";
import WSelect from "../WSelect";
import * as yup from "yup";
import { useToast } from "../../context/ToastContext";
import WInput from "../WInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import WFile from "../WFile";
import { fetchUserProfile } from "../../api/Auth";
import LoadingIndicator from "../LoadingIndicator";
import {
  updateSeriesTable,
  updateFolioSettings,
  updateRestaurantLogo,
  callSyncCheques,
} from "../../api/Restaurant";
import { useProfilePic } from "../../context/ProfilePicContext";
import { APP_WFACTURAS_PLANS } from "../../constants/paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const paymentSchema = yup.object().shape({
  new_start_folio: yup.string().optional(),
  new_folio_end_date: yup.string().optional(),
  enable_pmethod_select: yup.boolean().optional(),
  general_description: yup.string().optional(),
  selfinvoice_serie: yup.number().optional(),
});

const emptyValues = {
  serie_id: "",
  folio_start: 1,
  serie_type: " ",
};

const SettingsForm = () => {
  const history = useHistory();
  const { showToast } = useToast();
  const { pictureKey, updatePictureKey } = useProfilePic();
  const {
    restaurants,
    restId,
    setRestId,
    loading,
    setLoading,
    selectRef,
    userPlanData,
  } = useRestsDropdown();
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(paymentSchema),
  });

  const [edit, setEdit] = useState(false);
  const [logo, setLogo] = useState(null);
  const [myFile, setMyFile] = useState(null);
  const [initialFolio, setInitialFolio] = useState(null);
  const [license, setLicense] = useState(null);
  const [folioSettingsDuedate, setFolioSettingsDuedate] = useState(null);
  const [enablePmethodSelect, setEnablePmethodSelect] = useState(false);
  const [enableSelfInvoiceGeneralDesc, setEnableSelfInvoiceGeneralDesc] =
    useState(false);
  const [showGeneralDesc, setShowGeneralDesc] = useState(false);
  const [generalDesc, setGeneralDesc] = useState("");
  const [user, setUser] = useState([]);

  const [series, setSeries] = useState([]);
  const [selfInvActiveSerie, setSelfInvActiveSerie] = useState(-1);
  const [selectedValue, setSelectedValue] = useState(emptyValues);
  //countdowntimer
  const [periodData, setPeriodData] = useState({ month: null, year: null });
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const defaultTime = 5 * 60 * 1000;
  const minDate = new Date();
  const year = minDate.getFullYear();
  const prevYear = year - 1;
  minDate.setHours(minDate.getHours() - 72);

  const fileInputRef = useRef(null);

  const handleLogoChange = (event) => {
    const file = event.target.files[0];
    setMyFile(file);
    if (file) {
      setLogo({
        url: URL.createObjectURL(file),
        name: file.name,
      });
    }
  };

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const getPlanData = async (restId) => {
    try {
      setLoading(true);
      const folioSettings = await getFolioSettings(restId);
      setLicense(folioSettings.license);
      setInitialFolio(
        folioSettings.folio_start ? folioSettings.folio_start : null
      );
      setFolioSettingsDuedate(
        folioSettings.folio_duedate ? folioSettings.folio_duedate : null
      );
      if (
        folioSettings.general_description &&
        folioSettings.general_description !== ""
      ) {
        setGeneralDesc(folioSettings.general_description);
        setShowGeneralDesc(true);
      }
      if (folioSettings.selfinv_general_description) {
        setEnableSelfInvoiceGeneralDesc(true);
        setShowGeneralDesc(true);
      }

      if (folioSettings.series?.length > 0) {
        setSeries(folioSettings.series);
        setSelectedValue(folioSettings.series[0]);
        folioSettings.series.map((serie) => {
          if (serie.selfinv_active) setSelfInvActiveSerie(serie.id);
        });
      }
      if (folioSettings.pmethod_select)
        setEnablePmethodSelect(folioSettings.pmethod_select);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return null;
    }
  };

  const getUserData = async () => {
    try {
      const userData = await fetchUserProfile();
      setUser(userData);
    } catch (error) {
      return null;
    }
  };

  const submit = async (values) => {
    try {
      const pMethodSelectVal = values.enable_pmethod_select;
      const selfInvoiceGeneralDescActive = values.checkbox_selfinv_general_desc;
      const folioStartVal = values.new_start_folio;
      const endDateVal = values.new_folio_end_date;
      const selfInvSerie = values.selfinvoice_serie;
      let generalDescVal = values.general_description;
      const serieIndex = parseInt(values.serie_index);
      const serieFolio = parseInt(values.serie_folio_start);
      const serieId = values.serie_id;
      const serieType = values.serie_type;

      const updatePMethod = enablePmethodSelect !== pMethodSelectVal;

      const updateGeneralDescOnSelfInvoice =
        selfInvoiceGeneralDescActive !== enableSelfInvoiceGeneralDesc;

      const clearGeneralDesc = !showGeneralDesc && generalDesc !== "";

      const updateFolioStart = folioStartVal
        ? initialFolio !== folioStartVal
        : false;

      const updateDueDate = endDateVal
        ? folioSettingsDuedate !== endDateVal
        : false;

      const updateGeneralDesc = generalDescVal
        ? generalDesc !== generalDescVal
        : false;

      const updateSelfInvActiveSerie = selfInvSerie
        ? selfInvActiveSerie !== selfInvSerie
        : false;

      const shouldUpdate =
        updatePMethod ||
        updateFolioStart ||
        updateDueDate ||
        updateGeneralDesc ||
        updateGeneralDescOnSelfInvoice ||
        updateSelfInvActiveSerie;

      if (clearGeneralDesc) generalDescVal = "";

      if (shouldUpdate || clearGeneralDesc) {
        await updateFolioSettings(
          restId,
          folioStartVal,
          endDateVal,
          pMethodSelectVal,
          generalDescVal,
          selfInvoiceGeneralDescActive,
          selfInvSerie
        );
        setEnablePmethodSelect(pMethodSelectVal);
        showToast("Datos de autofactura actualizados", "success");
      }
      if (logo) {
        try {
          const updateLogo = await updateRestaurantLogo(logo, myFile, restId);
          updatePictureKey(updateLogo.key);
          showToast("Logo actualizado", "success");
        } catch (error) {
          showToast("Error al actualizar tu logo", "error");
        }
      }

      const isNewEntry = isNaN(serieIndex);

      const findUpdatedFields = (obj1, obj2) => {
        for (const key in obj1) {
          if (obj1[key] !== obj2[key]) {
            return true;
          }
        }
        return false;
      };

      let updateSeries = isNewEntry;

      if (!isNewEntry) {
        const matchedObject = series.filter(
          (obj) => obj.id === selectedValue.id
        )[0];

        if (matchedObject) {
          updateSeries = findUpdatedFields(matchedObject, selectedValue);
        }
      }

      const updateSerieId = serieId !== selectedValue.serie_id;
      const updateSerieFolio = serieFolio !== selectedValue.folio_start;
      const updateSerieType = serieType !== selectedValue.serie_type;

      if (!serieId || serieId === "") return;
      if (!serieFolio || serieFolio === "") return;
      if (!serieType || serieType === "") return;

      if (
        updateSerieId ||
        updateSerieFolio ||
        updateSerieType ||
        updateSeries
      ) {
        await updateSeriesTable(
          restId,
          serieIndex,
          serieId,
          serieFolio,
          serieType
        );
        showToast("Datos de serie actualizados", "success");
      }

      setEdit(false);
    } catch (error) {
      showToast(
        "Error al actualizar tus datos, intente de nuevo mas tarde",
        "error"
      );
    }
  };

  const handleChange = (val) => {
    const selectedSerie = series.find((serie) => serie.id === parseInt(val));

    setSelectedValue(selectedSerie || emptyValues);
  };

  const handleSerieIdChange = (e) => {
    const inputUpper = e.target.value.toUpperCase();

    setSelectedValue((prev) => ({
      ...prev,
      serie_id: inputUpper,
    }));
  };

  const handleSerieFolioChange = (e) => {
    const folioStartInt = parseInt(e.target.value);

    setSelectedValue((prev) => ({
      ...prev,
      folio_start: folioStartInt,
    }));
  };

  const handleSerieTypeChange = (e) => {
    const serieType = e.target.value;

    setSelectedValue((prev) => ({
      ...prev,
      serie_type: serieType,
    }));
  };

  const handleSyncClick = async () => {
    try {
      if (!periodData.month || !periodData.year) {
        showToast("Favor de seleccionar un periodo", "error");
        return;
      } else {
        await callSyncCheques(restId, periodData.month, periodData.year);
        const timestamp = Date.now();
        localStorage.setItem("syncButtonTimestamp", timestamp);
        setIsDisabled(true);
        setCountdown(defaultTime);
        showToast("Solicitud enviada con éxito", "success");
      }
    } catch (error) {
      console.error("Error updating cheques:", error);
    }
  };

  const formatCountdown = (milliseconds) => {
    const minutes = Math.floor(milliseconds / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
    return `0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleRestaurantChange = (value) => {
    setRestId(value);
  };

  const handleEdit = () => {
    setEdit((prev) => !prev);
  };

  const goToPlans = () => {
    history.push(APP_WFACTURAS_PLANS);
  };

  useEffect(() => {
    if (restId) {
      getPlanData(restId);
    }
  }, [restId]);

  useEffect(() => {
    const storedTimestamp = localStorage.getItem("syncButtonTimestamp");
    if (storedTimestamp) {
      const timeLeft = defaultTime - (Date.now() - storedTimestamp);
      if (timeLeft > 0) {
        setCountdown(timeLeft);
        setIsDisabled(true);
      }
    }

    getUserData();
  }, []);

  useEffect(() => {
    let interval;
    if (isDisabled) {
      interval = setInterval(() => {
        const storedTimestamp = localStorage.getItem("syncButtonTimestamp");
        const timeLeft = defaultTime - (Date.now() - storedTimestamp);
        if (timeLeft <= 0) {
          setIsDisabled(false);
          setCountdown(0);
          localStorage.removeItem("syncButtonTimestamp");
          clearInterval(interval);
        } else {
          setCountdown(timeLeft);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isDisabled]);

  return (
    <form className="p-8 w-full mt-12 md:mt-0" onSubmit={handleSubmit(submit)}>
      <div className="flex justify-between items-center">
        <div>
          <h3 className="facturas-title">Configuración</h3>
          <text className="text-md">
            En esta sección podrás ver los datos de tu sucursal
          </text>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-4 md:gap-10">
          <span
            onClick={handleEdit}
            className="wfacturas-button-outline-green cursor-pointer"
          >
            Habilitar edición
          </span>
          <button type="submit" className="wfacturas-button">
            Guardar
          </button>
        </div>
      </div>
      <div className="input-wrapper mt-4 mb-4">
        <WSelect
          mandatory
          name="restId"
          label="Seleccione sucursal"
          defaultValue={restaurants}
          ref={selectRef}
          onChange={(value) => handleRestaurantChange(value)}
        >
          {restaurants?.map((rest, index) => (
            <option key={index} value={rest.id}>
              {rest.text}
            </option>
          ))}
        </WSelect>
      </div>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between items-start">
            <div className="w-full">
              <label className="text-2xl mb-4 font-semibold text-green-800 mt-5 block">
                Datos de cuenta
              </label>
              <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-6 md:grid-rows-2 gap-x-10 gap-y-8">
                <div>
                  <WInput
                    disabled
                    name="name"
                    ref={register}
                    label="Nombre"
                    value={`${user.name} ${user.lastName}` || ""}
                  />
                  {errors.cardNumber && (
                    <div className=" text-red-600 py-1 text-sm">
                      {errors.cardNumber.message}
                    </div>
                  )}
                </div>
                <div>
                  <WInput
                    disabled
                    name="email"
                    ref={register}
                    label="Correo"
                    value={user.email || ""}
                  />
                  {errors.exp && (
                    <div className=" text-red-600 py-1 text-sm">
                      {errors.exp.message}
                    </div>
                  )}
                </div>
                <div>
                  <WInput
                    disabled
                    name="subscriptionType"
                    ref={register}
                    label="Tipo de suscripción"
                    value={
                      userPlanData?.periodicity
                        ? userPlanData.periodicity === "month"
                          ? "Mensual"
                          : "Anual"
                        : "Sin plan"
                    }
                  />
                  {errors.name && (
                    <div className=" text-red-600 py-1 text-sm">
                      {errors.name.message}
                    </div>
                  )}
                </div>
                <div>
                  <WInput
                    disabled
                    name="plan"
                    ref={register}
                    label="Plan"
                    value={
                      userPlanData?.plan
                        ? `Plan ${userPlanData.plan}`
                        : "Sin plan"
                    }
                  />
                  {errors.cvc && (
                    <div className=" text-red-600 py-1 text-sm">
                      {errors.cvc.message}
                    </div>
                  )}
                </div>
                <div>
                  <WInput
                    disabled
                    name="startDate"
                    ref={register}
                    label="Contratado"
                    value={
                      userPlanData?.created_at
                        ? userPlanData.created_at.replace("T", " ")
                        : "--"
                    }
                  />
                  {errors.cvc && (
                    <div className=" text-red-600 py-1 text-sm">
                      {errors.cvc.message}
                    </div>
                  )}
                </div>
                <div>
                  <WInput
                    disabled
                    name="endDate"
                    ref={register}
                    label="Vencimiento"
                    value={
                      userPlanData?.due_date
                        ? userPlanData.due_date.replace("T", " ")
                        : "--"
                    }
                  />
                  {errors.cvc && (
                    <div className=" text-red-600 py-1 text-sm">
                      {errors.cvc.message}
                    </div>
                  )}
                </div>
                <div>
                  <WInput
                    disabled
                    name="license"
                    label="Licencia"
                    value={license ? license : "--"}
                  />
                </div>
                <div>
                  <WInput
                    disabled
                    name="rest_id"
                    label="Id del restaurante"
                    value={restId ? restId : "--"}
                  />
                </div>
              </div>
              <div className="flex wfacturas-table-text pr-6 pt-2 text-2xl font-medium underline w-1/3 justify-end">
                <label className="cursor-pointer" onClick={goToPlans}>
                  Cambiar plan
                </label>
              </div>
            </div>
            <div className="w-full md:w-1/4 mt-8 md:mt-0"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start">
            <div className="w-full">
              <label className="text-2xl mb-4 font-semibold text-green-800 mt-8 block">
                Agregar o modificar series
              </label>
              <div className="grid grid-cols-1 mt-6 md:grid-cols-3 gap-x-10 gap-y-8">
                <div className="relative w-full">
                  <WSelect
                    disabled={!edit}
                    type="digits"
                    name="serie_index"
                    className="absolute top-0 left-0 wfacturas-input appearance-none h-10"
                    ref={register}
                    label="Serie"
                    onChange={(val) => handleChange(val)}
                  >
                    {series.map((serie) => (
                      <option value={serie.id}>{serie.serie_id}</option>
                    ))}
                    <option value="">NUEVA</option>
                  </WSelect>
                  <input
                    disabled={!edit}
                    type="text"
                    placeholder="NUEVA"
                    name="serie_id"
                    ref={register}
                    onChange={handleSerieIdChange}
                    className="wfacturas-input-invisible uppercase"
                    value={selectedValue.serie_id}
                  />
                </div>
                <div className="w-full">
                  <WInput
                    disabled={!edit}
                    name="serie_folio_start"
                    type="digits"
                    ref={register}
                    onChange={handleSerieFolioChange}
                    label="Folio de inicio"
                    value={selectedValue.folio_start}
                  />
                </div>
                <div className="w-full">
                  <WInput
                    disabled={!edit}
                    name="serie_type"
                    ref={register}
                    onChange={handleSerieTypeChange}
                    label="Tipo de serie"
                    value={selectedValue.serie_type}
                  />
                </div>
              </div>
              <label className="text-2xl mb-4 font-semibold text-green-800 mt-8 block">
                Ajustes de autofactura
              </label>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-8">
                <div className="w-full">
                  <WInput
                    disabled={!edit}
                    name="new_start_folio"
                    type="digits"
                    ref={register}
                    label="Folio de inicio"
                    defaultValue={initialFolio}
                  />
                  {!initialFolio && (
                    <div className="error">Debe asignar un folio de inicio</div>
                  )}
                </div>
                <div className="w-full">
                  <WSelect
                    disabled={!edit}
                    type="digits"
                    name="new_folio_end_date"
                    ref={register}
                    label="Vigencia"
                    defaultValue={folioSettingsDuedate}
                  >
                    <option hidden value={null}></option>
                    <option value={24}>24 horas</option>
                    <option value={48}>48 horas</option>
                    <option value={72}>72 horas</option>
                    <option value={720}>Fin de mes</option>
                    <option value={744}>1 día despúes del fin de mes</option>
                    <option value={768}>2 días despúes del fin de mes</option>
                    <option value={792}>3 días despúes del fin de mes</option>
                  </WSelect>
                  {!folioSettingsDuedate && (
                    <div className="error">Seleccione una opción</div>
                  )}
                </div>
                <div className="w-full">
                  <input
                    className="hidden"
                    type="file"
                    id="logoInput"
                    onChange={handleLogoChange}
                    ref={fileInputRef}
                  />
                  <WFile
                    className="flex wfacturas-input items-center cursor-pointer truncate"
                    onClick={handleClickUpload}
                    value={
                      logo
                        ? logo.name
                        : pictureKey
                          ? pictureKey.split("/")[1]
                          : ""
                    }
                    label="Logotipo"
                  />
                </div>
                <div className={`w-full ${edit ? "" : "text-gray-400"}`}>
                  <WSelect
                    disabled={!edit}
                    name="selfinvoice_serie"
                    className="absolute top-0 left-0 wfacturas-input appearance-none h-10"
                    ref={register}
                    label="Serie de autofactura"
                    defaultValue={selfInvActiveSerie}
                  >
                    <option value={-1}>F</option>
                    {series.map((serie) => (
                      <option value={serie.id}>{serie.serie_id}</option>
                    ))}
                  </WSelect>
                </div>
                <div className={`w-full ${edit ? "" : "text-gray-400"}`}>
                  <input
                    disabled={!edit}
                    className="mr-4"
                    type="checkbox"
                    name="enable_pmethod_select"
                    ref={register}
                    defaultChecked={enablePmethodSelect}
                  />
                  Habilitar selección de método de pago
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/4 mt-8 md:mt-0"></div>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-start">
            <div className="w-full">
              <label className="text-2xl mb-4 font-semibold text-green-800 mt-8 block">
                Ajustes generales de facturación
              </label>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-8">
                <div className={`w-full ${edit ? "" : "text-gray-400"}`}>
                  <div>
                    <input
                      disabled={!edit}
                      className="mr-4"
                      name="checkbox_general_desc"
                      type="checkbox"
                      onChange={() => setShowGeneralDesc((prev) => !prev)}
                      ref={register}
                      defaultChecked={showGeneralDesc}
                    />
                    Habilitar descripción general en admin
                  </div>
                  <div>
                    <input
                      disabled={!edit}
                      className="mr-4"
                      name="checkbox_selfinv_general_desc"
                      type="checkbox"
                      ref={register}
                      defaultChecked={enableSelfInvoiceGeneralDesc}
                    />
                    Habilitar descripción general en autofactura
                  </div>
                </div>
                {showGeneralDesc && (
                  <div className="w-full">
                    <WInput
                      disabled={!edit}
                      name="general_description"
                      ref={register}
                      label="Descripción general"
                      defaultValue={generalDesc}
                    />
                  </div>
                )}
              </div>
              {showGeneralDesc && (
                <>
                  <label className="text-2xl mb-4 font-semibold text-green-800 mt-8 block">
                    Sincronización de cheques
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-8">
                    <div className="w-full flex gap-4">
                      <WSelect
                        disabled={!edit && isDisabled}
                        name="period"
                        label="Seleccione periodo"
                        ref={selectRef}
                        onChange={(val) =>
                          setPeriodData((prevData) => ({
                            ...prevData,
                            month: val,
                          }))
                        }
                      >
                        <option value={"0"}>Seleccionar</option>
                        <option value={"01"}>Enero</option>
                        <option value={"02"}>Febrero</option>
                        <option value={"03"}>Marzo</option>
                        <option value={"04"}>Abril</option>
                        <option value={"05"}>Mayo</option>
                        <option value={"06"}>Junio</option>
                        <option value={"07"}>Julio</option>
                        <option value={"08"}>Agosto</option>
                        <option value={"09"}>Septiembre</option>
                        <option value={"10"}>Octubre</option>
                        <option value={"11"}>Noviembre</option>
                        <option value={"12"}>Diciembre</option>
                      </WSelect>
                      <WSelect
                        disabled={!edit}
                        name="year"
                        label="Seleccione año"
                        ref={selectRef}
                        onChange={(val) =>
                          setPeriodData((prevData) => ({
                            ...prevData,
                            year: val,
                          }))
                        }
                      >
                        <option value={0}>Seleccionar</option>
                        <option value={year}>{year}</option>
                        <option value={prevYear}>{prevYear}</option>
                      </WSelect>
                    </div>
                    <div className="w-full flex justify-center items-center">
                      {!isDisabled ? (
                        <div
                          disabled={!edit && !isDisabled}
                          className={`wfacturas-button ${!edit && !isDisabled ? "pointer-events-none opacity-50" : "cursor-pointer"}`}
                          onClick={
                            !edit && !isDisabled ? null : handleSyncClick
                          }
                        >
                          Sincronizar folios
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center">
                          <h4>
                            Debe esperar para poder realizar otra sincronización
                          </h4>
                          <span className="flex">
                            Tiempo restante:
                            <div className="mx-2 text-red-600 font-semibold">
                              {formatCountdown(countdown)}
                            </div>{" "}
                            minutos
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="w-full md:w-1/4 mt-8 md:mt-0"></div>
          </div>
        </>
      )}
    </form>
  );
};

export default SettingsForm;
