import React, { useState } from "react";
import "./NotificationWrapper.scss";

const NotificationWrapper = ({ notificationAlertCount }) => {
  return (
    <div
      className={
        notificationAlertCount > 0 ? "notification__badge" : "no_notifications"
      }
    >
      {notificationAlertCount}
    </div>
  );
};

export default NotificationWrapper;
