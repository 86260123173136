import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useTranslation } from "react-i18next";
import LoadingIndicator from "../../../components/LoadingIndicator";
import RegimenSelect from "../../../components/account/RegimenSelect";
import PaymentMethodSelect from "../../../components/account/PaymentMethodSelect";
import ItemsPreview from "../../../components/account/ItemsPreview";
import { createFacturaFromLanding } from "../../../api/User";
import WInput from "../../../components/WInput";
import WSelect from "../../../components/WSelect";
import CfdiSelect from "../../../components/account/CfdiSelect";

const CreateNewFacturaForm = ({
  cheque,
  restId,
  success,
  setSuccess,
  blockPmethod,
}) => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    name: yup.string().optional(),
    email: yup.string().required(),
    street: yup.string().optional(),
    exterior: yup.string().optional(),
    zip: yup.string().required(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    legal_name: yup
      .string()
      .required("Este campo es requerido")
      .test(
        "legalname-err",
        "El nombre no debe incluir el régimen societario (ej. 'S.A. de C.V.')",
        (value) => {
          if (!value) return false;

          const upperCaseValue = value.toUpperCase();
          const forbiddenTerms = ["S.A. DE C.V.", "DE C.V.", "DE CV"];

          // for (let term of forbiddenTerms) {
          //   if (upperCaseValue.includes(term)) {
          //     return false;
          //   }
          // }

          return true;
        }
      )
      .test("special-chars", "El nombre no debe incluir acentos", (value) => {
        if (!value) return false;

        // const accentPattern = /[áéíóúÁÉÍÓÚàèìòùÀÈÌÒÙñÑ]/;
        // if (accentPattern.test(value)) {
        //   return false;
        // }

        return true;
      }),
    folio: yup.number().optional(),
    amount: yup.number().optional(),
    use: yup
      .string()
      .required()
      .test((value) => value !== "no"),
    payment_form: yup
      .string()
      .optional()
      .test((value) => {
        if (!value) {
          value = cheque[0].paymentMethod;
        }
        return value !== "no";
      }),
    tax_system: yup
      .string()
      .required()
      .test((value) => value !== "no"),
    tax_id: yup.string().required().min(12).max(13),
  });

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setLoading(true);
    const newCheque = cheque[0];
    values.payment_form = values.payment_form
      ? values.payment_form
      : cheque[0].paymentMethod;
    values.amount = cheque[0].totalsinpropina;
    values.folio = newCheque.folio;
    values.rest_id = restId;
    const newValues = { ...values, cheque: newCheque };
    try {
      await createFacturaFromLanding(newValues);
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      setLoading(false);
      setSuccess(false);

      window.alert(`No se pudo completar la solicitud. ${error}`);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {!success && (
        <>
          <span>
            <label className="subtitle-text">Datos fiscales</label>
            <div className="input-wrapper mt-4 flex-1 ">
              <input
                type="text"
                ref={register}
                className="landing-input uppercase"
                name="legal_name"
                placeholder="Nombre o razón social"
              />
              <div className="error">{errors.legal_name?.message}</div>
            </div>
            <div className="input-wrapper mt-4 flex-1 ">
              <input
                type="text"
                ref={register}
                className="landing-input uppercase"
                name="tax_id"
                placeholder="RFC"
              />
              {errors.tax_id && <div className="error">Requerido</div>}
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="input-wrapper mt-4 flex-1 mr-0 md:mr-4">
                <input
                  type="text"
                  ref={register}
                  className="landing-input"
                  name="email"
                  placeholder="Correo"
                />
                {errors.email && <div className="error">Requerido</div>}
              </div>
              <div className="input-wrapper mt-4 flex-1 ">
                <input
                  type="text"
                  ref={register}
                  className="landing-input"
                  name="zip"
                  placeholder="Código postal"
                />
                {errors.zip && <div className="error">Requerido</div>}
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="input-wrapper mt-4 flex-1 mr-0 md:mr-4">
                <input
                  type="text"
                  ref={register}
                  className="landing-input"
                  name="street"
                  placeholder="Calle"
                />
              </div>
              <div className="input-wrapper mt-4 flex-1">
                <input
                  type="text"
                  ref={register}
                  className="landing-input"
                  name="exterior"
                  placeholder="Número"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row">
              <div className="input-wrapper mt-4 flex-1 mr-0 md:mr-4">
                <input
                  type="text"
                  ref={register}
                  className="landing-input"
                  name="city"
                  placeholder="Ciudad"
                />
                {errors.tax_id && <div className="error">Requerido</div>}
              </div>
              <div className="input-wrapper mt-4 flex-1 ">
                <input
                  type="text"
                  ref={register}
                  className="landing-input"
                  name="state"
                  placeholder="Estado"
                />
              </div>
            </div>
          </span>
          <div className="input-wrapper my-4">
            <div className="input-wrapper mt-4 flex-1">
              <RegimenSelect
                system="both"
                name="tax_system"
                register={register}
                type="landing"
              />
            </div>
            {errors.tax_system && <div className="error">Requerido</div>}
          </div>
          <label className="subtitle-text">Datos del consumo</label>
          <div className="flex">
            <div className="input-wrapper mt-4 flex-1 mr-4">
              <WInput
                disabled
                type="landing-input"
                ref={register}
                label="Folio"
                name="folio"
                defaultValue={cheque[0].numcheque}
              />
              {errors.rest_id && <div className="error">Folio invalido</div>}
            </div>
            <div className="input-wrapper mt-4 flex-1">
              <WInput
                disabled
                type="currency-landing"
                ref={register}
                name="amount"
                label="Monto"
                defaultValue={cheque[0].totalsinpropina}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row mb-4">
            <div className="input-wrapper mt-4 mr-0 md:mr-4 flex-1">
              <CfdiSelect name="use" register={register} type="landing" />
              {errors.use && <div className="error">Requerido</div>}
            </div>
            <div className="input-wrapper mt-4 flex-1">
              <PaymentMethodSelect
                removeArrow={blockPmethod}
                isDisabled={
                  cheque[0].paymentMethod != "no" ? blockPmethod : false
                }
                name="payment_form"
                register={register}
                defaultValue={cheque[0].paymentMethod}
                type="landing"
              />
              {errors.payment_form && <div className="error">Requerido</div>}
            </div>
          </div>
        </>
      )}
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ItemsPreview
            cheques={cheque}
            total={cheque[0].totalsinpropina}
            subtotal={0}
          />
          <button
            className="wfacturas-button-landing w-full mt-16"
            type="submit"
          >
            Generar factura
          </button>
        </>
      )}
    </form>
  );
};

export default CreateNewFacturaForm;
