import React from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import "./PoliticasdePrivacidad.scss";
import { useTranslation } from "react-i18next";

const PoliticasdePrivacidad = ({ children }) => {
  const { t } = useTranslation();
  return (
    <LayoutV2 title="Inicia sesión" className="mx:px-0 px-4">
      <div className="politics-main-container">
        <div className="politics-form-card">
          <h2 className="politics-title">{t("footer.privacy")}</h2>
          <br />
          <h5 className="politics-text">{t("privPol.policy1")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy2")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy3")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy4")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy5")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy6")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy7")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy8")}</h5>
          <br />
          <h5 className="politics-text">{t("privPol.policy9")}</h5>
        </div>
      </div>
    </LayoutV2>
  );
};

export default PoliticasdePrivacidad;
