import React, { useState } from "react";

// Import icons
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

// Import modal component.
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "32rem",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const EditFacturapiDataModal = ({
  isOpen,
  onRequestClose,
  restId,
  editFacturapiKeys,
  editFacturapiPlan,
  facturapiData,
}) => {
  const { t } = useTranslation();
  const today = new Date().toISOString();
  const [filteredData] = facturapiData.filter((data) => data.id === restId);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editInputs, setEditInputs] = useState(false);
  const [editedFacturapiId, setEditedFacturapiId] = useState(
    filteredData.facturapi_id
  );
  const [editedFacturapiKey, setEditedFacturapiKey] = useState(
    filteredData.facturapi_key
  );
  const [editedWfacturasUpdated, setEditedWfacturasUpdated] = useState(
    filteredData.wfacturas_updated
  );
  const [editedWfacturasDuedate, setEditedWfacturasDuedate] = useState(
    filteredData.wfacturas_duedate
  );
  const [editedWfacturasBells, setEditedWfacturasBells] = useState(
    filteredData.wfacturas_bells
  );

  const handleSaveChanges = () => {
    if (
      editedFacturapiId !== filteredData.facturapi_id ||
      editedFacturapiKey !== filteredData.facturapi_key
    ) {
      setShowConfirmationModal(true);
    } else if (
      editedWfacturasDuedate !== filteredData.wfacturas_duedate ||
      editedWfacturasBells != filteredData.wfacturas_bells
    ) {
      if (window.confirm("Seguro que desea actualizar el plan del cliente?")) {
        handlePlanConfirmation();
      }
    }
  };
  const handleConfirmation = () => {
    editFacturapiKeys(editedFacturapiId, editedFacturapiKey);
    setShowConfirmationModal(false);
    onRequestClose(true);
  };

  const handlePlanConfirmation = () => {
    const newDueDate = editedWfacturasDuedate
      ? new Date(editedWfacturasDuedate).toISOString()
      : new Date().toISOString();
    editFacturapiPlan(today, newDueDate, editedWfacturasBells);
    setShowConfirmationModal(false);
    onRequestClose(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Edit modal"
      className="py-8 wrapper relative tab-rep-resp"
    >
      <div
        className={`px-8 py-6 bg-white border relative ${
          showConfirmationModal ? "opacity-50" : ""
        }`}
      >
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={onRequestClose}
        />
        <h3 className="font-bold text-gray-900 text-xl text-center mb-5">
          Editar datos del restaurante
        </h3>
        <div className="flex flex-col justify-center">
          <p className="text-center">
            Ingrese a facturapi para administrar las llaves de acceso
          </p>
          <a
            href={"https://dashboard.facturapi.io/integration/apikeys"}
            className="text-blue-500 text-center mb-4"
            target="_blank"
          >
            dashboard.facturapi.io
          </a>
        </div>
        <div id="capture" className="flex flex-col items-center pb-2 p-1">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Facturapi organization id
          </label>
          <input
            disabled={!editInputs}
            type="text"
            className="input mb-4 w-full text-center"
            defaultValue={editedFacturapiId}
            placeholder="id de la organización"
            onChange={(e) => setEditedFacturapiId(e.target.value)}
          />
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Live secret key
          </label>
          <input
            disabled={!editInputs}
            type="text"
            className="input mb-4 text-center"
            defaultValue={
              editedFacturapiKey
                ? `${editedFacturapiKey.slice(0, 12)}**********`
                : ""
            }
            placeholder="sk_live_ejemplo*********"
            onChange={(e) => setEditedFacturapiKey(e.target.value)}
          />
          {/* <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            User Secret Key
          </label>
          <input
            disabled={filteredData.facturapi_user_key || !editInputs}
            type="text"
            className="input mb-4  text-center"
            defaultValue={
              filteredData.facturapi_user_key
                ? `${filteredData.facturapi_user_key.slice(0, 12)}***********`
                : ""
            }
            onChange={(e) => setEditedFacturapiUserKey(e.target.value)}
          /> */}
          <h3 className="font-bold text-gray-900 text-xl text-center mb-5 mt-4">
            Plan de timbres
          </h3>
          <div className="flex w-full justify-between">
            <div>
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                Actualizado el
              </label>
              <input
                disabled
                type="date"
                className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg mb-4"
                max={new Date().toISOString().split("T")[0]}
                defaultValue={
                  new Date(
                    editedWfacturasUpdated ? editedWfacturasUpdated : new Date()
                  )
                    .toISOString()
                    .split("T")[0]
                }
              />
            </div>
            <div>
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                Vencen el
              </label>
              <input
                disabled={!editInputs}
                type="date"
                className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg mb-4"
                defaultValue={
                  new Date(
                    editedWfacturasDuedate ? editedWfacturasDuedate : new Date()
                  )
                    .toISOString()
                    .split("T")[0]
                }
                onChange={(e) => setEditedWfacturasDuedate(e.target.value)}
              />
            </div>
          </div>
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Plan
          </label>
          <input
            disabled={!editInputs}
            type="number"
            className="input mb-4  text-center"
            defaultValue={editedWfacturasBells}
            onChange={(e) => setEditedWfacturasBells(e.target.value)}
          />
        </div>
        <div className="flex">
          <button
            disabled={editInputs}
            class={`w-full ${
              editInputs ? "bg-gray-400" : "bg-brand-700"
            } text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline mr-2`}
            onClick={() => setEditInputs(!editInputs)}
          >
            Editar
          </button>
          <button
            disabled={!editInputs}
            class={`w-full ${
              !editInputs ? "bg-gray-400" : "bg-brand-700"
            } text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline ml-2`}
            onClick={handleSaveChanges}
          >
            Guardar cambios
          </button>
        </div>
        <button
          class={`w-full mt-4 bg-red-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline`}
          onClick={onRequestClose}
        >
          Cancelar
        </button>
      </div>
      <div
        tabindex="-1"
        class={`${
          showConfirmationModal ? "block" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 z-50 w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div class="relative p-6 pl-20 pt-24 w-full max-w-md max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              class="absolute text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="popup-modal"
              onClick={() => setShowConfirmationModal(false)}
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
            <div class="p-4 md:p-5 text-center">
              <svg
                class="mx-auto mb-4 text-gray-700 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 class="mb-5 text-lg font-normal text-gray-700 dark:text-gray-400">
                ¿Seguro que desea actualizar las llaves de usuario? esta acción
                es irreversible. La llave podría ya no ser utilizada para
                autenticar a tu organización en la API
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                onClick={handleConfirmation}
                class="text-white bg-red-600 mr-4 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
              >
                Si, Estoy seguro
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                onClick={() => setShowConfirmationModal(false)}
                class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditFacturapiDataModal;
