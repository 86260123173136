import React from "react";

import SEO from "react-seo-component";
import WhatsappButton from "./WhatsappButton";
import LanguageButton from "./LanguageButton";

const WFacturasLayout = ({
  children,
  className,
  title,
  description,
  image,
  fullWidth,
  admin,
  distributor,
}) => {
  return (
    <>
      {/* <WFacturasNavbar admin={admin} distributor={distributor} /> */}
      <div
        className={`${
          !fullWidth ? "md:max-w-6xl max-w-full mx-auto" : ""
        } ${className}`}
      >
        <SEO
          title={title}
          titleTemplate={"Wmobil"}
          titleSeparator={`-`}
          description={description || ""}
          image={image || ""}
          pathname={"localhost:3000"}
          siteLanguage={"es-MX"}
          siteLocale={"es-MX"}
        />
        <div className="content">{children}</div>
      </div>
      <LanguageButton />
      <WhatsappButton />
    </>
  );
};

export default WFacturasLayout;
