import axios from "axios";
import { API_PATH } from "../constants/api";

export const getTable = (restaurant_id, table_id) => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/tables/${table_id}/restaurant/${restaurant_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.table;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
