import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//import ErrorComponent from "../ErrorComponent";
import StatesSelect from "../../components/account/StatesSelect.js";
import { updateNameRestaurant } from "../../api/User";

import LoadingIndicator from "../../components/LoadingIndicator.js";
import "./SwitchRestaurantName.css";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  name: yup.string().optional(),
});

const SwitchRestaurantName = ({ refreshRestaurant, restaurant }) => {
  const { t } = useTranslation();
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [userData, setUserData] = useState({});

  // Instantiate form
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    //defaultValues: userData,
  });

  const onSubmit = (values) => {
    setLoading(true);
    setSuccess(false);
    var queryData = {
      newtext: values.name,
      database_string: restaurant.database_string,
      user_id: restaurant.user_id,
      license_id: restaurant.license_id,
      text: restaurant.text,
    };
    updateNameRestaurant(queryData)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        setError(null);
        refreshRestaurant();
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        setSuccess(false);
        console.log(error);
      });
  };

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <label className="text-lg font-bold text-gray-900 leading-4 mt-4 block">
        {t("dashboard.general")}
      </label>
      <div className="flex">
        <div className="input-wrapper mt-4 flex-1 mr-4 input-wrapper-resp">
          <input
            type="text"
            ref={register}
            className="input"
            name="name"
            defaultValue={restaurant.text}
            //value={restaurant.text}
          ></input>
        </div>

        <button
          className="button-main smallButton button-main-resp"
          type="submit"
        >
          {t("restPage.rename")}
        </button>
      </div>
    </form>
  );
};

export default SwitchRestaurantName;
