import axios from "axios";
import { API_PATH } from "../constants/api";

import jwt_decode from "jwt-decode";
import { MdAllInclusive } from "react-icons/md";

export const getUserData = () => {
  const token = localStorage.getItem("token");

  return jwt_decode(token);
};

export const updateData = (data) => {
  const token = localStorage.getItem("token");

  return axios
    .patch(`${API_PATH}/users`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("token", response.data.token);
      window.dispatchEvent(new Event("storage"));

      return response.data.token;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateNameRestaurant = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .patch(`${API_PATH}/users/userNameRestaurant`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const registerMethod = (paymentMethodId) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/methods`,
      {
        payment_method_id: paymentMethodId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const generateMailCode = (usr_email) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/users/generateMailCode`,
      {
        email: usr_email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const generateMailCodePassword = (usr_email) => {
  return axios
    .post(`${API_PATH}/users/generateMailCodePassword`, {
      email: usr_email,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const verifiedCodePassword = (code) => {
  return axios
    .post(`${API_PATH}/users/verifiedCodePassword`, {
      code: code,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePassword = (password, code) => {
  return axios
    .post(`${API_PATH}/users/updatePassword`, {
      password: password,
      code: code,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const setUserVerified = (usr_email, mailCode) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/users/setUserVerified`,
      {
        email: usr_email,
        code: mailCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateMailCode = (usr_email, mailCode) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/users/validateMailCode`,
      {
        email: usr_email,
        code: mailCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
export const isUserVerified = (usr_email) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/users/isUserVerified`,
      {
        email: usr_email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const createCard = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/methods/card/new`,
      {
        customerId: data.customerId,
        tokenId: data.tokenId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

export const updateSubscription = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/methods/subscription`,
      {
        customerId: data.customerId,
        tokenId: data.tokenId,
        plan: data.plan,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getPaymentMethods = (customer_id) => {
  const token = localStorage.getItem("token");
  const customerId = customer_id;

  return axios
    .post(
      `${API_PATH}/methods/cardlist`,
      { customerId: customerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data.map((method) => {
        return {
          stripe_id: method.id,
          brand: method.brand,
          last4: method.last4,
          name: method.name,
          isDefault: method.default,
        };
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const getPaymentMethodsNoAuth = (customer_id) => {
  const customerId = customer_id;

  return axios
    .post(`${API_PATH}/methods/cardlist/no-auth`, { customerId: customerId })
    .then((response) => {
      return response.data.map((method) => {
        return {
          stripe_id: method.id,
          brand: method.brand,
          last4: method.last4,
          name: method.name,
          isDefault: method.default,
        };
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const validateCoupon = (coupon) => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/restaurants/coupons/${coupon}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.coupon;
    })
    .catch((error) => {
      throw error;
    });
};

export const createRestaurantSubscription = ({
  title,
  method,
  plan,
  card_token,
  coupon,
  database_string,
  license_id,
}) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/restaurants`,
      {
        name: title,
        method: card_token,
        price: plan,
        coupon,
        database_string,
        license_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFacturacionSubscription = ({
  card_token,
  rest_id,
  selected_plan,
  last_four,
  price,
  periodicity,
}) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/facturacion/plans/create`,
      {
        card_token,
        rest_id,
        selected_plan,
        last_four,
        price,
        periodicity,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const cancelFacturacionSubscription = ({ rest_id }) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/facturacion/plans/cancel`,
      {
        rest_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createRestaurantSubscriptionPaypal = (data) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/restaurants/paypalsub`,
      {
        name: data.name,
        plan: data.plan,
        created_at: data.created_at,
        final_date: data.final_date,
        database_string: data.database_string,
        license_id: data.license_id,
        paypal_id: data.paypal_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const createRestaurantSubscriptionOxxoSpei = ({
  title,
  method,
  plan,
  card_token,
  coupon,
  database_string,
  license_id,
}) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/restaurants/oxxospei`,
      {
        name: title,
        method: card_token,
        price: plan,
        coupon,
        database_string,
        license_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const newOrder = ({ title, customer_id, plan, card_token }) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/restaurants/newOrderOxxoSpei`,
      {
        name: title,
        method: card_token,
        price: plan,
        customer_id: customer_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const RenewSubscriptionPaypal = (data) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/restaurants/renewpaypalsub`,
      {
        plan: data.plan,
        created_at: data.created_at,
        final_date: data.final_date,
        restaurant_id: data.restaurant_id,
        paypal_id: data.paypal_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLicenses = () => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/users/licenses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubscription = (id) => {
  const token = localStorage.getItem("token");
  return axios
    .get(`${API_PATH}/users/licenses/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.subscription;
    })
    .catch((error) => {
      throw error;
    });
};

export const getStripePortalLink = (id) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/users/licenses/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const cancelSubscription = (id) => {
  const token = localStorage.getItem("token");
  return axios
    .post(
      `${API_PATH}/methods/del/subscription/`,
      { customerId: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDistributor = (id) => {
  return axios
    .get(`${API_PATH}/users/distributors/${id}`)
    .then((response) => {
      return response.data.distributor;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFacturapiClient = (data) => {
  const token = localStorage.getItem("token");

  return axios
    .post(`${API_PATH}/facturacion/newuser`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFacturaUser = (rest_id) => {
  const token = localStorage.getItem("token");

  return axios
    .get(`${API_PATH}/facturacion/users/${rest_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data.userData;
    })
    .catch((error) => {
      throw error;
    });
};

export const createFactura = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(`${API_PATH}/facturacion/create_invoice`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const message = error.response?.data?.error;
      throw message ? message : error;
    });
};

export const createFacturaFromLanding = (data) => {
  const token = localStorage.getItem("token");
  return axios
    .post(`${API_PATH}/facturacion/create_invoice_landing`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const message = error.response?.data?.error;
      throw message ? message : error;
    });
};

export const searchFolio = (restId, folios, includeTips, dateRange) => {
  const token = localStorage.getItem("token");

  return axios
    .post(
      `${API_PATH}/facturacion/restaurant/${restId}?tip=${includeTips}`,
      { folios, dateRange },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFacturas = (id, page, limit) => {
  const token = localStorage.getItem("token");
  const queryParams = new URLSearchParams({
    page,
    limit,
  });
  return axios
    .get(`${API_PATH}/facturacion/invoices/${id}?${queryParams.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getFacturasByDate = (id, startDate, endDate) => {
  const token = localStorage.getItem("token");

  return axios
    .get(
      `${API_PATH}/facturacion/invoices/${id}?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const newRestaurantUser = async (user, name, days) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_PATH}/users/newRestaurantUser`,
      {
        user: user,
        name: name,
        days: days,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getProfilePicKey = async (restId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(`${API_PATH}/get_pic_key/${restId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.key;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getInvoiceData = async (invoiceId, restId) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${API_PATH}/facturacion/${restId}/get_invoice/${invoiceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.invoice;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const newPayComplement = async (id, data) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_PATH}/facturacion/${id}/complement`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
