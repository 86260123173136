import React, { useState } from "react";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "./LandingPage.scss";
import SwiperCore, { Mousewheel, Pagination, Autoplay } from "swiper";
import { useEffect } from "react";
import { number } from "yup";
import { useHistory } from "react-router-dom";
import { SIGNUP_PATH } from "../../../constants/paths";
import { useTranslation } from "react-i18next";

const VisaLogo = require("../../../images/landing/visa-logo.svg");
const AMEXLogo = require("../../../images/landing/american-logo.svg");
const MasterCardLogo = require("../../../images/landing/mastercard-logo.svg");
const StripeLogo = require("../../../images/landing/stripe-logo.svg");
const SPEILogo = require("../../../images/landing/spei-logo.svg");
const OXXOLogo = require("../../../images/landing/visa-logo.svg");
const PayPalLogo = require("../../../images/landing/paypal-logo.svg");

export function LandingPage(props) {
  const { t } = useTranslation();

  const [restaurantes, setRestaurantes] = useState(
    props.type === "restaurantes" ? true : false
  );
  const [email, setEmail] = useState("");
  const history = useHistory();

  const [licensesValue, setLicensesValue] = useState(0);
  const [calculador, setCalculador] = useState({
    subtotalB: 0,
    IVA: 0,
    Comision: 0,
    ClienteDepos: 0,
  });

  const [loading, setLoading] = useState(false);

  const register = () => {
    history.push({ pathname: SIGNUP_PATH, state: { email: email } });
  };

  SwiperCore.use([Mousewheel, Pagination, Autoplay]);
  const LogosArray = [
    VisaLogo,
    AMEXLogo,
    MasterCardLogo,
    StripeLogo,
    SPEILogo,
    OXXOLogo,
    PayPalLogo,
  ];

  const subscriptionBenefits = [
    t("landingPage.cardTitle2Content1"),
    t("landingPage.cardTitle2Content2"),
    t("landingPage.cardTitle2Content3"),
    t("landingPage.cardTitle2Content4"),
    t("landingPage.cardTitle2Content5"),
    t("landingPage.cardTitle2Content6"),
    t("landingPage.cardTitle2Content7"),
    t("landingPage.cardTitle2Content8"),
    t("landingPage.cardTitle2Content9"),
  ];

  const calcular = () => {
    setLoading(true);
    let comision = 0;
    let clientedepos = 0;
    let subtotal = licensesValue * 349;
    let iva = ((subtotal * 100) / 116 - subtotal) * -1;
    if (licensesValue > 50) {
      comision = ((subtotal * 100) / 116) * 0.5;
      clientedepos = ((subtotal * 100) / 116) * 0.5;
    } else {
      comision = ((subtotal * 100) / 116) * 0.75;
      clientedepos = ((subtotal * 100) / 116) * 0.25;
    }

    setCalculador({
      subtotalB: subtotal,
      IVA: iva,
      Comision: comision,
      ClienteDepos: clientedepos,
    });

    setLoading(false);
  };

  const formatoComas = (number) => {
    const exp = /(\d)(?=(\d{3})+(?!\d))/g;
    const rep = "$1,";
    let arr = number.toString().split(".");
    arr[0] = arr[0].replace(exp, rep);
    return arr[1] ? arr.join(".") : arr[0];
  };

  useEffect(() => {
    setRestaurantes(props.type === "restaurantes" ? true : false);
  }, [props.type]);

  return (
    <LayoutV2 location={props.location} type={props.type} title="Inicio">
      <div
        className={
          "landing-main-component " + (!restaurantes ? "distributor" : "")
        }
      >
        <div className="top-section">
          <img
            className="background"
            src={require("../../../images/landing/Mask group.png")}
            alt="fondo verde"
          />
          <img
            className="vector-landing-top"
            src={require("../../../images/landing/Vector-landing.png")}
            alt="curvatura"
          />
          <div className={"content"}>
            <div className="container image-laptop-cellphone">
              <img
                className="laptop-cellphone"
                src={require(restaurantes
                  ? "../../../images/landing/computer-cellphone.png"
                  : "../../../images/landing/laptops-image.png")}
                alt="laptop y celular con wmobil"
              />
            </div>
            <div className="container">
              <div className="row">
                <h2>
                  {restaurantes
                    ? t("landingPage.title")
                    : t("landingPage.titleDist")}
                </h2>
              </div>
              <div className="row">
                <h3>
                  {restaurantes
                    ? t("landingPage.subtitle")
                    : t("landingPage.subtitleDist")}
                </h3>
              </div>
              <div className="row">
                <p>{t("landingPage.subtitle2")}</p>
              </div>
              <div className="row">
                <img
                  className="softrestaurant"
                  src={require("../../../images/landing/softrestaurant.svg")}
                  alt="softrestaurant"
                />
              </div>
              <div className="row form-main-container">
                <label htmlFor="email">{t("landingPage.subtitle3")}</label>
                <div className="form-container">
                  <div className="input-email-container">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder={t("landingPage.email")}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <button onClick={register}>
                    {t("landingPage.registerBtn")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="second-section">
          <div className="row">
            <h2>
              {restaurantes
                ? t("landingPage.title2")
                : t("landingPage.titleDist2")}
            </h2>
          </div>
          {restaurantes && (
            <div className="row">
              <h3>{t("landingPage.par1")}</h3>
            </div>
          )}
          <div className="row info-row">
            <div className="container">
              <p>
                {t("landingPage.par2P1")}
                <b>SoftRestaurant</b> {t("landingPage.par2P2")}
              </p>
              <div className="division"></div>
              <img
                className="softrestaurant"
                src={require("../../../images/landing/softrestauran2.svg")}
                alt="softrestaurant"
              />
            </div>
            <Swiper
              loop={false}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="mySwiper"
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide className="w-100">
                {restaurantes ? (
                  <div className="swiper-container">
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/calendar-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderT1")}</h3>
                      <p>{t("landingPage.sliderSt1")}</p>
                    </div>
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/payment-methods-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderT2")}</h3>
                      <p>{t("landingPage.sliderSt2")}</p>
                    </div>
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/withdraw-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderT3")}</h3>
                      <p>{t("landingPage.sliderSt3")}</p>
                    </div>
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/wallet-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderT4")}</h3>
                      <p>{t("landingPage.sliderSt4")}</p>
                    </div>
                  </div>
                ) : (
                  <div className="swiper-container">
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/chart-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderDistT1")}</h3>
                      <p>{t("landingPage.sliderDistSt1")}</p>
                    </div>
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/withdraw-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderDistT2")}</h3>
                      <p>{t("landingPage.sliderDistSt2")}</p>
                    </div>
                    <div className="item">
                      <img
                        className="softrestaurant"
                        src={require("../../../images/landing/wallet-icon.svg")}
                        alt="softrestaurant"
                      />
                      <h3>{t("landingPage.sliderT4")}</h3>
                      <p>{t("landingPage.sliderSt4")}</p>
                    </div>
                  </div>
                )}
              </SwiperSlide>
              {/* <SwiperSlide>Slide 2</SwiperSlide> */}
            </Swiper>
          </div>
        </div>
        <div className="third-section">
          {restaurantes ? (
            <>
              <h3>{t("landingPage.title3")}</h3>
              <div className="images-container">
                {LogosArray.map((image, index) => {
                  return (
                    <img
                      src={image}
                      key={"imagen de metodos de pago - " + index}
                      alt="logo de metodo de pago"
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="content-container">
              <div className="row">
                <h3>{t("landingPage.title6Dist")}</h3>
              </div>
              <div className="row input-row">
                <div className="label-container">
                  <label htmlFor="licenses">{t("landingPage.inputDist")}</label>
                  <div className="fill"></div>
                </div>
                <div className="form-container">
                  <div className="input-container">
                    <input
                      type="number"
                      name="licenses"
                      id="licenses"
                      value={licensesValue}
                      onChange={(e) => {
                        if (e.target.value.length > 4 || e.target.value < 0)
                          return;
                        setLicensesValue(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    onClick={() => {
                      calcular();
                    }}
                  >
                    {t("landingPage.calculateDist")}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="text-container bruto">
                  <h4>{t("landingPage.grossDist")}</h4>
                  {!loading ? (
                    <p>
                      {calculador.subtotalB === 0 ? (
                        <>
                          $<span>0.00</span>
                        </>
                      ) : (
                        <>
                          $
                          <span>
                            {formatoComas(calculador.subtotalB.toFixed(2))}
                          </span>
                        </>
                      )}
                    </p>
                  ) : (
                    <p>Cargando</p>
                  )}
                </div>
                <div className="text-container iva">
                  <h4>{t("landingPage.taxDist")}</h4>
                  {!loading ? (
                    <p>
                      {calculador.IVA === 0 ? (
                        <>
                          $<span>0.00</span>
                        </>
                      ) : (
                        <>
                          $
                          <span>{formatoComas(calculador.IVA.toFixed(2))}</span>
                        </>
                      )}
                    </p>
                  ) : (
                    <p>Cargando</p>
                  )}
                </div>
                <div className="text-container comision">
                  <h4>{t("landingPage.comDist")}</h4>
                  {!loading ? (
                    <p>
                      {calculador.Comision === 0 ? (
                        <>
                          $<span>0.00</span>
                        </>
                      ) : (
                        <>
                          $
                          <span>
                            {formatoComas(calculador.Comision.toFixed(2))}
                          </span>
                        </>
                      )}
                    </p>
                  ) : (
                    <p>Cargando</p>
                  )}
                </div>
              </div>
              <div className="row withdraw-container">
                <div className="comision-percentage">
                  <h4>{t("landingPage.comPreDist")}</h4>
                  <p>75% + IVA</p>
                </div>
                <div className="total-withdraw">
                  <h4>{t("landingPage.previewDist")}</h4>
                  {!loading ? (
                    <p>
                      {calculador.ClienteDepos === 0 ? (
                        <>
                          $<span>0.00</span>
                        </>
                      ) : (
                        <>
                          $
                          <span>
                            {formatoComas(calculador.ClienteDepos.toFixed(2))}
                          </span>
                        </>
                      )}
                    </p>
                  ) : (
                    <p>Cargando</p>
                  )}
                </div>
              </div>
              <div className="row last">
                <p>{t("landingPage.messageDist")}</p>
              </div>
            </div>
          )}
        </div>
        <div className="fourth-section">
          {restaurantes ? (
            <>
              <img
                className="iphone iphone1"
                src={require("../../../images/landing/iphone2.png")}
                alt="iphone con wmobil"
              />
              <img
                className="iphone iphone2"
                src={require("../../../images/landing/iphone1.png")}
                alt="iphone con wmobil"
              />
            </>
          ) : (
            <img
              className="imac"
              src={require("../../../images/landing/imac-wmobil.png")}
              alt="imac con wmobil"
            />
          )}
          <img
            className="squircle squircle-1"
            src={require("../../../images/landing/squircles/squircle-1.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-2"
            src={require("../../../images/landing/squircles/squircle-2.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-3"
            src={require("../../../images/landing/squircles/squircle-3.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-4"
            src={require("../../../images/landing/squircles/squircle-4.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-5"
            src={require("../../../images/landing/squircles/squircle-5.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-6"
            src={require("../../../images/landing/squircles/squircle-6.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-7"
            src={require("../../../images/landing/squircles/squircle-7.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-8"
            src={require("../../../images/landing/squircles/squircle-8.svg")}
            alt="squircle"
          />
          <img
            className="squircle squircle-9"
            src={require("../../../images/landing/squircles/squircle-9.svg")}
            alt="squircle"
          />
          <div className="info-card">
            <Swiper
              loop={true}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="mySwiper"
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide className="w-100">
                <div className="info-content">
                  <div className="row">
                    <h3>
                      {restaurantes
                        ? t("landingPage.title4")
                        : t("landingPage.titleDist4")}
                    </h3>
                  </div>
                  <div className="row">
                    <h4>WMobil App</h4>
                  </div>
                  <div className="row">
                    <p>
                      {t("landingPage.par3P1")}
                      <br />
                      <br />
                      {t("landingPage.par3P2")}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              {/* <SwiperSlide>Slide 2</SwiperSlide> */}
            </Swiper>
          </div>
          <div className="subscriptions-cards-container">
            <div className="secondary left">
              <h3>{t("landingPage.cardTitle1")}</h3>
              <p className="price">
                $<span>1,919</span>.00
              </p>
            </div>
            <div className="primary">
              <h3>{t("landingPage.cardTitle2")}</h3>
              <p className="price">
                $<span>3,699</span>.00
              </p>
              <div className="benefits-container">
                {props.type === "restaurantes" &&
                  subscriptionBenefits.map((benefit, index) => {
                    return (
                      <div className="benefit-row" key={"benefit-" + index}>
                        <img
                          src={require("../../../images/landing/checkmark.svg")}
                          alt=""
                        />
                        <p>{benefit}</p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="secondary right">
              <h3>{t("landingPage.cardTitle3")}</h3>
              <p className="price">
                $<span>349</span>.00
              </p>
            </div>
          </div>
        </div>
        <img
          className="fourth-section-footer"
          src={require("../../../images/landing/vector-section-fourth.svg")}
          alt=""
        />
        <div className="fifth-section">
          {restaurantes ? (
            <>
              <img
                className="dashboard dashboard1"
                src={require("../../../images/landing/dashboard1.png")}
                alt="imagen de una pantalla del dashboard de wmobil"
              />
              <img
                className="dashboard dashboard2"
                src={require("../../../images/landing/dashboard2.png")}
                alt="imagen del login de wmobil"
              />
            </>
          ) : (
            <>
              <img
                className="screenshot screenshot-desktop-payment"
                src={require("../../../images/landing/desktop-screenshot-payment.png")}
                alt="imagen de una pantalla de la pantalla de pago de wmobil escritorio"
              />
              <img
                className="screenshot mobile app-screenshot1"
                src={require("../../../images/landing/app-screenshot.png")}
                alt="imagen de la app movil de wmobil"
              />
              <img
                className="screenshot mobile app-screenshot2"
                src={require("../../../images/landing/app-screenshot2.png")}
                alt="imagen de la app movil de wmobil"
              />
            </>
          )}
          <div className="content">
            <div className="title">
              <h2>
                {restaurantes
                  ? t("landingPage.title5")
                  : t("landingPage.title5Dist")}
              </h2>
            </div>
            <div className="paragraph">
              {restaurantes ? (
                <p>
                  {t("landingPage.par4P1")}
                  <br />
                  <br />
                  {t("landingPage.par4P2")}
                </p>
              ) : (
                <p>
                  {t("landingPage.par4DistP1")}
                  <br />
                  <br />
                  {t("landingPage.par4DistP2")}
                </p>
              )}
            </div>
            {/* <div className="footer">
              <button>
                Ir a la página de{" "}
                {restaurantes ? "Distribuidores" : "Restaurantes"}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
