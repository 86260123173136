import React, { useEffect, useState } from "react";

// Import modal component.
import Modal from "react-modal";
import "./WFacturasActions.scss";
import iconInfoId from "../../images/restIdIconInfo.png";
import iconFolio from "../../images/folioInfo.jpeg";
import amountInfo from "../../images/amountInfo.jpeg";
import ticketDate from "../../images/ticketDate.png";
// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const closeArrow = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 15L15 5M5 5L15 15"
      stroke="#3F2E04"
      stroke-width="1.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const WFacturasInfoModal = React.memo(({ type, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      className="wfacturas-modal tab-rep-resp"
    >
      {type === "restId" && (
        <div>
          <div className="flex items-center justify-between w-title-container">
            <h3>Ubica el id restaurante</h3>
            <div className="cursor-pointer" onClick={onRequestClose}>
              {closeArrow}
            </div>
          </div>
          <div className="p-8">
            <img src={iconInfoId} />
          </div>
        </div>
      )}
      {type === "folio" && (
        <div>
          <div className="flex items-center justify-between w-title-container">
            <h3>Ubica el folio</h3>
            <div className="cursor-pointer" onClick={onRequestClose}>
              {closeArrow}
            </div>
          </div>
          <div className="p-8">
            <img src={iconFolio} />
          </div>
        </div>
      )}
      {type === "amount" && (
        <div>
          <div className="flex items-center justify-between w-title-container">
            <h3>Ubica el monto</h3>
            <div className="cursor-pointer" onClick={onRequestClose}>
              {closeArrow}
            </div>
          </div>
          <div className="p-8">
            <img src={amountInfo} />
          </div>
        </div>
      )}
      {type === "date" && (
        <div>
          <div className="flex items-center justify-between w-title-container">
            <h3>Ubica la fecha de expedición</h3>
            <div className="cursor-pointer" onClick={onRequestClose}>
              {closeArrow}
            </div>
          </div>
          <div className="p-8">
            <img src={ticketDate} />
          </div>
        </div>
      )}
    </Modal>
  );
});

export default WFacturasInfoModal;
