import React from "react";
import SigninForm from "../../../components/auth/forms/SigninForm";
import Layout from "../../../components/Layout";
import { useLocation } from "react-router";
import LayoutV2 from "../../../components/LayoutV2/LayoutV2";
import "./SigninPage.scss";
import { useTranslation } from "react-i18next";

const SigninPage = ({ children }) => {
  const { t } = useTranslation();
  return (
    <LayoutV2 title="Inicia sesión" className="mx:px-0 px-4">
      <div className="login-main-container">
        <div className="login-form-card">
          <img
            src={require("../../../images/full-logo.svg")}
            alt="wmobil logo"
          />
          <h2 className="login-title">{t("navBar.signIn")}</h2>
          <SigninForm />
        </div>
      </div>
    </LayoutV2>
  );
};

export default SigninPage;
