import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./Introduction.css";
import { useHistory } from "react-router-dom";
import { NEW_SUBSCRIPTION_PATH } from "../../constants/paths";

const Introduction = () => {
  const history = useHistory();
  return (
    <Layout title="Introducción">
      <div className="wmobil-install-background"></div>
      <div class="introduction-main-container">
        <div class="introduction-card">
          <div class="introduction-card-title-container">
            <h2>¡Cuenta creada!</h2>
          </div>
          <div class="introduction-text-container">
            <p>
              Tu correo ha sido verificado. Ahora para comenzar tu proceso ve
              este video que hicimos para ti como introducción a Wmobil.
            </p>
            <p>
              Hablaremos de todo lo escencial que debes de saber para moverle en
              Wmobil y la increible tecnología que se encuentra detrás del
              sistema.
            </p>
          </div>
          <div class="introduction-video-container">
            <iframe
              width="560"
              frameBorder="0"
              allowFullScreen
              height="315"
              src="https://www.youtube.com/embed/Dx2ZqrhxWwc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="introduction-card-empecemos-btn-container">
            <button onClick={() => history.push(NEW_SUBSCRIPTION_PATH)}>
              Empecemos
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Introduction;
