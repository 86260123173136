import React from "react";
import { useTranslation } from "react-i18next";

export function AdminClientDownloadTool(props) {
  const { t } = useTranslation();
  return (
    <div className="admin-client-download-tool-main-container">
      <label className="text-bg font-bold text-red-800 leading-4 mb-2 block">
        {t("admin.toolsLbl1")}
      </label>
      <a
        target="_blank"
        href="https://drive.google.com/file/d/1Jv6yfw5QYL1HIRJGY2D6zDG438poKRu4/view?usp=drive_link"
        download
      >
        <button type="button" className="button-main">
          {t("admin.toolsBtn1")}
        </button>
      </a>
      <a
        target="_blank"
        href="https://drive.google.com/file/d/1qlTMT9yAMTXKGOTFWLBe4ZWo_yAV8xPq/view?usp=drive_link"
        download
      >
        <button type="button" className="button-main">
          {t("admin.toolsBtn2")}
        </button>
      </a>
    </div>
  );
}
