import React, { useState, useEffect, useRef } from "react";
import { BsTranslate } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const LanguageButton = () => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);
  const modalRef = useRef(null);

  useEffect(() => {
    const closeMenu = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener("click", closeMenu);
    }

    return () => {
      document.removeEventListener("click", closeMenu);
    };
  }, [isModalOpen]);

  const showMenu = (event) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
    setModalOpen(false);
  };

  return (
    <div className="relative">
      <div
        className="bottom-0 right-0 md:p-4 p-3 rounded-full m-4 shadow-md fixed hover-pointer langbtn"
        style={{
          backgroundColor: "#25D366",
          zIndex: "10",
        }}
        target="blank"
        rel="nofollow"
        onClick={showMenu}
      >
        <BsTranslate className="text-white md:w-8 md:h-8 w-6 h-6" />
      </div>
      {isModalOpen && (
        <div>
          <ul
            className="z-20 bg-white py-2 fixed rounded right-0 border mr-2 shadow-md text-gray-700 text-sm font-normal langbtn-op-pad"
            ref={modalRef}
          >
            <li
              className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
              onClick={() => switchLanguage("es-MX")}
            >
              {t("langBtnEs")}
            </li>
            <li
              className="hover-pointer text-base text-gray-700 py-2 px-4 hover:bg-gray-100 flex items-center"
              onClick={() => switchLanguage("en-US")}
            >
              {t("langBtnEn")}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageButton;
