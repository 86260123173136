import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";
import "./PersonalInstall.css";
import { useHistory } from "react-router-dom";
import { INSTALL_PATH } from "../../constants/paths";
import { useTranslation } from "react-i18next";

const PersonallInstall = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Layout title="Introducción">
      <div className="wmobil-install-background"></div>
      <div class="personal-install-main-container">
        <div class="personal-install-card">
          <div className="personal-install-back-text-container">
            <a
              onClick={() => history.push(INSTALL_PATH)}
              style={{ cursor: "pointer" }}
            >
              ← {t("installPage.backBtn")}
            </a>
          </div>
          <div class="personal-install-card-title-container">
            <h2>{t("installPage.personalInst")}</h2>
          </div>
          <div class="personal-install-text-container">
            <p>{t("installPage.personalSub")}</p>
          </div>
          <div class="personal-install-video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/3xjMLncbrk8"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="personal-install-video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Tji9eSi-azI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="personal-install-video-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/CVLQr8RsiIo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="personal-install-card-empecemos-btn-container">
            <button>{t("installPage.begin")}</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PersonallInstall;
