import React, { useEffect, useState } from "react";
import { usePagination, useSortBy, useTable } from "react-table";

import "./Table.scss";
import { useTranslation } from "react-i18next";
import FacturasPagination from "../pages/app/FacturasPagination";

const SortFoliosTable = ({ actualPage, columns, data, setCurrPage }) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    gotoPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data: data,
      initialState: {
        pageIndex: actualPage,
        pageSize: data.length > 20 ? 15 : data.length,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (data.length > 0) {
      const newSize = data.length > 20 ? 15 : data.length;
      setPageSize(newSize);
    }
  }, [data]);

  return (
    <>
      <div className="main-admin-page-users-table-wrapper">
        <table
          id={""}
          {...getTableProps()}
          className="table-auto w-full folios-table-bg mx-auto shadow-md rounded-xl mt-1"
        >
          <thead className="w-full">
            {headerGroups.map((headerGroup) => (
              <tr>
                {headerGroup.headers.map((column) => (
                  <th className="py-1 wfacturas-table-text text-center text-xs md:text-base uppercase">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="text-center font-medium max-w-xs text-xs md:text-sm wfacturas-table-text h-10"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <FacturasPagination
        totalResults={data.length}
        page={pageIndex}
        itemsInPage={pageSize}
        itemsInTable={page.length}
        gotoPage={gotoPage}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        tblnextPage={nextPage}
        canNextPage={canNextPage}
        setCurrPage={setCurrPage}
      />
    </>
  );
};

export default SortFoliosTable;
