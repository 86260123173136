import React from "react";
import { FaCcMastercard, FaCcVisa } from "react-icons/fa";

const PaymentMethodOption = ({
  onClick,
  stripe_id,
  active,
  brand,
  last4,
  name,
  isDefault,
  noClick,
}) => {
  return (
    <div
      className={`select-method md:flex-no-wrap mb-4 ${
        active ? "active" : ""
      } no-click`}
      onClick={noClick ? null : () => onClick(stripe_id)}
    >
      <div className="card-number flex">
        {brand === "mastercard" && (
          <FaCcMastercard size={30} className="mr-4" />
        )}
        {brand === "visa" && <FaCcVisa size={30} className="mr-4" />}
        •••• {last4}
      </div>
      <div>{name}</div>
    </div>
  );
};

export default PaymentMethodOption;
