import React from "react";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import dateAPI from "date-and-time";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorComponent from "../../ErrorComponent";
import { getReporteVentas } from "../../../api/Restaurant";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  //type: yup.string().required("Campo requerido."),
  startDate: yup
    .date()
    .max(new Date(), "La fecha de inicio debe ser antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
  endDate: yup
    .date()
    .min(
      yup.ref("startDate"),
      "La fecha de fin debe ser después de la fecha de inicio"
    )
    .max(new Date(), "La fecha de fin debe ser antes de hoy.")
    .required("Campo requerido.")
    .typeError("Debes seleccionar una fecha válida."),
});

const SalesReportFormMovs = ({ restaurantId }) => {
  const { t } = useTranslation();
  // Instantiate form
  const { register, handleSubmit, errors, setError, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
    });

  // Function to generate report
  const generateReport = ({ startDate, endDate }) => {
    var today = new Date();
    var type = "movimientos";

    if (endDate.getDate() !== today.getDate()) {
      endDate = dateAPI.addDays(endDate, +1);
    } else {
      endDate = dateAPI.addDays(endDate, +1);
      endDate = dateAPI.addMilliseconds(endDate, -1);
    }

    getReporteVentas(
      restaurantId,
      type,
      startDate.toISOString().slice(0, 19).replace("T", " "),
      endDate.toISOString().slice(0, 19).replace("T", " ")
    );
  };

  return (
    <form className="my-4" onSubmit={handleSubmit(generateReport)}>
      <div class="mb-4">
        {/* <label class="block text-gray-700 text-md font-bold mb-3" for="title">
          Tipo de datos
        </label>
        <div class="block relative">
          <select
            name="type"
            ref={register}
            class="block appearance-none w-full bg-white border px-2 py-4 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="corte">Cortes</option>
            <option value="movimientos">Retiros y depósitos</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div> */}

        <ErrorComponent error={errors.type}></ErrorComponent>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-md font-bold mb-3" for="title">
          {t("restModals.salesRepModalInput1")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="startDate"
          max={new Date().toISOString().split("T")[0]}
          defaultValue={new Date().toISOString().split("T")[0]}
          ref={register}
        />
        <ErrorComponent error={errors.startDate}></ErrorComponent>
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-md font-bold mb-3" for="title">
          {t("restModals.salesRepModalInput2")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
          type="date"
          name="endDate"
          ref={register}
          defaultValue={new Date().toISOString().split("T")[0]}
          max={new Date().toISOString().split("T")[0]}
        />{" "}
        <ErrorComponent error={errors.endDate}></ErrorComponent>
      </div>
      <button
        class="w-full bg-brand-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
        type="submit"
      >
        {t("restModals.salesRepModalBtn")}
      </button>
    </form>
  );
};

export default SalesReportFormMovs;
