import React, { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./NewSubscriptionForm.css";
import ErrorComponent from "../ErrorComponent";
import PlanOption from "./PlanOption";
import PaymentMethodOption from "./PaymentMethodOption";
import { Link, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

// import PaymentIcon from "react-payment-icons";
import {
  createCard,
  createRestaurantSubscription,
  getPaymentMethods,
  getUserData,
  updateSubscription,
  validateCoupon,
} from "../../api/User";

// Import paths
import {
  MY_SUBSCRIPTIONS_PATH,
  NEW_PAYMENT_METHOD_PATH,
  NEW_SUBSCRIPTION_PATH,
  INSTALL_PATH,
} from "../../constants/paths";
import {
  MENSUAL,
  TRIMESTRAL,
  SEMESTRAL,
  ANUAL,
  PROMO12,
  PROMO11,
  PROMO10,
  PROMO09,
  PROMO08,
  PROMO07,
  PROMO06,
  PROMO05,
  PROMO04,
  PROMO03,
  PROMO02,
  PROMO01,
} from "../../constants/plans";
import LoadingIndicator from "../LoadingIndicator";
import { validatePassword } from "../../api/Restaurant";
import NewSubscriptionModal from "./NewSubscriptionModal";
import NewPaymentMethodForm from "../methods/NewPaymentMethodForm";

const passwordSchema = yup.object().shape({
  password: yup.string().required("Debes ingresar la contraseña."),
});

const schema = yup.object().shape({
  title: yup.string().required("Debes ingresar el nombre del restaurante."),
  plan: yup.string().required("Selecciona un plan de pago"),
  coupon: yup
    .string()
    .test(
      "length",
      "El cupón debe contener exactamente 10 caracteres o estar en blanco.",
      (coupon) => {
        if (coupon === "" || !coupon || coupon.length === 10) return true;

        return false;
      }
    ),
});

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const RenewSubscriptionForm = ({ id, conektaid, subscription }) => {
  const history = useHistory();
  // Variables for changing password form state
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  // Variable for holding restaurant data
  const [restaurant, setRestaurant] = useState({});

  const initialCoupon = {
    code: "",
  };

  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [response, setResponse] = useState({});
  const [coupon, setCoupon] = useState(null);
  const [couponValid, setCouponValid] = useState(null);
  const [couponCode, setCouponCode] = useState(initialCoupon);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [card, setCard] = useState("new");

  // Variable for success modal
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Instantiate password form
  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
  } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: { password: "" },
  });

  const handleInputChange2 = (e) => {
    const { value } = e.target;
    setCard(value);
  };

  // Instantiate restaurant form
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Variables for watching form attributes
  const plan = watch("plan");
  const method = watch("method");

  // Register method and plan fields
  useEffect(() => {
    register("plan");
    register("number");
    register("code");
    register("exp_year");
    register("exp_month");
    register("cvc");
  }, [register]);

  useEffect(() => {
    window.Conekta.setPublicKey("key_Sud9prnqdFEs7x7YtYKMb7Q");

    getPaymentMethods(conektaid)
      .then((pm) => {
        setPaymentMethods(pm);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const selectMethod = (id) => {
    setValue("method", id, true);
    clearErrors("method");
  };

  const selectPlan = (id) => {
    setValue("plan", id, true);
    clearErrors("plan");
  };

  const handleConektaSubmit = async (data) => {
    const tokenParams = {
      card: {
        number: data.number,
        name: data.name,
        exp_year: data.exp_year,
        exp_month: data.exp_month,
        cvc: data.cvc,
      },
    };
    return window.Conekta.Token.create(
      tokenParams,
      (card) => {
        updateSub({
          tokenId: card.id,
          plan: data.plan,
        });
      },
      errorCallback
    );
  };

  const updateSub = ({ tokenId, plan }) => {
    // setLoading(true);

    let data = {
      customerId: conektaid,
      tokenId: tokenId,
      plan: plan,
      card_type: "none",
    };

    createCard(data)
      .then((res) => {
        data.tokenId = res.cardId;
        updateSubscription(data)
          .then((res2) => {
            if (res2.status == "past_due") {
              alert(
                "pago declinado. Intente con otro metodo de pago o mas tarde"
              );
              setLoading(false);
            } else {
              history.push(MY_SUBSCRIPTIONS_PATH);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
            alert("Hubo un error. Favor de intentar mas tarde.");
          });
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert("Hubo un error. Favor de intentar mas tarde.");
      });
  };

  const successCallback = (data) => {};
  const errorCallback = (data) => {
    alert(data.message_to_purchaser);
    setLoading(false);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    let data = {
      customerId: conektaid,
      tokenId: card,
      plan: values.plan,
      card_type: "none",
    };

    if (card == "new") {
      // setLoading(true);
      handleConektaSubmit(values);
    } else {
      updateSubscription(data)
        .then((res) => {
          if (res.status == "past_due") {
            alert(
              "pago declinado. Intente con otro metodo de pago o mas tarde"
            );
            setLoading(false);
          } else {
            history.push(MY_SUBSCRIPTIONS_PATH);
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Hubo un error. Favor de intentar mas tarde.");
        });
    }

    // createRestaurantSubscription({
    //   ...values,
    //   database_string: restaurant.database_string,
    // })
    //   .then((response) => {
    //     setResponse(response.data);
    //     setLoading(false);
    //     setError(null);
    //     setSuccess(true);
    //   })
    //   .catch((error) => {
    //     setSuccess(false);
    //     setLoading(true);
    //     setError(error);
    //   });
  };

  const onPasswordSubmit = ({ password }) => {
    setPasswordLoading(true);
    validatePassword(id, password)
      .then((restaurant) => {
        setPasswordLoading(false);
        setPasswordSuccess(true);
        setRestaurant(restaurant);
        setPasswordError(null);
      })
      .catch((error) => {
        setPasswordLoading(false);
        setPasswordSuccess(false);
        setPasswordError(error);
      });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setShouldRedirect(true);
  };

  useEffect(() => {
    // Show a success popup if needed
    if (success && !id) {
      openModal();
    } else if (success) {
      setShouldRedirect(true);
    }
  }, [success, response]);

  if (shouldRedirect) return <Redirect to={INSTALL_PATH}></Redirect>;

  if (loading || passwordLoading) return <LoadingIndicator></LoadingIndicator>;

  if (id && !passwordSuccess)
    return (
      <form onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Contraseña del restaurante
          </label>
          <input
            type="password"
            name="password"
            ref={passwordRegister}
            className="input"
            key="password-input"
          />
          <ErrorComponent error={passwordErrors.password}></ErrorComponent>
        </div>
        <button type="submit" className="button-main">
          Verificar contraseña
        </button>
        <ErrorComponent error={passwordError}></ErrorComponent>
      </form>
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <NewSubscriptionModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        license={response.key}
        restaurant={response.restaurant}
      ></NewSubscriptionModal>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Nombre del restaurante:
        </label>
        <input
          type="text"
          name="title"
          ref={register}
          className="input new-sub-input"
          defaultValue={restaurant.text || ""}
          value={subscription.restaurant_title}
        />
        <ErrorComponent error={errors.title}></ErrorComponent>
      </div>
      <div className="input-wrapper mt-4">
        {paymentMethods.length > 0 ? (
          <div className="mb-4">
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              Método de pago:
            </label>
            <select
              className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
              type="text"
              name="card"
              ref={register}
              style={{ borderRadius: "10px" }}
              onChange={handleInputChange2}
            >
              <option value="new">Nuevo</option>
              {paymentMethods.map((pm) => (
                <option value={pm.stripe_id}>
                  {pm.brand} •••• {pm.last4} {pm.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}

        {card == "new" && (
          <>
            <div className="mb-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                Nombre del titular de la cuenta:
              </label>
              <input
                className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                type="text"
                name="name"
                ref={register}
                placeholder="Titular de la cuenta"
              />
            </div>

            <div className="mb-4">
              <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                Número de la tarjeta:
              </label>
              <input
                className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                type="text"
                name="number"
                ref={register}
                maxLength="16"
                placeholder="16 Dígitos de la tarjeta"
              />
            </div>

            <div className="flex mb-8 justify-between">
              <div style={{ width: "30%" }}>
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  Més de Vencimiento:
                </label>
                <select
                  className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                  type="text"
                  name="exp_month"
                  ref={register}
                  style={{ borderRadius: "10px" }}
                >
                  <option value="01">Enero</option>
                  <option value="02">Febrero</option>
                  <option value="03">Marzo</option>
                  <option value="04">Abril</option>
                  <option value="05">Mayo</option>
                  <option value="06">Junio</option>
                  <option value="07">Julio</option>
                  <option value="08">Agosto</option>
                  <option value="09">Septiembre</option>
                  <option value="10">Octubre</option>
                  <option value="11">Noviembre</option>
                  <option value="12">Diciembre</option>
                </select>
              </div>

              <div style={{ width: "30%" }}>
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  Año de vencimiento:
                </label>
                <input
                  className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                  type="text"
                  name="exp_year"
                  ref={register}
                  placeholder="2028"
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div style={{ width: "30%" }}>
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  Código de Seguridad:
                </label>
                <input
                  className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                  type="text"
                  name="cvc"
                  ref={register}
                  placeholder="CVC"
                  style={{ borderRadius: "10px" }}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Plan de pagos:
        </label>
        <div className="flex flex-wrap">
          <PlanOption
            active={
              plan !== TRIMESTRAL &&
              plan !== SEMESTRAL &&
              plan !== ANUAL &&
              plan !== undefined
            }
            title="Plan mensual"
            period="mes"
            price={349}
            id={MENSUAL}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === TRIMESTRAL}
            title="Plan trimestral"
            period="trimestre"
            price={979}
            id={TRIMESTRAL}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === SEMESTRAL}
            title="Plan semestral"
            period="semestre"
            price={1919}
            id={SEMESTRAL}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === ANUAL}
            title="Plan anual"
            period="año"
            price={3699}
            id={ANUAL}
            onClick={selectPlan}
            isLast
          ></PlanOption>
        </div>
        <ErrorComponent error={errors.plan}></ErrorComponent>
      </div>

      <button type="submit" className="button-main new-sub-btn">
        Renovar suscripción
      </button>
    </form>
  );
};

export default RenewSubscriptionForm;
