import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./NewSubscriptionForm.css";
import ErrorComponent from "../ErrorComponent";
import PlanOption from "./PlanOption";
import PaymentMethodOption from "./PaymentMethodOption";
import { Link, Redirect } from "react-router-dom";
import {
  createRestaurantSubscription,
  getPaymentMethods,
  validateCoupon,
} from "../../api/User";

// Import paths
import {
  MY_SUBSCRIPTIONS_PATH,
  NEW_PAYMENT_METHOD_PATH,
  NEW_SUBSCRIPTION_PATH,
  INSTALL_PATH,
  APP_HOME_PATH,
} from "../../constants/paths";
import {
  MENSUAL,
  TRIMESTRAL,
  SEMESTRAL,
  ANUAL,
  PROMO12,
  PROMO11,
  PROMO10,
  PROMO09,
  PROMO08,
  PROMO07,
  PROMO06,
  PROMO05,
  PROMO04,
  PROMO03,
  PROMO02,
  PROMO01,
  PROMO13,
  PROMO14,
  BEARBEAT,
} from "../../constants/plans";
import LoadingIndicator from "../LoadingIndicator";
import { validatePassword } from "../../api/Restaurant";
import NewSubscriptionModal from "./NewSubscriptionModal";
import NewPaymentMethodForm from "../methods/NewPaymentMethodForm";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const passwordSchema = yup.object().shape({
  password: yup.string().required("Debes ingresar la contraseña."),
});

const schema = yup.object().shape({
  title: yup.string().required("Debes ingresar el nombre del restaurante."),
  plan: yup.string().required("Selecciona un plan de pago"),
  coupon: yup
    .string()
    .test(
      "length",
      "El cupón debe contener exactamente 10 caracteres o estar en blanco.",
      (coupon) => {
        if (coupon === "" || !coupon || coupon.length === 10) return true;

        return false;
      }
    ),
});

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const NewSubscriptionForm = ({ id }) => {
  const { t } = useTranslation();

  // Variables for changing password form state
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  // Variable for holding restaurant data
  const [restaurant, setRestaurant] = useState({});

  const initialCoupon = {
    code: "",
  };

  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [shouldRedirectWithID, setShouldRedirectWithID] = useState(false);
  const [response, setResponse] = useState({});
  const [coupon, setCoupon] = useState(null);
  const [couponValid, setCouponValid] = useState(null);
  const [couponCode, setCouponCode] = useState(initialCoupon);
  const [loadingPay, setLoadingPay] = useState(false);

  // Variable for success modal
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const history = useHistory();

  // Instantiate password form
  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    errors: passwordErrors,
  } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: { password: "" },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCouponCode({ ...couponCode, [name]: value });
  };

  // Instantiate restaurant form
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    watch,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Variables for watching form attributes
  const plan = watch("plan");
  const method = watch("method");

  // Register method and plan fields
  useEffect(() => {
    register("plan");
    register("number");
    register("code");
    register("exp_year");
    register("exp_month");
    register("cvc");
    window.Conekta.setPublicKey("key_Sud9prnqdFEs7x7YtYKMb7Q");
  }, [register]);

  const selectMethod = (id) => {
    setValue("method", id, true);
    clearErrors("method");
  };

  const selectPlan = (id) => {
    setValue("plan", id, true);
    clearErrors("plan");
  };

  const handleConektaSubmit = async (data) => {
    const tokenParams = {
      card: {
        number: data.number,
        name: data.name,
        exp_year: data.exp_year,
        exp_month: data.exp_month,
        cvc: data.cvc,
      },
    };
    return window.Conekta.Token.create(
      tokenParams,
      (card) => {
        createRestaurantSubscription({
          ...data,
          card_token: card.id,
          database_string: restaurant.database_string,
          license_id: restaurant.license_id,
        })
          .then((response) => {
            setResponse(response.data);
            if (response.data.suscription.activated === 0) {
              alert(
                "Al parecer hubo un problema con el pago de su suscripcion,\n" +
                  "Lo redireccionaremos a un apartado donde podra intentarlo nuevamente, si el problema persiste favor de contactarse con un administrador."
              );
              history.push(
                MY_SUBSCRIPTIONS_PATH + "/" + response.data.restaurant.id
              );
            } else {
              setLoadingPay(false);
              setLoading(false);
              setError(null);
              setSuccess(true);
            }
          })
          .catch((error) => {
            setSuccess(false);
            setLoading(false);
            setError(error);
          });
      },
      errorCallback
    );
  };

  const successCallback = (data) => {};
  const errorCallback = (data) => {
    setLoadingPay(false);
    setLoading(false);
    alert(data.message_to_purchaser);
  };

  const validateCoupon = () => {
    let codeIsValid = false;

    if (couponCode.code === "WMOBIL2021") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2022") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2023") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2024") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2025") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2026") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2027") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2028") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2029") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2030") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2031") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL2032") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL7") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "WMOBIL14") {
      return window.alert("El codigo es correcto");
    } else if (couponCode.code === "PRUEBA14") {
      return window.alert("El codigo es correcto");
    } else {
      return window.alert("El codigo es incorrecto");
    }
  };

  const onSubmit = async (values) => {
    setLoadingPay(true);

    let codeIsValid = false;

    if (values.code === "WMOBIL2021") {
      values.plan = PROMO01;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2022") {
      values.plan = PROMO02;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2023") {
      values.plan = PROMO03;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2024") {
      values.plan = PROMO04;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2025") {
      values.plan = PROMO05;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2026") {
      values.plan = PROMO06;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2027") {
      values.plan = PROMO07;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2028") {
      values.plan = PROMO08;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2029") {
      values.plan = PROMO09;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2030") {
      values.plan = PROMO10;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2031") {
      values.plan = PROMO11;
      codeIsValid = true;
    } else if (values.code === "WMOBIL2032") {
      values.plan = PROMO12;
      codeIsValid = true;
    } else if (values.code === "WMOBIL7") {
      values.plan = PROMO13;
      codeIsValid = true;
    } else if (values.code === "WMOBIL14") {
      values.plan = PROMO14;
      codeIsValid = true;
    } else if (values.code === "PRUEBA14") {
      values.plan = PROMO12;
      codeIsValid = true;
    }

    if (values.code !== "" && values.code !== undefined) {
      if (!codeIsValid) return window.alert("El código es incorrecto");
    }

    setLoading(true);
    handleConektaSubmit(values);

    // createRestaurantSubscription({
    //   ...values,
    //   database_string: restaurant.database_string,
    // })
    //   .then((response) => {
    //     setResponse(response.data);
    //     setLoading(false);
    //     setError(null);
    //     setSuccess(true);
    //   })
    //   .catch((error) => {
    //     setSuccess(false);
    //     setLoading(true);
    //     setError(error);
    //   });
  };

  const onPasswordSubmit = ({ password }) => {
    setPasswordLoading(true);
    validatePassword(id, password)
      .then((restaurant) => {
        setPasswordLoading(false);
        setPasswordSuccess(true);
        setRestaurant(restaurant);
        setPasswordError(null);
      })
      .catch((error) => {
        setPasswordLoading(false);
        setPasswordSuccess(false);
        setPasswordError(error);
      });
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setShouldRedirect(true);
  };

  useEffect(() => {
    // Show a success popup if needed
    if (success && !id) {
      openModal();
    } else if (success && id) {
      setShouldRedirectWithID(true);
    } else if (success) {
      setShouldRedirect(true);
    }
  }, [success, response]);

  if (shouldRedirect) return <Redirect to={INSTALL_PATH}></Redirect>;
  if (shouldRedirectWithID) return <Redirect to={APP_HOME_PATH}></Redirect>;

  if (loading || passwordLoading) return <LoadingIndicator></LoadingIndicator>;

  if (id && !passwordSuccess)
    return (
      <form onSubmit={passwordHandleSubmit(onPasswordSubmit)}>
        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            Contraseña del restaurante
          </label>
          <input
            type="password"
            name="password"
            ref={passwordRegister}
            className="input"
            key="password-input"
          />
          <ErrorComponent error={passwordErrors.password}></ErrorComponent>
        </div>
        <button type="submit" className="button-main">
          Verificar contraseña
        </button>
        <ErrorComponent error={passwordError}></ErrorComponent>
      </form>
    );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <NewSubscriptionModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        license={response.key}
        restaurant={response.restaurant}
      ></NewSubscriptionModal>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("subsPage.subFormRest")}
        </label>
        <input
          type="text"
          name="title"
          ref={register}
          className="input new-sub-input"
          defaultValue={restaurant.text || ""}
        />
        <ErrorComponent error={errors.title}></ErrorComponent>
      </div>
      <div className="input-wrapper mt-4">
        <div className="mb-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("subsPage.subFormAccHolder")}
          </label>
          <input
            className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="name"
            ref={register}
            placeholder={t("subsPage.subFormHolder")}
          />
        </div>

        <div className="mb-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("subsPage.subFormCard")}
          </label>
          <input
            className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
            type="text"
            name="number"
            ref={register}
            placeholder={t("subsPage.subFormDigits")}
          />
          {/* <InputMask
                        maskPlaceholder={null}
                        className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
                        type="text"
                        name="number"
                        ref={register}
                        placeholder="16 Dígitos de la tarjeta"
                        mask="9999  9999  9999  9999"
                    /> */}
        </div>

        <div className="flex mb-8 justify-between">
          <div style={{ width: "30%" }}>
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("subsPage.subFormCardMonth")}
            </label>
            <select
              className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
              type="text"
              name="exp_month"
              ref={register}
              style={{ borderRadius: "10px" }}
            >
              <option value="01">{t("dashboard.dropdownOps.1")}</option>
              <option value="02">{t("dashboard.dropdownOps.2")}</option>
              <option value="03">{t("dashboard.dropdownOps.3")}</option>
              <option value="04">{t("dashboard.dropdownOps.4")}</option>
              <option value="05">{t("dashboard.dropdownOps.5")}</option>
              <option value="06">{t("dashboard.dropdownOps.6")}</option>
              <option value="07">{t("dashboard.dropdownOps.7")}</option>
              <option value="08">{t("dashboard.dropdownOps.8")}</option>
              <option value="09">{t("dashboard.dropdownOps.9")}</option>
              <option value="10">{t("dashboard.dropdownOps.10")}</option>
              <option value="11">{t("dashboard.dropdownOps.11")}</option>
              <option value="12">{t("dashboard.dropdownOps.12")}</option>
            </select>
          </div>

          <div style={{ width: "30%" }}>
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("subsPage.subFormCardYear")}
            </label>
            <input
              className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
              type="text"
              name="exp_year"
              ref={register}
              placeholder="2028"
              style={{ borderRadius: "10px" }}
            />
          </div>

          <div style={{ width: "30%" }}>
            <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
              {t("subsPage.subFormCardCvc")}
            </label>
            <input
              className="shadow appearance-none border rounded new-sub-input w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
              type="text"
              name="cvc"
              ref={register}
              placeholder="CVC"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("subsPage.subFormPlans")}
        </label>
        <div className="flex flex-wrap">
          <PlanOption
            active={
              plan !== TRIMESTRAL &&
              plan !== SEMESTRAL &&
              plan !== ANUAL &&
              plan !== BEARBEAT &&
              plan !== undefined
            }
            title={t("subsPage.subFormPlan1")}
            period="mes"
            price={349}
            id={MENSUAL}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === TRIMESTRAL}
            title={t("subsPage.subFormPlan2")}
            period="trimestre"
            price={979}
            id={TRIMESTRAL}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === SEMESTRAL}
            title={t("subsPage.subFormPlan3")}
            period="semestre"
            price={1919}
            id={SEMESTRAL}
            onClick={selectPlan}
          ></PlanOption>
          <PlanOption
            active={plan === ANUAL}
            title={t("subsPage.subFormPlan4")}
            period="año"
            price={3699}
            id={ANUAL}
            onClick={selectPlan}
            // isLast
          ></PlanOption>
          <PlanOption
            active={plan === BEARBEAT}
            title="Plan BearBeat"
            period="quincena"
            price={1040}
            id={BEARBEAT}
            onClick={selectPlan}
            isLast
          />
        </div>
        <ErrorComponent error={errors.plan}></ErrorComponent>
      </div>

      {plan === MENSUAL && (
        <div className="input-wrapper mt-4">
          <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
            {t("subsPage.subFormDisc")}
          </label>
          <div className="flex">
            <input
              className="shadow appearance-none new-sub-input border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-lg"
              type="text"
              name="code"
              ref={register}
              placeholder={t("subsPage.subFormDiscHolder")}
              onChange={handleInputChange}
            />
            <button
              type="button"
              onClick={() => validateCoupon()}
              className="btn-canjear-cupon"
            >
              {t("subsPage.subFormRed")}
            </button>
          </div>
        </div>
      )}

      {!loadingPay ? (
        <button type="submit" className="button-main new-sub-btn">
          {t("subsPage.subFormSubmit")}
        </button>
      ) : (
        <div class="spinner"></div>
      )}
    </form>
  );
};

export default NewSubscriptionForm;
