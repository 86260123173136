import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import WSelect from "../WSelect";
import { getFacturas, getFacturasByDate } from "../../api/User";
import LoadingIndicator from "../LoadingIndicator";
import InvoicesTable from "../restaurant/views/InvoicesTable";
import WInput from "../WInput";
import { useToast } from "../../context/ToastContext";
import "jspdf-autotable";
import { generateInvoicesPDFReport, getFolioSettings } from "../../api/Utils";
import { CSVLink } from "react-csv";
import { TbCsv } from "react-icons/tb";
import { TbPdf } from "react-icons/tb";
import { useRestsDropdown } from "../../hooks/useRestsDropdown";

const schema = yup.object().shape({
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  restId: yup.string().required(),
});

const AllInvoicesForm = ({ page, limit }) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { restaurants, restId, setRestId, loading, setLoading, selectRef } =
    useRestsDropdown();
  const [error, setError] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  const [userSeries, setUserSeries] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleReportGeneration = (event) => {
    if (selectedInvoices.length > 0) {
      showMenu(event);
    } else {
      showToast(
        "Debe seleccionar almenos una factura para generar el reporte",
        "custom"
      );
    }
  };

  // Function for opening dropdown menu
  const showMenu = (event) => {
    event.preventDefault();
    setShowReport(true);
    document.addEventListener("click", closeMenu);
  };

  // Function for closing dropdown menu
  const closeMenu = (event) => {
    if (!selectRef.current?.contains(event.target)) {
      setShowReport(false);
      document.removeEventListener("click", closeMenu);
    }
  };

  const handleSelectionChange = (invoices) => {
    setSelectedInvoices(invoices);
  };

  const handleReportType = async (event) => {
    const reportType = event.currentTarget.dataset["type"];

    const filteredInvoices = allInvoices.invoices.filter((invoice) =>
      selectedInvoices.includes(invoice.id)
    );
    if (reportType === "pdf") {
      generateInvoicesPDFReport(
        filteredInvoices,
        startDate,
        endDate,
        allInvoices.totalCanceled,
        allInvoices.totalValid,
        allInvoices.totalInvoiced,
        allInvoices.canceledInvoices,
        allInvoices.acceptedInvoices,
        allInvoices.notInvoicedInWf,
        allInvoices.cheqsTotalSum,
        allInvoices.invoicedCountGeneral,
        allInvoices.totalInvoicedGeneral
      );
      showToast("Reporte PDF generado exitosamente", "success");
    } else if (reportType === "csv") {
      showToast("Archivo CSV exportado correctamente", "success");
    }

    setShowReport(false);
  };

  useEffect(() => {
    if (allInvoices && allInvoices.invoices) {
      const filteredInvoices = allInvoices.invoices.filter((invoice) =>
        selectedInvoices.includes(invoice.id)
      );

      setCsvData([
        [
          "Total ventas",
          "Total folios facturados",
          "Pendiente por facturar",
          "Total cancelaciones",
          "Total facturas",
          "Facturado a púbico general",
        ],
        [
          `=DOLLAR(${allInvoices.cheqsTotalSum},2)`,
          `=DOLLAR(${allInvoices.totalInvoiced},2)`,
          `=DOLLAR(${allInvoices.cheqsTotalSum - allInvoices.totalInvoiced},2)`,
          `=DOLLAR(${allInvoices.totalCanceled},2)`,
          `=DOLLAR(${allInvoices.totalInvoiced},2)`,
          `=DOLLAR(${allInvoices.totalInvoicedGeneral},2)`,
        ],
        [],
        ["Facturas generadas"],
        [],
        ["Folio", "Fecha", "Razón social", "RFC", "Estatus", "Monto"],
        ...filteredInvoices.map(
          ({ folio_number, created_at, customer, status, total }) => [
            folio_number,
            created_at,
            customer.legal_name,
            customer.tax_id,
            status,
            parseFloat(total).toFixed(2),
          ]
        ),
      ]);
    }
  }, [selectedInvoices, allInvoices.invoices]);
  // Get invoices
  const fetchData = async (restId) => {
    try {
      setLoading(true);

      if (!restId || restId.length === 0) {
        return null;
      }

      const facturasResponse = await getFacturas(restId, page, limit);
      const settings = await getFolioSettings(restId);
      if (settings.series) setUserSeries(settings.series);
      if (facturasResponse.invoices !== undefined) {
        setAllInvoices(facturasResponse);
        setCsvData([
          [
            "Total ventas",
            "Total folios facturados",
            "Pendiente por facturar",
            "Total cancelaciones",
            "Total facturas",
            "Facturado a púbico general",
          ],
          [
            `=DOLLAR(${facturasResponse.cheqsTotalSum},2)`,
            `=DOLLAR(${facturasResponse.totalInvoiced},2)`,
            `=DOLLAR(${facturasResponse.cheqsTotalSum - facturasResponse.totalInvoiced},2)`,
            `=DOLLAR(${facturasResponse.totalCanceled},2)`,
            `=DOLLAR(${facturasResponse.totalInvoiced},2)`,
            `=DOLLAR(${facturasResponse.totalInvoicedGeneral},2)`,
          ],
          [],
          ["Facturas generadas"],
          [],
          ["Folio", "Fecha", "Razón social", "RFC", "Estatus", "Monto"],
          ...facturasResponse.invoices.map(
            ({ folio_number, created_at, customer, status, total }) => [
              folio_number,
              created_at,
              customer.legal_name,
              customer.tax_id,
              status,
              total,
            ]
          ),
        ]);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getInvoicesByDate = async (restId, startDate, endDate) => {
    try {
      setLoading(true);
      const _endDate = new Date(endDate);

      const newEndDate = new Date(_endDate.getTime());
      newEndDate.setDate(_endDate.getDate() + 1);

      const formattedEndDate = newEndDate.toISOString().split("T")[0];
      const facturasResponse = await getFacturasByDate(
        restId,
        startDate,
        formattedEndDate
      );

      if (facturasResponse.invoices !== undefined) {
        setAllInvoices(facturasResponse);
        setCsvData([
          [
            "Total ventas",
            "Total folios facturados",
            "Pendiente por facturar",
            "Total cancelaciones",
            "Total facturas",
            "Facturado a púbico general",
          ],
          [
            `=DOLLAR(${facturasResponse.cheqsTotalSum},2)`,
            `=DOLLAR(${facturasResponse.totalInvoiced},2)`,
            `=DOLLAR(${facturasResponse.cheqsTotalSum - facturasResponse.totalInvoiced},2)`,
            `=DOLLAR(${facturasResponse.totalCanceled},2)`,
            `=DOLLAR(${facturasResponse.totalInvoiced},2)`,
            `=DOLLAR(${facturasResponse.totalInvoicedGeneral},2)`,
          ],
          [],
          ["Facturas generadas"],
          [],
          ["Folio", "Razón social", "RFC", "Monto"],
          ...facturasResponse.invoices.map(
            ({ folio_number, customer, total }) => [
              folio_number,
              customer.legal_name,
              customer.tax_id,
              total,
            ]
          ),
        ]);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (restId) {
      fetchData(restId);
    }
  }, []);

  useEffect(() => {
    if (restId) {
      setLoading(true);
      fetchData(restId);
    }
  }, [restId]);

  if (error) {
    return (
      <div className="p-8 w-full">
        <h2 className="text-2xl text-gray-800 font-bold">
          Contacte a soporte para dar de alta esta funcionalidad
        </h2>
      </div>
    );
  }
  return (
    <div className="p-8 w-full mt-12 md:mt-0">
      <div className="flex justify-between">
        <div>
          <h3 className="facturas-title">Facturas realizadas</h3>
          <text className="text-md">
            Aquí encontrarás todas tus facturas hechas con anterioridad
          </text>
        </div>
        <div className="relative">
          <button
            onClick={(e) => handleReportGeneration(e)}
            className="wfacturas-button"
          >
            Crear reporte
          </button>
          <ul
            ref={selectRef}
            className={`${
              !showReport ? "hidden" : "block"
            } z-10 bg-white py-2 absolute top-0 mt-10 w-32 rounded right-0 border shadow-md text-left text-gray-700 text-sm font-normal`}
          >
            <li
              className="hover-pointer  px-4 justify-center hover:bg-gray-100 flex items-center"
              onClick={handleReportType}
              data-type="pdf"
            >
              <TbPdf className="w-full h-6" />
            </li>
            <li
              className="hover-pointer px-4 justify-center hover:bg-gray-100 flex items-center"
              onClick={handleReportType}
              data-type="csv"
            >
              <CSVLink
                filename={`Invoice report ${startDate.split("T")[0]} - ${
                  endDate.split("T")[0]
                }`}
                data={csvData}
              >
                <TbCsv className="w-full h-6" />
              </CSVLink>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div className="input-wrapper mt-4 mb-4">
          <WSelect
            mandatory
            name="restId"
            label="Seleccione sucursal"
            defaultValue={restaurants}
            ref={selectRef}
            onChange={(value) => setRestId(value)}
          >
            {restaurants?.map((rest, index) => (
              <option key={index} value={rest.id}>
                {rest.text}
              </option>
            ))}
          </WSelect>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center input-wrapper mt-4 mb-4 justify-start">
          <div className="flex items-center mt-6 md:mt-0">
            <WInput
              name="start_date"
              type="date"
              label={"Fecha de inicio"}
              maxDate={new Date().toISOString().split("T")[0]}
              defaultValue={new Date().toISOString().split("T")[0]}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <div className="px-4">al</div>
            <WInput
              name="end_date"
              type="date"
              label={"Fecha final"}
              maxDate={new Date().toISOString().split("T")[0]}
              defaultValue={new Date().toISOString().split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="p-0 md:p-4 mt-4 md:mt-0">
            <button
              className="wfacturas-button"
              onClick={() => getInvoicesByDate(restId, startDate, endDate)}
            >
              Buscar
            </button>
          </div>
        </div>
        <div className="">
          {loading ? (
            <LoadingIndicator />
          ) : allInvoices.invoices?.length > 0 ? (
            <InvoicesTable
              invoices={allInvoices.invoices}
              restId={restId}
              onSelectionChange={handleSelectionChange}
              selectedInvoices={selectedInvoices}
              fetchData={fetchData}
              series={userSeries}
            />
          ) : (
            <p className="md:text-base text-sm text-center mt-6 font-medium">
              No se encontraron facturas
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllInvoicesForm;
