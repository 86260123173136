import React, { useContext, useState, useEffect } from "react";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";

import SortableTableForConnections from "../../components/SortableTableForConnections";
import SwitchConnectionButton from "../../components/SwitchConnectionButton";

import { NotificationContext } from "../../context/NotificationContext";
import "./AdminHomePage.scss";
import { useTranslation } from "react-i18next";

const AdminConnectionsPage = () => {
  const { t } = useTranslation();
  const { restaurants, restaurantsOffline, error, loading } =
    useContext(NotificationContext);

  const licenciasOffline = [];

  restaurantsOffline.forEach((e) => {
    licenciasOffline.push(e.licencia);
  });

  const columns = [
    {
      Header: t("restPage.accTableTopHeader1"),
      accessor: "restaurante",
    },
    {
      Header: t("signInForm.email2"),
      accessor: "email",
    },
    {
      Header: t("subsPage.lic"),
      accessor: "licencia",
    },
    {
      Header: t("admin.lastFetch"),
      accessor: (values) => {
        var format = values.ultimoPoleo;
        format = format.replace("T", " / ");
        format = format.slice(0, -5);
        return format;
      },
    },
    {
      Header: t("admin.timeZn"),
      accessor: "zonahoraria",
    },
    {
      Header: t("admin.stat"),
      accessor: (values) => {
        return (
          <div>
            <SwitchConnectionButton
              license={values.licencia}
              colorStatus={values.colorStatus}
            ></SwitchConnectionButton>
          </div>
        );
      },
    },
  ];

  if (loading) return <LoadingIndicator fullScreen />;

  if (error) {
    return <Layout title="Dashboard">{error.message}</Layout>;
  }

  return (
    <Layout title="Dashboard" admin>
      <div className="py-8 wrapper">
        <div className="main-admin-page-padding">
          <h2 className="text-2xl text-gray-800 font-bold">
            {t("navBar.conn")}
          </h2>
          <br></br>
          <p className="text-gray-600">
            {t("admin.connText1")} <br /> <br />
            {t("admin.connText2")}
            <br />
            {t("admin.connText3")}
          </p>
          <hr className="mt-2 mb-4" />
          <SortableTableForConnections
            id="admin-users-table"
            columns={columns}
            data={restaurants}
            offlineData={licenciasOffline}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AdminConnectionsPage;
