import React, { useState } from "react";

// Import icons
import MdCloseCircle from "react-ionicons/lib/MdCloseCircle";

// Import modal component.
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "5px 5px 54px rgba(0, 0, 0, 0.25)",
    width: "90%",
    maxWidth: "700px",
  },
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const QrGenerationModal = ({
  isOpen,
  onRequestClose,
  qrLink,
  downloadImage,
  expiration,
}) => {
  const { t } = useTranslation();
  const restaurant_id = qrLink.split("facturacion/")[1];
  const [showDueDate, setShowDueDate] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
      className="py-8 wrapper relative tab-rep-resp"
    >
      <div className="px-2 md:px-8 py-6 bg-white border w-full relative">
        <MdCloseCircle
          className="hover-pointer absolute w-6 fill-current text-gray-700 top-0 right-0 mr-3 mt-3"
          onClick={onRequestClose}
        ></MdCloseCircle>
        <div className="flex flex-col items-center">
          <h3 className="font-bold text-gray-900 text-xl text-center mb-5">
            Descarga este código QR para agregarlo al ticket
          </h3>
          <div className="flex w-full pl-10 gap-4 items-center justify-start">
            <input
              type="checkbox"
              value={showDueDate}
              defaultChecked={showDueDate}
              onChange={() => setShowDueDate((prev) => !prev)}
            />
            <text className="wfacturas-table-text text-base font-semibold">
              Mostrar vigencia
            </text>
          </div>
          <div id="capture" className="qr-max-size pb-2">
            <div className="w-1/2">
              <QRCode className="w-full" value={qrLink} />
            </div>
            <div className="flex flex-col w-1/2 items-center justify-center ticket-font">
              <img
                className="imac mb-4"
                src={require("../../images/wmobilfacturacion.svg")}
                alt="imac con wmobil"
              />
              <p className="mb-4">Id restaurante: {restaurant_id}</p>
              <p>Para facturación ingrese a este enlace:</p>
              <p className="mb-4 text-xs md:text-xl">
                wfacturas.com/facturacion
              </p>
              {showDueDate && (
                <p className="text-xs md:text-sm">
                  {parseInt(expiration) === 720
                    ? `Tiene hasta el fin de mes para realizar su factura`
                    : `Este ticket tiene vigencia de ${expiration ? expiration : 72}
                      horas para realizar su factura`}
                </p>
              )}
            </div>
          </div>
        </div>
        <button
          class="w-full mt-4 bg-brand-700 text-white font-bold py-4 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={downloadImage}
        >
          Descargar
        </button>
      </div>
    </Modal>
  );
};

export default QrGenerationModal;
