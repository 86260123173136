import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../../components/ErrorComponent";
import { changeStatusColor } from "../../api/Admin";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Redirect } from "react-router-dom";
//import { ADMIN_DISTRIBUTORS_PATH } from "../../constants/adminPaths";

const schema = yup.object().shape({
  id: yup.string().required("Debes ingresar la licencia del restaurante."),
});

const SwitchRestaurantColorStatus = () => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [colorStatus, setColorStatus] = useState("");
  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  // Function to submit form
  const onSubmit = (data) => {
    setLoading(true);

    changeStatusColor(data.id, data.color)
      .then((result) => {
        setLoading(false);
        setSuccess(true);
        setError(null);
        alert(
          "Cambio de color de " + data.id + " a " + data.color + " exitoso"
        );
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(false);
        setError(err);
      });
  };

  //if (success) return <Redirect to={ADMIN_DISTRIBUTORS_PATH}></Redirect>;
  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="input-wrapper mt-4">
        <label className="text-bg font-bold text-red-800 leading-4 mb-2 block">
          Herramienta de cambio color del estatus de un restaurante
        </label>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Licencia del restaurante
        </label>
        <input type="text" name="id" ref={register} className="input" />
        <ErrorComponent error={errors.id}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <table>
          <tr>
            <th>
              <div
                style={{
                  width: "280px",

                  position: "relative",
                  marginRight: "50px",
                }}
              >
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  Color
                </label>
                <input
                  style={{ height: "50px" }}
                  value={colorStatus}
                  type="color"
                  name="color"
                  ref={register}
                  className="input"
                  onChange={(e) => {
                    setColorStatus(e.target.value);
                  }}
                />
                <ErrorComponent error={errors.color}></ErrorComponent>
              </div>
            </th>
            <th>
              <div>
                <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
                  HEX
                </label>
                <input
                  value={colorStatus}
                  type="text"
                  name="color"
                  ref={register}
                  className="input"
                  onChange={(e) => {
                    setColorStatus(e.target.value);
                  }}
                />
                <ErrorComponent error={errors.color}></ErrorComponent>
              </div>
            </th>
          </tr>
        </table>
      </div>

      <button type="submit" className="button-main">
        Cambiar código de color
      </button>
      <ErrorComponent error={error}></ErrorComponent>
    </form>
  );
};

export default SwitchRestaurantColorStatus;
