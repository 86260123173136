import axios from "axios";
import { API_PATH } from "../constants/api";

export const fetchRegularUsers = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.users;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchRegularUser = async (id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchRegularUserFromAdmin = async (id) => {
  try {
    const response = await axios.get(`${API_PATH}/admin/users/no-auth/${id}`);
    return response.data.user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLicensesFromAdmin = async (id) => {
  try {
    const response = await axios.get(
      `${API_PATH}/admin/licenses/no-auth/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getImpersonatedUserFromAdmin = async (id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/impersonate/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFacturapiData = async (rest_id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${API_PATH}/facturacion/get_facturapi/${rest_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateFacturapiData = async (data) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/facturacion/update_facturapi`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateFacturapiPlanData = async (data) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/facturacion/update_facturapi_plan`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDisconnectedRestaurants = async () => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${API_PATH}/admin/disconnectedRestaurants`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteRestaurant = async (id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/restaurants/delete`,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchRestaurant = async (id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/restaurants/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.restaurant;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDistributors = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/distributors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.distributors;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDistributorsPayments = async () => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/distributorsPayments`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.distributors;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDistributor = async (id) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(`${API_PATH}/admin/distributors/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.distributor;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createDistributor = async (distributor) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/admin/distributors`,
      distributor,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.distributor;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changePassword = async (correo, pass) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/admin/switchPassword`,
      {
        email: correo,
        password: pass,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changeStatusColor = async (licencia, color) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/admin/switchColorStatus`,
      {
        id: licencia,
        colorStatus: color,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const terminateSession = async (correo) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/admin/terminateSession`,
      {
        email: correo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const verifyUserByAdmin = async (correo) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${API_PATH}/admin/verifyuserbyadmin`,
      {
        email: correo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserByEmail = async (email) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${API_PATH}/admin/usersbyEmail/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.user;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRestaurantsByUserId = async (id) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${API_PATH}/admin/resturantsFromUser/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.restaurants;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addDays = async (userId, restaurantId, days) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_PATH}/admin/addDays`,
      {
        userId: userId,
        restaurantId: restaurantId,
        days: days,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const reinstallTurnosTotal = async (restaurantId) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_PATH}/admin/reset_turnostotal`,
      {
        restaurantId: restaurantId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateInvoicedCheque = async (rest_id, folio, invoiced) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_PATH}/admin/update_invoiced_cheque`,
      {
        restaurantId: rest_id,
        folio: folio,
        invoiced: invoiced,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const newRestaurantAdmin = async (user, name, days) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${API_PATH}/admin/newRestaurantAdmin`,
      {
        user: user,
        name: name,
        days: days,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDatabase = async (dbString) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.delete(
      `${API_PATH}/admin/databases/${dbString}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.message;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDatabaseOvh = async (dbString) => {
  const adminUser = {
    email: "pruebaadmin@gmail.com",
    password: "123456",
  };

  try {
    const ovhLogin = await axios.post(
      `https://wmobil.inowu.dev/api/users/login`,
      adminUser
    );
    const ovhToken = ovhLogin?.data?.token;
    if (!ovhToken) throw new Error("Failed to get token");

    const response = await axios.delete(
      `https://wmobil.inowu.dev/api/admin/databases/${dbString}`,
      {
        headers: {
          Authorization: `Bearer ${ovhToken}`,
        },
      }
    );
    return response.data.message;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
