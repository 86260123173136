import React, { useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

const ClipboardCopy = ({ value, children }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="relative copy">
      <CopyToClipboard text={value} onCopy={handleCopy}>
        {children}
      </CopyToClipboard>
      {copied ? (
        <div
          className="absolute py-1 px-2 bg-gray-300 text-gray-800 text-xs rounded w-40 font-medium"
          style={{ left: "calc(100% + .5rem)", top: "0%" }}
        >
          Copiado al portapapeles
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClipboardCopy;
