import React from "react";
import NewDistributorForm from "../../components/admin/distributor/NewDistributorForm";
import Layout from "../../components/Layout";

import BackArrow from "../../components/BackArrow";
import { ADMIN_DISTRIBUTORS_PATH } from "../../constants/adminPaths";

const NewDistributorPage = () => {
  return (
    <Layout title="Nuevo distribuidor" admin>
      <div className="py-8 wrapper">
        <BackArrow to={ADMIN_DISTRIBUTORS_PATH} title="Regresar"></BackArrow>
        <h2 className="text-2xl text-gray-800 font-bold">Nuevo distribuidor</h2>
        <NewDistributorForm></NewDistributorForm>
      </div>
    </Layout>
  );
};

export default NewDistributorPage;
