import React, { createContext, useContext, useEffect, useState } from "react";

const TimbresContext = createContext();

export const TimbresProvider = ({ children }) => {
  const [timbresCount, setTimbresCount] = useState(() => {
    const storedCount = localStorage.getItem("timbresCount");
    return storedCount ? parseInt(storedCount, 10) : 0;
  });

  const updateTimbresCount = (newCount) => {
    setTimbresCount(newCount);
  };

  useEffect(() => {
    localStorage.setItem("timbresCount", timbresCount.toString());
  }, [timbresCount]);

  return (
    <TimbresContext.Provider value={{ timbresCount, updateTimbresCount }}>
      {children}
    </TimbresContext.Provider>
  );
};

export const useTimbres = () => {
  return useContext(TimbresContext);
};
