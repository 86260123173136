import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../ErrorComponent";
import {
  deleteGroup,
  editGroup,
  getAllRestaurants,
  getGroup,
} from "../../api/Groups";
import { Redirect } from "react-router-dom";
import { APP_HOME_PATH } from "../../constants/paths";
import LoadingIndicator from "../LoadingIndicator";

const schema = yup.object().shape({
  title: yup.string().required("Debes ingresar el nombre del grupo."),
  restaurants: yup
    .array()
    .test(
      "at-least-one",
      "Debes seleccionar al menos un restaurante",
      (restaurants) => {
        return restaurants.some((selected) => selected);
      }
    ),
});

const EditGroupForm = ({ id }) => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Variable for holding group data
  const [group, setGroup] = useState([]);

  // Variable for holding all restaurants from user
  const [restaurants, setRestaurants] = useState([]);

  // Instantiate form
  const { register, handleSubmit, errors, setValue, watch } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let group_restaurants = [];

    setLoading(true);
    getGroup(id)
      .then((group) => {
        setGroup(group);

        group_restaurants = group.restaurants;

        return getAllRestaurants();
      })
      .then((rests) => {
        setLoading(false);
        setError(null);
        setRestaurants(
          rests
            .sort((a, b) => a.id > b.id)
            .map((restaurant) => {
              return {
                ...restaurant,
                selected:
                  group_restaurants.findIndex((gr) => {
                    return gr.restaurant_id === restaurant.id;
                  }) !== -1,
              };
            })
        );
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        setRestaurants([]);
      });
  }, []);

  const onSubmit = (values) => {
    setLoading(true);

    const selectedRestaurants = values.restaurants
      .map((selected, index) => {
        return { ...restaurants[index], selected };
      })
      .filter((restaurant) => {
        return restaurant.selected;
      });

    editGroup(id, {
      restaurants: selectedRestaurants,
      title: values.title,
    })
      .then(() => {
        setSuccess(true);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setSuccess(false);
        setLoading(false);
        setError(error);
      });
  };

  const onDelete = () => {
    if (window.confirm("¿Seguro que deseas borrar el grupo?")) {
      deleteGroup(id)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setError(null);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  };

  if (loading) return <LoadingIndicator fullScreen></LoadingIndicator>;

  if (success) return <Redirect to={APP_HOME_PATH}></Redirect>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Nombre del grupo
        </label>
        <input
          type="text"
          name="title"
          ref={register}
          className="input"
          defaultValue={group.title}
        />
        <ErrorComponent error={errors.title}></ErrorComponent>
      </div>
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          Restaurantes
        </label>
        <div className="mt-4">
          {restaurants.map((restaurant, index) => (
            <div className="w-full border rounded px-3 py-2 text-gray-700 flex items-center mb-2">
              <input
                type="checkbox"
                name={`restaurants[${index}]`}
                ref={register}
                defaultChecked={restaurant.selected}
              />
              <span className="ml-2">{restaurant.text}</span>
            </div>
          ))}
          <ErrorComponent error={errors.restaurants}></ErrorComponent>
        </div>
      </div>
      <button type="submit" className="button-main">
        Editar grupo
      </button>
      <button type="button" className="button-danger" onClick={onDelete}>
        Eliminar grupo
      </button>
      <ErrorComponent error={error}></ErrorComponent>
    </form>
  );
};

export default EditGroupForm;
