import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ErrorComponent from "../../ErrorComponent";
import emailjs from "emailjs-com";
import { signUp, fetchUserProfile } from "../../../api/Auth";
import {
  generateMailCode,
  validateMailCode,
  setUserVerified,
  isUserVerified,
} from "../../../api/User";
import { FILES_PATH } from "../../../constants/api";

import LoadingIndicator from "../../LoadingIndicator";
import { useTranslation } from "react-i18next";

const schema = yup.object().shape({
  name: yup.string().required("Debes ingresar tu nombre."),
  lastName: yup.string().required("Debes ingresar tu apellido."),
  email: yup.string().required("Debes ingresar tu correo electrónico."),
  password: yup.string().required("Debes ingresar tu contraseña"),
});

const SignupForm = ({ defaultEmail, referalId, distributor }) => {
  // Variables for changing form state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { t } = useTranslation();

  // Instantiate form
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    signUp({ ...data, distributor_id: referalId })
      .then((result) => {
        sendMailCode(data.email, data.name);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        setSuccess(false);
      });
  };

  const sendMailCode = (email, usr_name) => {
    generateMailCode(email).then((code) => {
      var templateParams = {
        reply_to: email,
        code: code.mailCode,
        name: usr_name,
      };
      setLoading(false);
      /* setError(null);
      setSuccess(true); */
      emailjs
        .send(
          "service_ss9w9ws",
          "template_tc2fxi5",
          templateParams,
          "user_itfQnWMwlnZrVcVMwjZwQ"
        )
        .then((res) => {
          setLoading(false);
          setError(null);
          setSuccess(true);
        });
    });
  };

  if (loading) return <LoadingIndicator></LoadingIndicator>;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="relative">
      {referalId ? (
        <div className="absolute opacity-25 w-full" style={{ zIndex: -1 }}>
          <img
            src={`${FILES_PATH}/${distributor.avatar}`}
            className="opacity-50 max-w-xs mx-auto block"
          ></img>
        </div>
      ) : (
        <div className="absolute opacity-25 w-full" style={{ zIndex: -1 }}>
          <img
            src={"/static/media/wmobil.21af0a67.svg"}
            className="opacity-50 max-w-xs mx-auto block"
          ></img>
        </div>
      )}
      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signUpForm.name")}
        </label>
        <input type="text" name="name" ref={register} className="input" />
        <ErrorComponent error={errors.name}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signUpForm.lName")}
        </label>
        <input type="text" name="lastName" ref={register} className="input" />
        <ErrorComponent error={errors.lastName}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signInForm.email")}
        </label>
        <input
          type="email"
          name="email"
          ref={register}
          defaultValue={defaultEmail}
          className="input"
        />
        <ErrorComponent error={errors.email}></ErrorComponent>
      </div>

      <div className="input-wrapper mt-4">
        <label className="text-sm font-bold text-gray-800 leading-4 mb-2 block">
          {t("signInForm.pass")}
        </label>
        <input
          type="password"
          name="password"
          ref={register}
          className="input"
        />
        <ErrorComponent error={errors.password}></ErrorComponent>
      </div>

      <button type="submit" className="button-main login-btn ">
        {t("navBar.register")}
      </button>

      <br></br>
      <div className="">
        <ErrorComponent error={error}></ErrorComponent>
      </div>
    </form>
  );
};

export default SignupForm;
