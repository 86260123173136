import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  HOME_PATH,
  HOME_PATH_DISTRIBUTION,
  HOME_PATH_FACTURACION,
  HOME_PATH_LANDING,
  SIGNIN_PATH,
  SIGNUP_PATH,
} from "../../constants/paths";
import MdCancel from "react-ionicons/lib/MdClose";
import MdList from "react-ionicons/lib/MdList";
import "./NavbarV2.scss";
import MdHome from "react-ionicons/lib/MdHome";
import {
  DISTRIBUTOR_DASHBOARD_PATH,
  DISTRIBUTOR_MY_ACCOUNT_PATH,
} from "../../constants/distributorPaths";
import {
  ADMIN_CONNECTIONS_PATH,
  ADMIN_DISTRIBUTORS_PATH,
  ADMIN_HOME_PATH,
  ADMIN_MY_ACCOUNT_PATH,
  ADMIN_TOOLS_PATH,
} from "../../constants/adminPaths";
import MdRestaurant from "react-ionicons/lib/MdRestaurant";
import { MdLogin } from "react-icons/md";
import { MdShare } from "react-icons/md";
import { MdAddBusiness } from "react-icons/md";
import { useTranslation } from "react-i18next";

export const NavbarV2 = (props) => {
  const { t } = useTranslation();

  const [userType, setUserType] = useState({
    admin: props && props.admin,
    distributor: props && props.distributor,
    restaurant: props && props.restaurant,
  });
  const [isAuth, setIsAuth] = useState(
    window.localStorage.getItem("token") ? true : false
  );

  const [show, setShow] = useState(false);

  function locationClassName(location) {
    switch (location) {
      case "landing":
        return "navbar-v2-landing";
        break;

      default:
        return "";
        break;
    }
  }

  useEffect(() => {
    // Set an event listener for local storage.
    window.addEventListener("storage", reactToTokenChange);

    // Remove event listener.
    return () => {
      window.removeEventListener("storage", reactToTokenChange);
    };
  }, []);

  // Function to react to token change in local storage.
  const reactToTokenChange = () => {
    setIsAuth(window.localStorage.getItem("token") ? true : false);
  };

  const UserLinks = () => {
    const [show, setShow] = useState(false);

    if (userType.admin) {
      return (
        <nav className="navbar-v2 ">
          <ul>
            <Link to={HOME_PATH_LANDING}>
              <li>
                <img src={require("../../images/wmobil.svg")} />
                <h3>Wmobil</h3>
              </li>
            </Link>
          </ul>
          <ul className="navlinks-admin">
            <NavLink to={ADMIN_HOME_PATH} exact>
              <li>
                <h3>{t("navBar.users")}</h3>
              </li>
            </NavLink>
            <NavLink to={ADMIN_CONNECTIONS_PATH} exact>
              <li>
                <h3>{t("navBar.conn")}</h3>
              </li>
            </NavLink>
            <NavLink to={ADMIN_DISTRIBUTORS_PATH} exact>
              <li>
                <h3>{t("navBar.dist")}</h3>
              </li>
            </NavLink>
            <NavLink to={ADMIN_MY_ACCOUNT_PATH} exact>
              <li>
                <h3>{t("navBar.myAcc")}</h3>
              </li>
            </NavLink>
            <NavLink to={ADMIN_TOOLS_PATH} exact>
              <li>
                <h3>{t("navBar.tools")}</h3>
              </li>
            </NavLink>
          </ul>
        </nav>
      );
    } else if (userType.distributor) {
      return (
        <nav className="navbar-v2 ">
          <ul>
            <Link to={DISTRIBUTOR_DASHBOARD_PATH}>
              <li>
                <img src={require("../../images/wmobil.svg")} />
                <h3>Wmobil</h3>
              </li>
            </Link>
          </ul>
          <ul className="navlinks-distributor">
            <NavLink to={DISTRIBUTOR_DASHBOARD_PATH} exact>
              <li>
                <h3>{t("navBar.dash")}</h3>
              </li>
            </NavLink>
            <NavLink to={DISTRIBUTOR_MY_ACCOUNT_PATH} exact>
              <li>
                <h3>{t("navBar.myAcc")}</h3>
              </li>
            </NavLink>
          </ul>
        </nav>
      );
    } else {
      return (
        <nav className={"navbar-v2 " + locationClassName(props.location)}>
          <ul>
            <Link to={HOME_PATH_LANDING} className="wmobil-logo-resp">
              <li>
                <img src={require("../../images/wmobil.svg")} />
                <h3 className="navlinks-resp">Wmobil</h3>
              </li>
            </Link>
          </ul>
          <ul className="navlinks-default">
            <NavLink to={HOME_PATH_LANDING} exact>
              <li>
                <h3 className="navlinks-resp">{t("navBar.rest")}</h3>
              </li>
            </NavLink>
            <NavLink to={HOME_PATH_DISTRIBUTION} exact>
              <li>
                <h3 className="navlinks-resp">{t("navBar.dist")}</h3>
              </li>
            </NavLink>
            <NavLink to={HOME_PATH_FACTURACION} exact>
              <li>
                <h3>Facturación</h3>
              </li>
            </NavLink>
            {!isAuth && (
              <>
                <NavLink to={SIGNIN_PATH} exact>
                  <li>
                    <h3 className="navlinks-resp">{t("navBar.signIn")}</h3>
                  </li>
                </NavLink>
                <NavLink to={SIGNUP_PATH} exact>
                  <li>
                    <h3 className="navlinks-resp">{t("navBar.join")}</h3>
                  </li>
                </NavLink>
              </>
            )}
          </ul>
          <ul className="navlinks-container resp">
            <li className={`menu-button-burger ${show ? "show" : ""}`}>
              {!show ? (
                <MdList onClick={() => setShow(!show)} />
              ) : (
                <>
                  <div className="profile-navigation bg-white-100 px-4 border-gray-200 cancel-top-right-corner">
                    <MdCancel
                      className="position-top-right"
                      onClick={() => setShow(!show)}
                    />
                    <div
                      className="menu-account sticky pt-8"
                      onClick={() => setShow(!show)}
                    >
                      <NavLink
                        to={HOME_PATH_LANDING}
                        exact
                        activeClassName="active"
                        className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900 nav-link-flex-box"
                      >
                        <MdRestaurant className="fill-current" />
                        <h3>{t("navBar.rest")}</h3>
                      </NavLink>
                      <NavLink
                        to={HOME_PATH_DISTRIBUTION}
                        exact
                        activeClassName="active"
                        className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900 nav-link-flex-box"
                      >
                        <MdShare className="fill-current" />
                        <h3>{t("navBar.dist")}</h3>
                      </NavLink>
                      <NavLink
                        to={SIGNIN_PATH}
                        exact
                        activeClassName="active"
                        className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900 nav-link-flex-box"
                      >
                        <MdLogin className="fill-current" />
                        <h3>{t("navBar.signIn")}</h3>
                      </NavLink>
                      <NavLink
                        to={SIGNUP_PATH}
                        exact
                        activeClassName="active"
                        className="px-4 py-2 flex rounded-md hover:bg-gray-200 m-2 text-gray-700 hover:text-gray-900 nav-link-flex-box"
                      >
                        <MdAddBusiness className="fill-current" />
                        <h3>{t("navBar.join")}</h3>
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </li>
          </ul>
        </nav>
      );
    }
  };

  return UserLinks();
};
