import React, { useEffect, useState } from "react";

// Import modal component.
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import "./WFacturasActions.scss";
import { Checkbox } from "@mui/material";
import WSelect from "../WSelect";
import { BarLoader } from "react-spinners";

// Bind the modal to the app element.
Modal.setAppElement("#root");

// Custom styles for modal
const customStyles = {
  overlay: { backgroundColor: "rgba(0,0,0,.4)", zIndex: "11" },
};

const WFacturasActionModal = React.memo(
  ({
    modalIsOpen,
    closeModal,
    modalData,
    initializeAction,
    loading,
    userEmail,
    saveHours,
  }) => {
    const { t } = useTranslation();
    const { type, id, folio, fecha, vencimiento } = modalData;
    const [motive, setMotive] = useState("no");
    const [pdf, setPdf] = useState(false);
    const [xml, setXml] = useState(false);
    const [email, setEmail] = useState(null);
    const [hours, setHours] = useState(72);
    const [motiveSelect, setMotiveSelect] = useState(false);
    let prevHours = 720;

    if (fecha) {
      const fechaDate = new Date(fecha).getDate();
      const vencimientoDate = new Date(vencimiento).getDate();
      const difference = vencimientoDate - fechaDate;
      prevHours = difference > 72 ? difference * 24 : 720;
    }

    const handleCancelInvoice = () => {
      setMotiveSelect(true);

      if (motive !== "no") {
        initializeAction(motive);
      }
    };

    const handleCloseModal = () => {
      setMotiveSelect(false);
      setMotive("no");
      setPdf(false);
      setXml(false);
      setEmail(null);

      closeModal();
    };

    const handleDownloadInvoice = () => {
      const downloadType =
        pdf && xml ? "zip" : pdf ? "pdf" : xml ? "xml" : null;
      if (downloadType) {
        initializeAction(downloadType);
      }
    };

    const handleSendToEmail = () => {
      const emailToUse = !email ? userEmail : email;
      initializeAction(emailToUse);
    };

    useEffect(() => {
      setHours(prevHours);
    }, [prevHours]);

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
        className="wfacturas-modal tab-rep-resp"
      >
        {type === "cancel" && (
          <div>
            <div className="flex items-center justify-between w-title-container">
              <h3>Cancelar factura</h3>
              <div className="cursor-pointer" onClick={handleCloseModal}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15L15 5M5 5L15 15"
                    stroke="#3F2E04"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="w-body-container">
              <p className="mb-4">
                {`Está a punto de cancelar la factura con folio: ${folio} ¿Desea continuar?`}
              </p>
              {motiveSelect && (
                <WSelect
                  mandatory
                  name="restId"
                  label="Seleccione un motivo"
                  onChange={(value) => setMotive(value)}
                >
                  <option value="no">
                    {t("dashboard.dropdownOps.myAccDrop1")}
                  </option>
                  <option value="01">
                    Comprobante emitido con errores con relación
                  </option>
                  <option value="02">
                    Comprobante emitido con errores sin relación
                  </option>
                  <option value="03">No se llevó a cabo la operación</option>
                  <option value="04">
                    Operación nominativa relacionada en la factura global
                  </option>
                </WSelect>
              )}
            </div>
            <div className="w-buttons-container">
              {!loading ? (
                <>
                  <button
                    className="wfacturas-button-outline"
                    onClick={handleCloseModal}
                  >
                    Regresar
                  </button>
                  <button
                    className="wfacturas-button"
                    onClick={handleCancelInvoice}
                  >
                    {`${!motiveSelect ? "Continuar" : "Cancelar factura"}`}
                  </button>
                </>
              ) : (
                <div className="flex w-full justify-center p-4">
                  <BarLoader />
                </div>
              )}
            </div>
          </div>
        )}
        {(type === "send" || type === "send-landing") && (
          <div>
            <div className="flex items-center justify-between w-title-container">
              <h3>
                {type === "send-landing"
                  ? "Folio ya facturado"
                  : "Enviar factura"}
              </h3>
              <div className="cursor-pointer" onClick={handleCloseModal}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15L15 5M5 5L15 15"
                    stroke="#3F2E04"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="w-body-container">
              <p>
                {type === "send-landing"
                  ? "Este folio ya se encuentra facturado, ingrese el correo al que desea mandar la factura"
                  : "Asegure que el correo debajo es el correcto o cámbielo en caso de ser necesario."}
              </p>
              {loading ? (
                <input
                  className="w-full mt-2 p-1 rounded-md border border-yellow-900"
                  type="text"
                  placeholder="correo@gmail.com"
                />
              ) : (
                <input
                  className="w-full mt-2 p-1 rounded-md border border-yellow-900"
                  type="text"
                  placeholder="correo@gmail.com"
                  defaultValue={userEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
              )}
            </div>
            <div className="w-buttons-container">
              {!loading ? (
                <>
                  <button
                    className="wfacturas-button-outline"
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </button>
                  <button
                    className="wfacturas-button"
                    onClick={handleSendToEmail}
                  >
                    Enviar
                  </button>
                </>
              ) : (
                <div className="flex w-full justify-center p-4">
                  <BarLoader />
                </div>
              )}
            </div>
          </div>
        )}
        {type === "download" && (
          <div>
            <div className="flex items-center justify-between w-title-container">
              <h3>Descargar factura</h3>
              <div className="cursor-pointer" onClick={handleCloseModal}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15L15 5M5 5L15 15"
                    stroke="#3F2E04"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="w-body-container">
              <p>Selecciona el tipo archivo de tu descarga.</p>
              <div className="flex items-center">
                <Checkbox onChange={() => setPdf(!pdf)} />
                <label>PDF</label>
              </div>
              <div className="flex items-center">
                <Checkbox onChange={() => setXml(!xml)} />
                <label>XML</label>
              </div>
            </div>
            <div className="w-buttons-container">
              {!loading ? (
                <button
                  className="wfacturas-button"
                  onClick={handleDownloadInvoice}
                >
                  Descargar
                </button>
              ) : (
                <div className="flex w-full justify-center p-4">
                  <BarLoader />
                </div>
              )}
            </div>
          </div>
        )}
        {type === "setting" && (
          <div>
            <div className="flex items-center justify-between w-title-container">
              <h3>Duración de factura</h3>
              <div className="cursor-pointer" onClick={handleCloseModal}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 15L15 5M5 5L15 15"
                    stroke="#3F2E04"
                    stroke-width="1.875"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="w-body-container">
              <p>Ingrese el tiempo que desea extender el límite</p>
              <select
                className="w-full mt-2 p-1 rounded-md border border-yellow-900 h-10 max-w-xs"
                placeholder="Seleccione una opcion"
                defaultValue={prevHours}
                onChange={(e) => setHours(e.target.value)}
              >
                <option value={24}>24 horas</option>
                <option value={48}>48 horas</option>
                <option value={72}>72 horas</option>
                <option value={720}>Fin de mes</option>
              </select>
            </div>
            <div className="w-buttons-container">
              {!loading ? (
                <>
                  <button
                    className="wfacturas-button-outline"
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="wfacturas-button"
                    onClick={() => {
                      saveHours({ hours, folio });
                    }}
                  >
                    Guardar
                  </button>
                </>
              ) : (
                <div className="flex w-full justify-center p-4">
                  <BarLoader />
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    );
  }
);

export default WFacturasActionModal;
