import React from "react";

import { ResponsiveWaffle } from "@nivo/waffle";
import { useTranslation } from "react-i18next";

const formatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const ProgressBar = ({ id, title, className, max, current, color }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`w-full block p-4 rounded border mt-2 shadow-sm border-gray-200 ${className}`}
    >
      <div className="text-lg text-gray-800 font-bold">{title}</div>
      <div className="text-xs font-light text-gray-700">
        <span className="text-sm">{formatter.format(current)}</span>{" "}
        {t("restPage.accTablePage2")}{" "}
        <span className="text-base text-gray-900 font-medium">
          {formatter.format(max)}
        </span>{" "}
      </div>
      <div className="text-xs font-light mb-4 text-gray-700">
        <span>({((current * 100) / max).toFixed(2) + "%"})</span>
      </div>
      <div style={{ height: "300px" }}>
        <ResponsiveWaffle
          data={[
            {
              id,
              label: title,
              value: current,
            },
          ]}
          rows={24}
          columns={18}
          total={max}
          fillDirection="bottom"
          colors={[color]}
          tooltip={({ value }) => {
            return (
              <div>
                <span className="text-xs uppercase font-bold">{title}: </span>
                <span className="text-sm">{formatter.format(value)}</span>
              </div>
            );
          }}
        ></ResponsiveWaffle>
      </div>
    </div>
  );
};

export default ProgressBar;
