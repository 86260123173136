import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchUserProfile } from "../../api/Auth";
import {
  generateMailCode,
  validateMailCode,
  setUserVerified,
  isUserVerified,
} from "../../api/User";
import Layout from "../../components/Layout";
import LoadingIndicator from "../../components/LoadingIndicator";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";
import SubscriptionItem from "../../components/subscriptions/SubscriptionItem";
import {
  MY_SUBSCRIPTIONS_PATH,
  NEW_SUBSCRIPTION_PATH,
  VERIFICATION_PATH,
} from "../../constants/paths";
import "./MailVerification.css";
import { HashLoader } from "react-spinners";

const MailVerification = () => {
  const history = useHistory();
  // Variable for getting licenses
  const [userEmail, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [verified, setVerified] = useState(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  // Instantiate form
  const { register, handleSubmit, errors, reset } = useForm({
    /* resolver: yupResolver(schema),
        defaultValues: userData, */
  });

  useEffect(() => {
    setLoading(true);
    fetchUserProfile()
      .then((usr_data) => {
        setEmail(usr_data.email);
        setUserName(usr_data.name);

        isUserVerified(usr_data.email).then((res) => {
          if (res.emailVerified) {
            setVerified(true);
          } else {
            setVerified(false);
            history.push(VERIFICATION_PATH);
          }
        });
        //emailjs.send(SERVICE_ID, TEMPLATE_ID, data, USER_ID)
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (verified) {
    }
  });

  const sendMailCode = () => {
    generateMailCode(userEmail).then((code) => {
      var templateParams = {
        reply_to: userEmail,
        code: code.mailCode,
        name: userName,
      };
      emailjs.send(
        "service_ss9w9ws",
        "template_tc2fxi5",
        templateParams,
        "user_itfQnWMwlnZrVcVMwjZwQ"
      );
    });
  };

  const onSubmit = (values) => {
    var code = values.code;
    if (code.length != 0) {
      setLoading(true);
      setSuccess(false);
      validateMailCode(userEmail, code)
        .then((res) => {
          setLoading(false);
          setSuccess(true);
          setError(null);

          if (res.isValid) {
            setUserVerified(userEmail, code).then((res) => {
              setVerified(true);
            });
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
          setSuccess(false);
        });
    } else {
    }
  };

  if (!verified) {
    return (
      <div className="flex">
        <div className="p-8 w-full">
          <h3 className="text-2xl text-gray-800 font-bold">
            Hola, parece que aún no has verificado tu correo {userEmail}
          </h3>
          <br />
          <h4 className="text-2md text-gray-700 ">
            Verifica tu cuenta ingresando el código que recibiras en tu correo
            para poder empezar a usar wmobil
          </h4>

          <form className="w-full formA" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              ref={register}
              className="input inputA"
              name="code"
              defaultValue={""}
              placeholder={"Código de verificación"}
              pattern="[0-9]+"
              maxLength={6}
              minLength={6}
            ></input>
            <button className="button-main buttonB" type="submit">
              Verificar cuenta
            </button>
          </form>
          <h4 className="text-2md text-gray-700 spacingTop">
            Si no recibiste el correo con tu código puedes solicitar uno nuevo
          </h4>

          <button className="button-main buttonA" onClick={sendMailCode}>
            Volver a enviarme el código
          </button>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default MailVerification;
