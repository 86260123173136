import React, { createContext, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import WBanner from "../components/WBanner";

const containerStyle = {
  top: 0,
  left: 0,
  right: 0,
};

const toastOptionsStyle = {
  duration: 3000,
  style: {
    maxWidth: "100%",
    width: "100%",
    borderRadius: 0,
  },
  success: {
    style: {
      color: "#164E2A",
      backgroundColor: "#CEEED5",
    },
    icon: "",
  },
  error: {
    style: {
      color: "#FF1414",
      backgroundColor: "#FFBEBE",
    },
    icon: "",
  },
  custom: {
    duration: 2000,
  },
};

const ToastContext = createContext();

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("Missing ToastProvider");
  }
  return context;
};

export const ToastProvider = ({ children }) => {
  const showToast = (text, type) => {
    const isCustom = type === "custom";
    toast[`${type}`](isCustom ? <WBanner text={text} /> : text);
  };

  return (
    <ToastContext.Provider value={{ toast, showToast }}>
      {children}
      <Toaster
        position="top-center"
        containerStyle={containerStyle}
        toastOptions={toastOptionsStyle}
      />
    </ToastContext.Provider>
  );
};
