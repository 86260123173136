import React from "react";
import { useTranslation } from "react-i18next";

const DotComponent = ({ status }) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center">
      <div
        className={`rounded-full w-2 h-2 ${
          status ? "bg-green-500" : "bg-red-500"
        } inline-block mr-1`}
      ></div>
      <span className={`${status ? "text-green-700" : "text-red-700"} ml-2`}>
        {status ? t("admin.active") : t("admin.inactive")}
      </span>
    </div>
  );
};

export default DotComponent;
