import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import LoadingIndicator from "../LoadingIndicator";
import { useTranslation } from "react-i18next";
import PaymentMethodSelect from "./PaymentMethodSelect";
import CfdiSelect from "./CfdiSelect";
import { createFactura } from "../../api/User";
import { APP_MY_FACTURAS_PATH } from "../../constants/paths";
import ItemsPreview from "./ItemsPreview";
import WInput from "../WInput";
import { useToast } from "../../context/ToastContext";
import { useTimbres } from "../../context/TimbresContext";
import { getTimbresData } from "../../api/Utils";
import LegalDataInputForm from "./LegalDataInputForm";
import WSelect from "../WSelect";

const publicoEnGeneral = {
  legal_name: "Público en general",
  tax_id: "XAXX010101000",
  tax_system: "616",
  use: "S01",
};

const NewFacturaForm = ({
  cheques,
  restId,
  selectedFolios,
  selectedWfNumcheques,
  deselectedFolios,
  userData,
  generalDescription,
  clients,
  includeTips,
  series,
  dateRange,
  selectAll,
}) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { updateTimbresCount } = useTimbres();
  const { folioStartDate, folioEndDate } = dateRange;
  const [loading, setLoading] = useState(false);
  const [publicoGral, setPublicoGral] = useState(false);
  const [isPUE, setIsPUE] = useState(true);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filteredLegalName, setFilteredLegalName] = useState([]);
  const [filteredTaxid, setFilteredTaxId] = useState([]);
  const itemsPerCheque = cheques?.cheque;
  const minDate = new Date();
  minDate.setHours(minDate.getHours() - 72);
  const schema = yup.object().shape({
    legal_name: yup.string().required(),
    support_email: yup.string().optional(),
    street: yup.string().optional(),
    exterior: yup.string().optional(),
    zip: yup.string().required(),
    city: yup.string().optional(),
    state: yup.string().optional(),
    tax_id: yup.string().required().min(12).max(13),
    phone: yup.string().optional(),
    tax_system: yup
      .string()
      .required()
      .test(function (reg) {
        return reg !== "no";
      }),
    folio: yup.string().optional(),
    amount: yup.string().optional(),
    date: yup.string().required(),
    use: yup
      .string()
      .required()
      .test(function (use) {
        return use !== "no";
      }),
    payment_form: yup
      .string()
      .optional()
      .test(function (pay) {
        return pay !== "no";
      }),
    period: yup
      .string()
      .optional()
      .test(function (period) {
        if (publicoGral) {
          return period !== "no";
        } else return true;
      }),
    month: yup
      .string()
      .optional()
      .test(function (month) {
        if (publicoGral) {
          return month !== "no";
        } else return true;
      }),
    year: yup
      .string()
      .optional()
      .test(function (year) {
        if (publicoGral) {
          return year !== "no";
        } else return true;
      }),
    pdf_custom_section: yup.string().optional(),
    serie_id: yup.string().optional(),
    payment_method: yup.string().optional(),
  });
  // Instantiate form
  const { register, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      const confirmationMessage =
        selectedFolios.length > 1
          ? "Seguro que desea facturar todos los folios?"
          : "¿Están correctos los datos?";

      if (selectedFolios.length >= 1 && window.confirm(confirmationMessage)) {
        setLoading(true);
        const timbresReponse = await getTimbresData(restId);
        if (timbresReponse.timbres === 0) {
          showToast("Has consumido todos tus timbres", "custom");
          setLoading(false);
          return;
        }
        const today = new Date();
        today.setHours(today.getHours() - 1);
        const todayHours = today.toISOString().split("T")[1];
        const dateString = `${values.date}T${todayHours}`;

        values.payment_form = values.payment_form
          ? values.payment_form
          : cheques.payment_method;

        values.amount = isPUE ? cheques.total : values.amount;
        values.payment_method = isPUE ? "PUE" : "PPD";
        values.folio = selectedFolios;
        values.wf_numcheque = selectedWfNumcheques;
        values.deselected_folio = deselectedFolios;
        values.date = dateString;
        values.include_tip = includeTips;
        values.tip_amount = cheques.totalTips;

        const serieFolioStart = series?.find((serie) => {
          return serie.serie_id === values.serie_id;
        });

        if (serieFolioStart) {
          values.serie_initial_folio = serieFolioStart.folio_start;
        }

        const newValues = {
          ...values,
          rest_id: restId,
          user_data: userData,
          turn_start_date: folioStartDate,
          turn_end_date: folioEndDate,
          select_all: selectAll,
          items: cheques.allItems,
          // generalDescription !== "" && generalDescription
          //   ? chequeWithGeneralDesc[0].items
          //   : cheques.allItems,
        };

        createFactura(newValues)
          .then(() => {
            const prevTimbres = parseInt(localStorage.getItem("timbresCount"));
            if (prevTimbres !== 99999) updateTimbresCount(prevTimbres - 1);
            showToast("¡Factura creada exitosamente!", "success");
            window.location.href = APP_MY_FACTURAS_PATH;
          })
          .catch((error) => {
            setLoading(false);
            showToast(
              `Error: ${typeof error === "object" ? error.message : error}`,
              "error"
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } catch (error) {
      showToast(`Error`, "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsPUE(true);
    if (selectedClient && selectedClient !== "new") {
      reset({
        legal_name: selectedClient ? selectedClient.legal_name : "",
        tax_id: selectedClient ? selectedClient.tax_id : "",
        tax_system: selectedClient ? selectedClient.tax_system : "",
        zip: selectedClient ? selectedClient.address.zip : "",
        state: selectedClient ? selectedClient.address.state : "",
        street: selectedClient ? selectedClient.address.street : "",
        exterior: selectedClient ? selectedClient.address.exterior : "",
        city: selectedClient ? selectedClient.address.city : "",
        phone: selectedClient ? selectedClient.phone : "",
        support_email: selectedClient ? selectedClient.email : "",
        use: "G03",
        payment_method: "PUE",
      });
    } else {
      reset({
        legal_name: publicoGral ? publicoEnGeneral.legal_name : "",
        tax_id: publicoGral ? publicoEnGeneral.tax_id : "",
        tax_system: publicoGral ? publicoEnGeneral.tax_system : "",
        use: publicoGral ? publicoEnGeneral.use : "G03",
      });
    }
  }, [publicoGral, selectedClient, reset]);

  const handleCheckboxChange = () => {
    setPublicoGral((prevState) => !prevState);
  };

  const evaluateTaxId = (value) => {
    const valueLower = value.toLowerCase();
    if (valueLower === "") {
      setFilteredTaxId([]);
      setSelectedClient(null);
    } else {
      const matches = clients.filter((client) =>
        client.tax_id.toLowerCase().includes(valueLower)
      );
      setFilteredTaxId(matches);
    }
    if (!clients) return;
    if (valueLower === publicoEnGeneral.tax_id && valueLower.length >= 12) {
      setPublicoGral(true);
    } else if (
      valueLower !== publicoEnGeneral.tax_id &&
      valueLower.length === 13
    ) {
      setPublicoGral(false);
      const client = clients.find((x) => x.tax_id.toLowerCase() === valueLower);

      if (client) {
        setSelectedClient(client);
      }
    }
  };

  const handleClientSearch = (value) => {
    const valueLower = value.toLowerCase();
    if (!clients) return;

    if (valueLower === "") {
      setFilteredLegalName([]);
      setSelectedClient(null);
    } else {
      const matches = clients.filter((client) =>
        client.legal_name.toLowerCase().includes(valueLower)
      );
      setFilteredLegalName(matches);
    }
  };

  const handlePPDChange = () => {
    setIsPUE((prev) => !prev);
  };

  const handlePaymentMethodChange = (value) => {
    const valueInt = parseInt(value);

    if (!isNaN(valueInt) && valueInt === 99) {
      setIsPUE(false);
    } else if (!isPUE) {
      setIsPUE(true);
    }
  };

  useEffect(() => {
    if (publicoGral) {
      showToast("Realizando factura global", "custom");
    }
  }, [publicoGral]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4 ">
        <label className="text-2xl font-semibold wfacturas-table-text mt-5 block">
          Datos fiscales
        </label>
        <label className="text-sm font-bold wfacturas-text leading-4 block cursor-pointer">
          <input
            type="checkbox"
            checked={publicoGral}
            onChange={handleCheckboxChange}
            className="mr-3 cursor-pointer"
          />
          Facturar a público en general{" "}
        </label>
        <div className="w-32">
          <WSelect
            type="digits"
            name="serie_id"
            className=" wfacturas-input appearance-none"
            ref={register}
            label="Serie"
          >
            <option value={"no"}>F</option>
            {series?.map((serie) => (
              <option value={serie.serie_id}>{serie.serie_id}</option>
            ))}
          </WSelect>
        </div>
      </div>
      <LegalDataInputForm
        publicoGral={publicoGral}
        register={register}
        filteredLegalName={filteredLegalName}
        filteredTaxid={filteredTaxid}
        selectedClient={selectedClient}
        errors={errors}
        evaluateTaxId={(val) => evaluateTaxId(val)}
        handleClientSearch={(val) => handleClientSearch(val)}
        setSelectedClient={(val) => setSelectedClient(val)}
      />
      <label className="text-2xl mb-4 font-semibold wfacturas-table-text mt-5 block">
        Datos de consumo
      </label>
      <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-6 gap-x-40 items-center mb-6">
        <div>
          <CfdiSelect name="use" register={register} />
          {errors.use && <div className="error">Requerido</div>}
        </div>
        <div>
          <WInput
            mandatory
            type="date"
            name="date"
            label="Fecha de emision"
            ref={register}
            maxDate={new Date().toISOString().split("T")[0]}
            minDate={minDate.toISOString().split("T")[0]}
            defaultValue={new Date().toISOString().split("T")[0]}
          />
          {errors.date && (
            <div className="error">Fecha fuera del rango de facturación</div>
          )}
        </div>
        <div>
          <WInput ref={register} label="Notas" name="pdf_custom_section" />
          {errors.pdf_custom_section && (
            <div className="error">Clave inválida</div>
          )}
        </div>
        <div>
          <WInput
            mandatory
            disabled={isPUE}
            type="currency"
            ref={register}
            label="Monto"
            name="amount"
            defaultValue={cheques?.total}
          />
          {errors.amount && <div className="error">Monto invalido</div>}
        </div>
        <div>
          <PaymentMethodSelect
            // isDisabled={
            //   cheques?.payment_method != "no" && cheques.payment_method
            //     ? true
            //     : false
            // }
            name="payment_form"
            register={register}
            defaultValue={cheques?.payment_method}
            onChange={(val) => handlePaymentMethodChange(val)}
          />
          {errors.payment_form && <div className="error">Requerido</div>}
        </div>
        <div>
          <WSelect
            removeArrow
            disabled
            name={"payment_method"}
            label="Método de pago"
            ref={register}
            value={isPUE ? "PUE" : "PPD"}
            onChange={handlePPDChange}
          >
            <option value="PUE">PUE - Pago en Una sola Exhibición</option>
            <option value="PPD">PPD - Pago en Parcialidades o Diferido</option>
          </WSelect>
          {errors.payment_method && <div className="error">Requerido</div>}
        </div>
      </div>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          {itemsPerCheque && (
            <ItemsPreview
              cheques={itemsPerCheque}
              total={cheques?.total}
              subtotal={cheques?.subtotal}
              totalTips={cheques?.totalTips}
            />
          )}
          <div>
            <label className="text-2xl font-semibold wfacturas-table-text mt-5 block">
              Folios
            </label>
            <div className="flex items-center justify-between mb-40">
              {selectedWfNumcheques.length > 0 && (
                <div className="md:w-1/2 w-full">
                  <div className="flex wfacturas-input winput-folios text-gray-500 border-solid flex-wrap">
                    {selectedWfNumcheques.map((folio, index) => (
                      <div key={folio}>
                        {index === selectedWfNumcheques.length - 1 ? (
                          folio
                        ) : (
                          <>{folio},&nbsp;</>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="text-gray-500">
                    {selectedFolios.length} folios
                  </div>
                </div>
              )}

              <button
                className="bg-green-700 hover:text-green-100 cursor-pointer text-white font-medium rounded-md p-2 items-center"
                type="submit"
              >
                Facturar
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default NewFacturaForm;
