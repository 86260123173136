import React from "react";
import { useTranslation } from "react-i18next";
import downArrow from "../images/downArrow.svg";

const WDropdown = ({ filteredLegalName, setSelectedClient }) => {
  return (
    <div className="wdropdown">
      {filteredLegalName.map((client, index) => (
        <p
          key={client.id}
          className={`cursor-pointer px-4 py-1 hover:bg-blue-600 hover:text-white ${
            index === filteredLegalName.length - 1
              ? " rounded-bl-lg rounded-br-lg"
              : ""
          }`}
          onClick={() => setSelectedClient(client)}
        >
          <div>
            <p className="font-medium">{client.legal_name}</p>
            <div className="flex">
              <p className="font-semibold pr-2">RFC:</p>
              <p>{client.tax_id}</p>
              <p className="font-semibold px-2">CP:</p>
              <p>{client.address.zip}</p>
            </div>
          </div>
        </p>
      ))}
    </div>
  );
};

export default WDropdown;
