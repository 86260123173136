import React, { createContext, useEffect, useState } from "react";
import { fetchDisconnectedRestaurants } from "../api/Admin";

const NotificationContext = createContext();

const NotificationContextProvider = ({ children }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurantsOffline, setRestaurantsOffline] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notificationCount, setNotificationCount] = useState(() => {
    const storedNotificationCount = localStorage.getItem("notificationCount");
    return storedNotificationCount ? parseInt(storedNotificationCount) : 0;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedRestaurants = await fetchDisconnectedRestaurants();
        setRestaurants(fetchedRestaurants.restaurants);
        setRestaurantsOffline(fetchedRestaurants.restaurantsOffline);
        setNotificationCount(fetchedRestaurants.restaurants.length);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    localStorage.setItem("notificationCount", notificationCount.toString());
  }, [notificationCount]);

  useEffect(() => {
    const storedNotificationCount = localStorage.getItem("notificationCount");
    if (storedNotificationCount !== null) {
      setNotificationCount(parseInt(storedNotificationCount));
    }
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        restaurants,
        restaurantsOffline,
        error,
        loading,
        notificationCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationContextProvider };
