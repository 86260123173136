export const ADMIN_HOME_PATH = "/admin";
export const ADMIN_CONNECTIONS_PATH = "/conexiones";
export const ADMIN_USERS_PATH = "/admin/usuarios";
export const ADMIN_MY_ACCOUNT_PATH = "/admin/mi-cuenta";

export const ADMIN_NEW_DISTRIBUTOR_PATH = "/admin/distribuidores/nuevo";
export const ADMIN_DISTRIBUTORS_PATH = "/admin/distribuidores";
export const ADMIN_PAYMENTS_PATH = "/admin/pagos";

export const ADMIN_TOOLS_PATH = "/admin/tools";
