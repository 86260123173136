import React from "react";
import { signOut } from "../../api/Auth";
import Layout from "../../components/Layout";
import ProfileNavbar from "../../components/navigation/ProfileNavbar";

const AdminAccountPage = () => {
  return (
    <Layout title="Mi cuenta" fullWidth admin>
      <div className="flex">
        <ProfileNavbar admin></ProfileNavbar>
        <div className="p-8">
          <h2 className="text-2xl text-gray-800 font-bold">Mi cuenta</h2>
        </div>
      </div>
    </Layout>
  );
};

export default AdminAccountPage;
